import { ModuleWithProviders, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import {
  ApiService, AthleteService, AthleteStatusLogService, AuthService, CatalogService, ExerciseMediaService, ExerciseService,
  FolderService,
  InstitutionManagerService, InstitutionService, LocalStorageService, ParamsService, ProgressSpinnerService,
  ScrollService, SocialLoginService, SubscriptionService, TeamService, UserService, UtilitiesService
} from './services';

import { TokenInterceptor } from './interceptors/token.interceptor';

import { SP_CORE_CONFIG } from './sp-core.constants';
import { SpCoreConfig } from './sp-core-config.interface';

@NgModule({
  declarations: [],
  imports: [],
  exports: []
})
export class SpCoreModule {
  static forRoot(config?: SpCoreConfig): ModuleWithProviders<SpCoreModule> {

    return {
      ngModule: SpCoreModule,
      providers: [
        UtilitiesService,
        AuthService,
        ProgressSpinnerService,
        LocalStorageService,
        ScrollService,
        InstitutionService,
        UserService,
        TeamService,
        AthleteService,
        AthleteStatusLogService,
        InstitutionManagerService,
        SocialLoginService,
        CatalogService,
        ApiService,
        ParamsService,
        ExerciseService,
        SubscriptionService,
        ExerciseMediaService,
        FolderService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: TokenInterceptor,
          multi: true
        },
        {
          provide: SP_CORE_CONFIG,
          useValue: config ||
            // Configuraciones por defecto
            <SpCoreConfig>{
              apiBaseUrl: '',
              tokenUrl: 'token/',
              refreshTokenUrl: 'token-refresh/',
              userInfoUrl: 'me',
              forgotPasswordUrl: 'reset-password/',
              loginUrl: 'auth/login'
            }
        }
      ]
    }
  }
}
