import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserTypePipe } from './user-type.pipe';

const PIPES = [
  UserTypePipe
];

@NgModule({
  declarations: [
    ...PIPES
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ...PIPES
  ]
})
export class UserTypeModule { }
