import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatIconRegistry } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { I18NextModule, I18NEXT_NAMESPACE } from 'angular-i18next';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { JoyrideModule } from 'ngx-joyride';

import { ExerciseService } from 'sp-core';
import { NgxMatIntlTelInputComponent, SpHeaderModule, SpInstitutionModule } from 'sp-library';

import {
  AppService, EventService, ExerciseBlockService, ParameterService,
  PhaseDayService, PhaseService, PhaseWeekService, ProgramService, WidgetService,
  WorkoutBlockService, WorkoutLocationService, WorkoutService, WorksetTypeService, NotifyService, WorkoutLogService
} from './services';

import { SubscriptionModule } from '@web/shared/components/subscription';
import { UserTypeModule } from '@web/shared/pipes/user-type/user-type.module';

import { HeaderComponent, HeaderSubscriptionComponent, UserComplementDataComponent } from './components';

const COMPONENTS = [
  HeaderComponent,
  HeaderSubscriptionComponent,
  UserComplementDataComponent
];

const STANDALONE_COMPONENTS = [
  NgxMatIntlTelInputComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatFormFieldModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatSlideToggleModule,
    MatTooltipModule,
    I18NextModule,
    NgxUsefulSwiperModule,
    NgbPopoverModule,
    JoyrideModule.forChild(),
    SpInstitutionModule,
    SpHeaderModule,
    SubscriptionModule,
    UserTypeModule,
    STANDALONE_COMPONENTS,
  ],
  providers: [
    AppService,
    PhaseWeekService,
    PhaseDayService,
    ExerciseBlockService,
    EventService,
    ExerciseService,
    ParameterService,
    PhaseService,
    ProgramService,
    WidgetService,
    WorkoutService,
    WorkoutBlockService,
    WorkoutLocationService,
    WorksetTypeService,
    WorkoutLogService,
    NotifyService,
    {
      provide: I18NEXT_NAMESPACE,
      useValue: [
        'common',
        'core',
        'libraryComponents'
      ]
    }
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class CoreModule {
  constructor(
    private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.iconRegistry.addSvgIcon('logo', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/solo-performance.svg'));
    this.iconRegistry.addSvgIcon('settings', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/settings.svg'));
    this.iconRegistry.addSvgIcon('notifications', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/bell.svg'));
    this.iconRegistry.addSvgIcon('expand', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/expand.svg'));
    this.iconRegistry.addSvgIcon('account', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/account-circle-outline.svg'));
    this.iconRegistry.addSvgIcon('payment', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/payment.svg'));
    this.iconRegistry.addSvgIcon('logout', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/keyboard-backspace.svg'));
    this.iconRegistry.addSvgIcon('light-mode', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/light-mode.svg'));
    this.iconRegistry.addSvgIcon('dark-mode', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/dark-mode.svg'));
    this.iconRegistry.addSvgIcon('stripe', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/stripe.svg'));
    this.iconRegistry.addSvgIcon('menu', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/three-horizontal-lines.svg'));
    this.iconRegistry.addSvgIcon('mode-edit', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/mode-edit.svg'));
    this.iconRegistry.addSvgIcon('info', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/info-fill.svg'));
    this.iconRegistry.addSvgIcon('help', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/help-outline-24.svg'));
    this.iconRegistry.addSvgIcon('image-not-found', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/image-not-found-outline-24.svg'));
  }
}