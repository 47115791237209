import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatStepperModule } from '@angular/material/stepper';

import { SpDbNameModule, SpSubscriptionTypeModule } from '../../pipes';
import { SpDialogModule } from './../sp-dialog/sp-dialog.module';
import { SpUploadModule } from './../sp-upload/sp-upload.module';
import { SpNotificationModule } from './../sp-notification/sp-notification.module';
import { InstitutionDetailComponent } from './institution-detail/institution-detail.component';
import { InstitutionManagerDetailComponent } from './institution-manager-detail/institution-manager-detail.component';

const COMPONENTS = [
  InstitutionDetailComponent,
  InstitutionManagerDetailComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatDialogModule,
    MatStepperModule,
    SpDbNameModule,
    SpSubscriptionTypeModule,
    SpDialogModule,
    SpUploadModule,
    SpNotificationModule
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class SpInstitutionModule { }
