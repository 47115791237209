export type ChatMessageType = 'text' | 'image' | 'video' | 'file';

export interface ChatFile {
    file: string;
    id: number;
}

export enum Type {
    Text = 1,
    Image = 2,
    Video = 3,
    File = 4
}