import { Injectable } from '@angular/core';
import getDay from 'date-fns/getDay';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ApiService, Day, Phase, PhaseDay, PhaseWeek, PhaseWeekResponse, PhaseWeekView, ServiceResponse } from 'sp-core';

import { PhaseDayService } from './phase-day.service';

@Injectable()
export class PhaseWeekService {

  constructor(
    private apiService: ApiService,
    private dayService: PhaseDayService
  ) { }

  /**
   * Crea una nueva semana de fase
   * @param week Datos de semana
   */
  create(week: PhaseWeek): Observable<PhaseWeek> {
    return this.apiService
      .post<PhaseWeekResponse>('weeks/', week.toRequest())
      .pipe(
        map(response => week.fromResponse(response))
      );
  }

  /**
   * Agrega una semana para un fase que corresponde a un programa tipo playlist
   * ```
   * Crea la semana únicamente con id de fase y un nombre por defecto
   * ```
   * @param phaseId Identificador de semana a agregar
   * @returns Semana de fase recién creada
   */
  createForPlaylistProgram(phaseId: number): Observable<PhaseWeek> {

    const weekToCreate = new PhaseWeek();
    weekToCreate.phaseId = phaseId;

    return this.apiService
      .post<PhaseWeekResponse>(`week-list/`, weekToCreate.forPlaylistProgramRequest())
      .pipe(
        map(response => new PhaseWeek().fromResponse(response))
      );
  }

  createWithDate(week: PhaseWeek): Observable<PhaseWeek> {
    return this.apiService
      .post<PhaseWeekResponse>('weeks/', week.toCreateWithDateRequest())
      .pipe(map(response => {
        return week.fromResponse(response);
      }));
  }

  createAndMove(week: PhaseWeek): Observable<PhaseWeek> {
    return this.apiService
      .post<PhaseWeekResponse>(`create-move-weeks/`, week.toCreateWithDateRequest())
      .pipe(
        map(response => week.fromResponse(response)),
        catchError(this.apiService.handleError('createAndMove'))
      );
  }

  /**
   * Clona una semana de fase. De momento se utiliza para clonar una semana de fase de librería es decir sin programa asignado
   * @param weekId Identificador de semana a clonar
   * @returns Semana clonada
   */
  clone(weekId: number): Observable<PhaseWeek> {
    return this.apiService
      .post<PhaseWeekResponse>(`weeks/${weekId}/clone/`, {})
      .pipe(
        map(response => new PhaseWeek().fromResponse(response)),
        catchError(this.apiService.handleError('clone'))
      );
  }

  /**
   * Clona una semana de fase correspondiente a un programa de calendario
   * @param weekId Identificador de semana a clonar
   * @returns Semana clonada
   */
  cloneAndMove(weekId: number, view = PhaseWeekView.workoutBuilder): Observable<PhaseWeek> {

    let body = {};
    if (view === PhaseWeekView.workoutBuilder) {
      body = { 'builder': true };
    }

    return this.apiService
      .post<PhaseWeekResponse>(`copy-weeks/${weekId}/`, body)
      .pipe(
        map(response => new PhaseWeek().fromResponse(response)),
        catchError(this.apiService.handleError('cloneAndMove'))
      );
  }

  /**
   * Clona una semana de fase correspondiente a un programa tipo playlist.
   * Se diferencía del método clone normal, ya que éste requiere tener un programa asignado
   * @param weekId Identificador de semana a clonar
   * @returns Semana clonada
   */
  cloneForPlaylistProgram(weekId: number): Observable<PhaseWeek> {
    return this.apiService
      .post<PhaseWeekResponse>(`week-list/${weekId}/clone/`, {})
      .pipe(
        map(response => new PhaseWeek().fromResponse(response)),
        catchError(this.apiService.handleError('PhaseWeekService.cloneForPlaylistProgram'))
      );
  }

  /**
   * Elimina una semana del programa/fase
   * @param weekId Identificador de semana a eliminar
   */
  delete(weekId: number): Observable<ServiceResponse> {
    return this.apiService
      .delete(`weeks/${weekId}/`)
      .pipe(
        map(() => new ServiceResponse()),
        catchError(this.apiService.handleError('delete'))
      );
  }

  /**
   * Elimina una semana del programa/fase y recorre las fechas de las semanas posteriores
   * @param weekId Identificador de semana a eliminar
   * @returns Objeto genérico de respuesta
   */
  deleteAndMove(weekId: number): Observable<ServiceResponse> {
    return this.apiService
      .delete(`delete-move-weeks/${weekId}/`)
      .pipe(
        map(() => new ServiceResponse()),
        catchError(this.apiService.handleError('deleteAndMove'))
      );
  }

  /**
   * Eliminar una semana de una fase que corresponde a un programa tipo playlist.
   * TODO: Confirmar qué diferencia existe entre el servicio de borrado de semana normal (this.delete)
   * @param weekId Identificador de semana a eliminar
   * @returns 
   */
  deleteForPlaylistProgram(weekId: number): Observable<ServiceResponse> {
    return this.apiService
      .delete(`week-list/${weekId}/`)
      .pipe(
        map(() => new ServiceResponse()),
        catchError(this.apiService.handleError('deleteForPlaylistProgram'))
      );
  }

  /***
   * Agrega un día a la semana. El día se crea en base a la fecha indicada
   */
  addDayForDate(
    week: PhaseWeek,
    dayDate: Date
  ): Observable<PhaseDay> {

    // En caso de que el día obtenido sea domingo, asigna el índice correcto para SPF. Para la librería 0 es domingo, para SPF domingo es 7
    const dayNumber = getDay(dayDate);
    const day = dayNumber === 0
      ? Day.sunday
      : dayNumber as Day;

    const phaseDay = new PhaseDay();
    phaseDay.day = day;
    phaseDay.weekId = week.id;

    return this.dayService.create(phaseDay).pipe(
      map(createdDay => {
        // Si la semana no tiene objeto de fase asignada pero tiene el id, crea una nueva instancia
        if (!week.phase && week.phaseId) {
          const phase = new Phase();
          phase.id = week.phaseId;
          week.phase = phase;
        }
        // Si no se retorna fecha le asigna la que se envía para crearlo
        if (!createdDay.date) {
          createdDay.date = dayDate;
        }
        createdDay.week = week;
        return createdDay;
      })
    );
  }
}