import {APP_INITIALIZER, isDevMode, LOCALE_ID} from '@angular/core';

import languageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import {
  I18NEXT_SERVICE,
  I18NextModule,
  ITranslationService,
  defaultInterpolationFormat
} from 'angular-i18next';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';

import { CustomMatPaginatorIntl } from './custom-mat-paginator-intl';

const i18nextOptions = {
  whitelist: ['en', 'es'],
  fallbackLng: 'en',
  debug: false,
  returnEmptyString: false,
  ns: [
    'translation'
  ],
  interpolation: {
    format: I18NextModule.interpolationFormat(defaultInterpolationFormat)
  },
  backend: {
    loadPath: 'assets/locales/{{lng}}/{{lng}}.{{ns}}.json',
  },
  detection: {
    order: ['localStorage'],
    lookupLocalStorage: 'lang',
    caches: ['localStorage'],
    cookieDomain: 'document.location.hostname'
  }
};

function appInit(i18next: ITranslationService) {
  return () => {
    return i18next
      .use(HttpApi)
      .use(languageDetector)
      .init(i18nextOptions);
  };
}

export function localeIdFactory(i18next: ITranslationService): string {
  return i18next.language;
}

export const I18N_PROVIDERS = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [I18NEXT_SERVICE],
    multi: true
  },
  {
    provide: LOCALE_ID,
    useFactory: localeIdFactory,
    deps: [I18NEXT_SERVICE]
  },
  {
    provide: MatPaginatorIntl,
    useClass: CustomMatPaginatorIntl
  }
];
