<!-- Barra de progreso -->
<div *ngIf="spinnerIsRunning"
    class="sp-main-layout__progress-bar">
    <mat-progress-bar color="warn"
        mode="indeterminate">
    </mat-progress-bar>
</div>

<!-- Router -->
<main #main
    class="sp-main-layout__main container-fluid px-4"
    spScroll>
    <router-outlet></router-outlet>
</main>

<!-- Header -->
<web-header #header
    class="sp-header">
</web-header>