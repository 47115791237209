import { Component, ElementRef, OnInit, OnDestroy, Renderer2, ViewChild } from '@angular/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { concatMap, finalize } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { JoyrideService } from 'ngx-joyride';

import { AuthService, InstitutionService, ProgressSpinnerService, ScrollService, SubscriptionService, UserService, WorkoutTag } from 'sp-core';

import { AppInitialData } from '@web/core/models';
import { AppService, ParameterService, WorkoutService } from '@web/core/services';
import { UserComplementDataComponent } from '@web/core/components';

import { DARK_THEME_CLASS_NAME } from '../../app.constants';

@Component({
  selector: 'web-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss']
})
export class MainLayoutComponent implements OnInit, OnDestroy {

  @ViewChild('header', { read: ElementRef }) headerRef: ElementRef;
  @ViewChild('main') mainRef: ElementRef;

  spinnerIsRunning = false;

  get header(): HTMLElement {
    return this.headerRef.nativeElement;
  }

  get main(): HTMLElement {
    return this.mainRef.nativeElement;
  }

  private bodyMarginTop: number;

  private subSink = new SubSink();

  private initialData: AppInitialData;

  constructor(
    private renderer: Renderer2,
    private overlay: OverlayContainer,
    private dialog: MatDialog,
    private joyrideService: JoyrideService,
    private spinnerService: ProgressSpinnerService,
    private scrollService: ScrollService,
    private appService: AppService,
    private authService: AuthService,
    private subscriptionService: SubscriptionService,
    private institutionService: InstitutionService,
    private userService: UserService,
    private workoutService: WorkoutService,
    private parameterService: ParameterService
  ) {
    this.spinnerService.delay = 0;
  }

  ngOnInit(): void {

    this.subSink.sink = this.subscriptionService.regionNotAssigned$.pipe(
      //first(() => !this.subscriptionService.regionNotAssignedError)
    ).subscribe(value => {
      if (this.subscriptionService.regionNotAssignedError) return;
      this.subscriptionService.regionNotAssignedError = value;
    });

    // Indica que el tema será dark. Cuando se tenga tema light agregar la configuración en perfil.
    this.appService.themeChanged$.next('dark');

    // Tema oscuro
    this.subSink.sink = this.appService.themeChanged$
      .subscribe(mode => {
        if (mode === 'dark') {
          this.renderer.addClass(document.body, DARK_THEME_CLASS_NAME);
          this.overlay.getContainerElement().classList.add(DARK_THEME_CLASS_NAME);
        }
        else {
          this.renderer.removeClass(document.body, DARK_THEME_CLASS_NAME);
          this.overlay.getContainerElement().classList.remove(DARK_THEME_CLASS_NAME);
        }
      });

    this.subSink.sink = this.spinnerService.stateChange.subscribe(state => {
      this.spinnerIsRunning = state
    });

    this.subSink.sink = this.scrollService.onScroll.subscribe(() => {
      // Visualiza una sombra en la parte inferior del encabezado. Cuando el contenido del cuerpo alcanza el límite inferior del encabezado.
      // Actualmente se está manejando un margen de 1.5rem
      // Cuando el tema es claro no tiene el borde inferior por defecto por lo que se requiere agregar programáticamente
      if (this.main.scrollTop >= this.bodyMarginTop) {
        this.renderer.addClass(this.header, 'sp-header--raised');
      } else {
        this.renderer.removeClass(this.header, 'sp-header--raised');
      }
    })

    this.loadInitialData();
  }

  ngOnDestroy(): void {
    // Elimina la clase dark ya que no aplica para login
    this.renderer.removeClass(document.body, DARK_THEME_CLASS_NAME);
    this.subSink.unsubscribe();
  }

  ngAfterViewInit(): void {

    // Para visualizar una sombra en la parte inferior del encabezado. Cuando el contenido del cuerpo alcanza el límite inferior del encabezado.
    // Actualmente se está manejando un margen de 1.5rem
    this.bodyMarginTop = parseFloat(getComputedStyle(document.documentElement).fontSize) * 1.5;
  }

  private showTour(): void {

    // En caso de que se haya indicado como realizado el tour, ya no se visualiza de nuevo
    if (this.authService.tourDone) return;

    // En caso de que el usuario sólo tenga una institución asignada, NO visualiza el tour
    if (this.initialData.authenticatedUser?.institutions?.length <= 1) return;

    this.joyrideService.startTour({
      steps: ['step1']
    });
  }

  private showUserComplementaDataDialog(initialData: AppInitialData): void {

    // En caso de que se obtenga el error de "región no asignada" también solicitará la pantalla de complemento de información
    const requestOnlyRegion = !!this.subscriptionService.regionNotAssignedError;

    // Verifica si el perfil del usuario ya está completo
    // Verifica que tampoco se solicite sólo captura de región
    if (initialData.authenticatedUser?.isProfileComplete && !requestOnlyRegion) {
      this.showTour();
      return;
    }

    // Visualiza la pantalla para complementar datos de perfil
    this.dialog.open(UserComplementDataComponent, {
      disableClose: true,
      autoFocus: false,
      data: { requestOnlyRegion }
    }).afterClosed().subscribe(() => {
      this.showTour();
    });
  }

  private loadInitialData(): void {

    const initialData = new AppInitialData();

    this.spinnerService.start();
    // Obtiene institución por defecto
    this.subSink.sink = this.institutionService.getDefaultInstitution().pipe(
      // Obtiene datos de tipos de workouts
      concatMap(defaultInstitution => {
        initialData.defaultInstitution = defaultInstitution;
        return this.workoutService.getPaginatedWorkoutTypes();
      }),
      // Obtiene colección de parámetros de ejercicios
      concatMap(workoutTypesData => {
        // Almacena los íconos en el registro de Angular Material (MatIconRegistry)
        initialData.workoutTypesData = workoutTypesData;
        return this.parameterService.get();
      }),
      // Obtiene datos de usuario en sesión
      concatMap(parameters => {
        initialData.parameters = parameters;
        return this.userService.get();
      }),
      // Obtiene datos de perfil del usuario en sesión
      concatMap(user => {
        initialData.authenticatedUser = user;
        return this.userService.getProfile(user.id);
      }),
      // Obtiene subscripción actual
      concatMap(userProfile => {
        initialData.authenticatedUserProfile = userProfile;
        return this.subscriptionService.getCurrentSubscription();
      }),
      concatMap(currentSubscription => {
        initialData.currentSubscription = currentSubscription;
        return this.workoutService.getPaginatedTagsByInstitution()
      }),
      finalize(() => this.spinnerService.stop())
    ).subscribe(tagsData => {
      initialData.institutionalTagsFirstPage = tagsData;
      initialData.institutionalTagsFirstPage.data = WorkoutTag.sortByName(initialData.institutionalTagsFirstPage.data);
      this.initialData = initialData;
      this.appService.emitInitialDataChanged(initialData);
      this.showUserComplementaDataDialog(initialData);
    }, (error) => {
      if(error.status != 400) return;
      // Si algún servicio falla, enviar los datos iniciales que se hayan logrado obtener
      this.initialData = initialData;
      this.appService.emitInitialDataChanged(initialData);
      this.showUserComplementaDataDialog(initialData);
    });
  }
}
