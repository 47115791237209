import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { I18NextModule } from 'angular-i18next';

import { SpDialogModule } from '../sp-dialog/sp-dialog.module';

import { SpCardModule } from '../sp-card';
import { SpInputSearchModule } from '../sp-input-search';
import { SpUploadModule } from '../sp-upload';
import { SpYoutubeModule } from '../sp-youtube';
import { SpClickStopPropagationModule } from '../../directives';
import { SpNotificationModule } from '../sp-notification';
import { SpProgressSpinnerModule } from '../sp-progress-spinner';

import { ExerciseMediaComponent } from './exercise-media/exercise-media.component';
import { ExerciseFilterCategoryGroupComponent } from './exercise-filter-category-group/exercise-filter-category-group.component';
import { ExerciseFilterComponent } from './exercise-filter/exercise-filter.component';
import { ExerciseCardComponent } from './exercise-card/exercise-card.component';
import { ExerciseDetailComponent } from './exercise-detail/exercise-detail.component';
import { ExerciseCardListComponent } from './exercise-card-list/exercise-card-list.component';
import { SpDisableContextmenuModule } from '../../directives';
import { TranslatePipe } from './pipe/translate.pipe';

const COMPONENTS = [
  ExerciseMediaComponent,
  ExerciseFilterCategoryGroupComponent,
  ExerciseFilterComponent,
  ExerciseCardComponent,
  ExerciseDetailComponent,
  ExerciseCardListComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    TranslatePipe
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatTooltipModule,
    MatIconModule,
    SpDialogModule,
    SpCardModule,
    SpInputSearchModule,
    SpClickStopPropagationModule,
    SpYoutubeModule,
    SpUploadModule,
    SpNotificationModule,
    SpProgressSpinnerModule,
    SpDisableContextmenuModule,
    I18NextModule
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class SpExerciseModule {
  constructor(
    private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.iconRegistry.addSvgIcon('play', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/play.svg'));
    this.iconRegistry.addSvgIcon('pause', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/pause.svg'));
    this.iconRegistry.addSvgIcon('edit', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/edit.svg'));
  }
}
