import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';

import {
  ApiService, ExerciseBlock, ExerciseBlockAddRemoveParameter, ExerciseBlockParameter, ExerciseBlockParameterResponse,
  ExerciseParameter, ExerciseParameterLink, ExerciseParameterResponse, ExerciseSubparameter, ExerciseSubparametersData,
  ExerciseSubparametersDataResponse, UtilitiesService, WorkoutBlock, WorkoutBlocks, WorkoutsData,
  WorksetValue, WorksetValueResponse
} from 'sp-core';

@Injectable()
export class ParameterService {

  private parametersSubject$ = new BehaviorSubject<Array<ExerciseParameter>>([]);
  /**
   * Parameters collection
   */
  parameters$ = this.parametersSubject$.asObservable();

  private parameterAddedSubject$ = new Subject<ExerciseBlockAddRemoveParameter>();
  /**
   * Observable para saber cuando un parámetro se agrega
   */
  parameterAdded$ = this.parameterAddedSubject$.asObservable();

  private parameterRemovedSubject$ = new Subject<ExerciseBlockAddRemoveParameter>();
  /**
   * Observable para saber cuando un parámetro se elimina
   */
  parameterRemoved$ = this.parameterRemovedSubject$.asObservable();

  private currentSubparametersData: ExerciseSubparametersData = new ExerciseSubparametersData();

  constructor(
    private api: ApiService
  ) { }

  /**
   * Obtiene la lista/catálogo de parámetros de ejercicio, así como sus correspondientes subparámetros
   * @returns 
   */
  get(): Observable<Array<ExerciseParameter>> {

    let params = new HttpParams()
      .set('visible', '1')
      .set('ordering', 'order');

    return forkJoin([
      // Parámetros
      this.api.get<Array<ExerciseParameterResponse>>(
        'block-exercises-catalog/',
        params
      ),
      // Subparámetros
      this.getSubparameters()
    ]).pipe(
      map(response => {

        const parameters = response[0].map(x => new ExerciseParameter().fromResponse(x));
        const subparameters = response[1];

        parameters.forEach(parameter => {
          parameter.assignSubparameters(subparameters.filter(x => x.parameterId === parameter.id));
        });

        return parameters;
      }),
      tap(parameters => this.parametersSubject$.next(parameters))
    );
  }

  getPaginatedSubparameters(
    exerciseParameterId?: number
  ): Observable<ExerciseSubparametersData> {

    // TODO: Para optimizar el acceso. En éste caso siempre se obtiene la lista completa por lo que no hay problema. Analizar como optimizar pero mantieniendo la funcionalidad de poder filtrar la lista
    if (this.currentSubparametersData.data?.length) {
      return of(this.currentSubparametersData);
    }

    let params = new HttpParams()
      .set('perpage', '1000')
      .set('ordering', 'order');

    if (exerciseParameterId) {
      params = params.set('block_exercise_catalog', exerciseParameterId.toString());
    }

    return this.api.get<ExerciseSubparametersDataResponse>(
      'sub-block-exercises-catalog/',
      params
    ).pipe(
      map(response => {
        this.currentSubparametersData = new ExerciseSubparametersData().fromResponse(response);
        return this.currentSubparametersData;
      })
    );
  }

  getSubparameters(
    parameterId?: number
  ): Observable<Array<ExerciseSubparameter>> {

    return this.getPaginatedSubparameters(
      parameterId
    ).pipe(
      map(response => response.data)
    );
  }

  /**
   * Agrega un nuevo parámetro/catálogo a un bloque<->ejercicio
   */
  add(
    exerciseBlock: ExerciseBlock,
    parameter: ExerciseParameter
  ): Observable<ExerciseParameterLink> {

    const body = {
      exercise_block: exerciseBlock.id,
      block_exercise_catalog: parameter.id
    };

    return this.api.post<ExerciseBlockParameterResponse>(
      'item-block-exercises-catalog/',
      body
    ).pipe(
      map(response => {
        const blockLink = new ExerciseBlockParameter().fromResponse(response);
        // Notifica que se ha creado o asignado un nuevo parámetro a la relación de bloque<->ejercicio
        const parameterLink = new ExerciseParameterLink();
        parameterLink.parameter = parameter;
        parameterLink.id = blockLink.id;
        exerciseBlock.parameterLinks.push(parameterLink);
        this.parameterAddedSubject$.next({
          exerciseBlock: exerciseBlock,
          parameterLink: parameterLink
        });
        // Asignación recién creada
        return parameterLink;
      })
    );
  }

  /**
   * Elimina las asignaciones de un parámetro a todas las series de un bloque<->ejercicio.
   */
  remove(
    exerciseBlock: ExerciseBlock,
    parameterLink: ExerciseParameterLink
  ): Observable<boolean> {

    return this.api.delete(
      `item-block-exercises-catalog/${parameterLink.id}/`
    ).pipe(
      map(() => {
        // Elimina el parámetro de la colección de parámetros del bloque<->ejercicio
        UtilitiesService.removeFromCollection(exerciseBlock.parameterLinks, parameterLink);
        // Notifica que se eliminó un parámetro del bloque<->ejercicio
        this.parameterRemovedSubject$.next({
          exerciseBlock: exerciseBlock,
          parameterLink: parameterLink
        });
        return true;
      })
    );
  }

  /**
   * Agrega un valor de serie para la línea y subparámetro indicados
   * @param worksetValue Valor de serie
   */
  addValue(
    worksetValue: WorksetValue
  ): Observable<WorksetValue> {
    return this.api
      .post<WorksetValueResponse>(
        `values-block-exercises-catalog/`,
        worksetValue.toRequest()
      ).pipe(
        map(response => worksetValue.fromResponse(response))
      );
  }

  /**
   * Editar un valor de serie para la línea y subparámetro indicados
   * @param worksetValue Valor de serie
   */
  updateValue(
    worksetValue: WorksetValue
  ): Observable<WorksetValue> {
    return this.api
      .patch<WorksetValueResponse>(
        `values-block-exercises-catalog/${worksetValue.id}/`,
        worksetValue.toRequest()
      ).pipe(
        map(response => worksetValue.fromResponse(response))
      );
  }

  /**
   * Elimina un valor de serie
   * @param worksetValueId Identificador de valor de serie
   */
  deleteValue(
    worksetValueId: number
  ): Observable<boolean> {
    return this.api
      .delete<WorksetValueResponse>(
        `values-block-exercises-catalog/${worksetValueId}/`
      ).pipe(
        map(() => true)
      );
  }
}
