import { Component, ElementRef, OnInit, OnDestroy, ViewChild, AfterViewInit, ViewEncapsulation, isDevMode } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { BehaviorSubject, merge } from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { SubSink } from 'subsink';
import lottie, { AnimationItem } from 'lottie-web';

import { AuthService, Institution, InstitutionService, Subscription, SubscriptionService, User, UserService, UserType } from 'sp-core';
import { HeaderGroupAlignment, InstitutionDetailComponent } from 'sp-library';

import { AppService } from '@web/core/services';
import { SharingDataComponentsService } from '@web/shared/services/sharing-data-components';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';

@Component({
  selector: 'web-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('lottieLogo') lottieLogoRef: ElementRef;

  user: User;
  logo = 'assets/images/solo-performance.svg';
  HeaderGroupAlignment = HeaderGroupAlignment;

  UserType = UserType;

  currentInstitution: Institution;

  institutions: Array<Institution> = [];

  photoProfile: string;

  darkMode = new UntypedFormControl(true);

  currentSubscription: Subscription;

  private afterLogoLoaded$ = new BehaviorSubject<boolean>(true);

  private subSink = new SubSink();

  private logoAnimation: AnimationItem;

  isConnect = false;

  userConnectAccount: string;

  onBoardingComplete = false;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private authService: AuthService,
    private userService: UserService,
    private SHAREDATA: SharingDataComponentsService,
    private appService: AppService,
    private subscriptionService: SubscriptionService,
    private institutionService: InstitutionService
  ) { }

  ngOnInit(): void {

    // Escucha cambio en institución
    this.subSink.sink = this.institutionService.institutionChanged$.subscribe(institution => {
      const index = this.institutions.findIndex(x => x.id === institution.id);
      if (index < 0) return;
      this.institutions.splice(index, 1, institution);
      this.currentInstitution = institution;
    });

    this.subSink.sink = this.userService.user$.subscribe(user => {

      // Usuario en sesión
      this.user = user;

      this.isConnect = user?.isConnect ?? false;
      this.userConnectAccount = user?.connect ?? '';
      this.onBoardingComplete = user?.onboardingComplete ?? false;

      // Selecciona la institución por defecto del usuario
      this.institutions = this.user.institutions;
      const institutionId = this.authService.currentInstitutionId;
      if (institutionId) {
        this.currentInstitution = this.user.institutions.find(x => x.id === institutionId);
      }
    });

    this.subSink.sink = merge(
      this.userService.profile$.pipe(map(x => x.institutionalImage?.imageURL || x.photo)),
      this.userService.institutionalImage$.pipe(map(x => x.imageURL))
    ).subscribe(photo => {
      this.photoProfile = photo;
    });

    this.subSink.sink = this.SHAREDATA.getPictureProfile.subscribe(photo => {
      this.photoProfile = photo;
    });

    this.subSink.sink = this.subscriptionService.currentSubscription$.pipe(
      filter(x => !!x && !x.isEmpty)
    ).subscribe(subscription => {
      this.currentSubscription = subscription
    });

    // Animación del logo cuando carga el componente header y cada vez que carga el dashboard
    this.subSink.sink = merge(
      this.afterLogoLoaded$.pipe(filter(x => x)),
      this.appService.widgetsLoaded$.pipe(filter(x => x))
    ).pipe(
      startWith({})
    ).subscribe(() => {
      if (this.logoAnimation?.isLoaded) {
        this.logoAnimation.goToAndPlay(0);
      }
    });
  }

  ngAfterViewInit(): void {

    // Solicita la carga logo lottie
    this.logoAnimation = lottie.loadAnimation({
      container: this.lottieLogoRef.nativeElement,
      path: 'assets/lotties/spf-white-logo.json',
      renderer: 'svg',
      loop: false,
      autoplay: false
    });

    // Cuando el logo haya cargado se indica para animación
    this.logoAnimation.addEventListener('DOMLoaded', () => {
      this.afterLogoLoaded$.next(true);
    });
  }

  handleInstitutionSelectionChange(event: MatSelectChange): void {

    this.currentInstitution = this.institutions.find(x => x.id === event.value);
    if (!this.currentInstitution) return;

    this.userService.saveSelectedInstitution(
      this.user,
      this.currentInstitution.id
    ).subscribe(() => {
      window.location.reload();
    });
  }

  handleInstitutionEditClick(event: any, institution: Institution): void {
    event.preventDefault();
    event.stopPropagation();
    this.dialog.open(InstitutionDetailComponent, {
      data: institution
    });
  }

  handleLogoMouseEnter(e: any): void {
    this.logoAnimation.goToAndPlay(0);
  }

  /**
   * TODO: Al parecer no se utiliza. Confirmar
   */
  handleInstitutionNameClick(): void {
    this.dialog.open(InstitutionDetailComponent, {
      data: this.currentInstitution
    });
  }

  handleJoyrideOnDone(): void {
    // Marca como realizado la guía para no solicitarlo en cada actualización (refresh) de la aplicación web
    this.authService.tourDone = 1;
  }

  handleHelpClick(): void {
    window.open(`https://youtu.be/nXQdl4AHCVw`, '_blank');
  }

  logout(): void {
    this.authService
      .logout()
      .subscribe(() => {
        this.darkMode.setValue(null);
        this.router.navigate(['/auth'], { replaceUrl: true });
      })
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  goStripeDashboard(): void {
    window.open(`https://dashboard.stripe.com/${this.userConnectAccount}${isDevMode() ? '/test/dashboard' : '/dashboard'}`, '_blank');
  }

  handleCustomerPortalClick(): void {
    window.open(`https://billing.stripe.com/p/login/aEUaHmdDn5lc5RSaEE`, '_blank');
  }
}
