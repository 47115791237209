import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from 'sp-core';

import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import { I18NEXT_NAMESPACE_RESOLVER } from 'angular-i18next';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',

  },
  {
    path: '',
    component: MainLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      i18nextNamespaces: [
        'common',
        "core",
        'libraryComponents'
      ]
    },
    resolve: {
      i18next: I18NEXT_NAMESPACE_RESOLVER
    },
    children: [
      {
        path: 'dashboard',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('@web/modules/dashboard/dashboard.module').then(
          (m) => m.DashboardModule
        ),
        data: {
          i18nextNamespaces: [
            'common',
            'widgets',
            'dashboard',
            'programs',
            'teams',
            'athletes'
          ]
        },
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        }
      },
      {
        path: 'management',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('@web/modules/management/management.module').then(
          (m) => m.ManagementModule
        ),
        data: {
          i18nextNamespaces: [
            'common'
          ]
        },
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        }
      },
      {
        path: 'programs',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('@web/modules/library/programs/programs.module').then(
          (m) => m.ProgramsModule
        )
      },
      {
        path: 'program',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('@web/modules/program-builder/program-builder.module').then(
          (m) => m.ProgramBuilderModule
        ),
        data: {
          i18nextNamespaces: [
            'common',
            'programs',
            'phases',
            'workouts',
            'athletes',
            'validations'
          ]
        },
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        }
      },
      {
        path: 'library',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('@web/modules/library/library.module').then(
          (m) => m.LibraryModule
        )
      },
      {
        path: 'phase-builder',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('@web/modules/phase-builder/phase-builder.module').then(m => m.PhaseBuilderModule),
        data: {
          i18nextNamespaces: [
            'common',
            'phases',
            'phaseBuilder',
            'exercises',
            'athletes',
            'workouts',
            'blocks',
            'templateSets',
            'exerciseParameters'
          ]
        },
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        }
      },
      {
        path: 'profiling',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('@web/modules/profiling/profiling.module').then(
          (m) => m.ProfilingModule
        )
      },
      {
        path: 'profile',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('@web/modules/profile/profile.module').then(
          m => m.ProfileModule
        ),
        data: {
          i18nextNamespaces: [
            'common',
            'profile',
            'user'
          ]
        },
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        }
      },
      {
        path: 'pricing',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('@web/modules/pricing/pricing.module').then(
          m => m.PricingModule
        ),
        data: {
          i18nextNamespaces: [
            'common',
            'pricing'
          ]
        },
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        }
      },
      {
        path: 'marketplace',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('@web/modules/marketplace/marketplace.module').then(
          (m) => m.MarketplaceModule
        ),
        data: {
          i18nextNamespaces: ['marketplace']
        },
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        }
      },
      {
        path: 'logs',
        canActivateChild: [AuthGuard],
        loadChildren: () => import('@web/modules/logs/logs.module').then(
          (m) => m.LogsModule
        ),
        data: {
          i18nextNamespaces: [
            'common',
            'exercises',
            'workouts',
            'blocks',
            'templateSets',
            'exerciseParameters'
          ]
        },
        resolve: {
          i18next: I18NEXT_NAMESPACE_RESOLVER
        }
      },
    ]
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    loadChildren: () => import('@web/modules/auth/auth.module').then((m) => m.AuthModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
