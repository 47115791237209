import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';

@Component({
  selector: 'sp-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {

  @Input() tags: string[] = [];
  @Input() label: string;
  @Input() max: number = 3;
  @Input() input: boolean = true;

  @Output() _tags = new EventEmitter<string[]>();

  separatorKeysCodes: number[] = [ENTER, COMMA];

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Agrega una tag de la lista
   * @param event input de la tag
   * @author Martin Batun Tec.
  */
  addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add our tag
    if (value) { this.tags.push(value) };
    // Clear the input value
    event.input.value = '';

    this._tags.next(this.tags);
  }


  /**
   * Elimina una tag de la lista
   * @param tag la tag a eliminar
   * @author Martin Batun Tec.
  */
  removeTag(tag: string): void {
    const index = this.tags.indexOf(tag);
    // Delete our tag
    if (index >= 0) { this.tags.splice(index, 1) }
    this._tags.next(this.tags);
  }

}
