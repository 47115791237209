import { Injectable } from '@angular/core';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';

import { TranslationService } from './core/services';

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {

    constructor(private translationService: TranslationService) {
        super();
        this.itemsPerPageLabel = this.translationService.translate('items_per_page');
        this.firstPageLabel = this.translationService.translate('first_page');
        this.previousPageLabel = this.translationService.translate('previous_page');
        this.nextPageLabel = this.translationService.translate('next_page');
        this.lastPageLabel = this.translationService.translate('last_page');
    }
}