const USER_COMPLEMENT_DATA_CONTROL_NAMES = {
    countryCode: 'countryCode',
    language: 'language',
    institutionName: 'institutionName',
    teamName: 'teamName',
    fullname: 'name',
    phone: 'phone',
    region: 'region',
    professionalType: 'professionalType'
}

export {
    USER_COMPLEMENT_DATA_CONTROL_NAMES
};