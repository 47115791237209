import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import { Subscription, SubscriptionType, SubscriptionTypeString } from 'sp-core';

@Component({
  selector: 'web-upgrade-button',
  templateUrl: './upgrade-button.component.html',
  styleUrls: ['./upgrade-button.component.scss']
})
export class UpgradeButtonComponent implements OnInit, OnChanges {

  @Input() subscription: Subscription;

  text: string;

  SubscriptionType = SubscriptionType;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['subscription'] && this.subscription) {
      if (this.subscription.type === SubscriptionType.trial || this.subscription.type === SubscriptionType.free) {
        this.text = 'Upgrade Now';
      }
    }
  }

  ngOnInit(): void {
  }
}