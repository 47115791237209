import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { A11yModule } from '@angular/cdk/a11y';

import { GenderPipe } from './pipes';
import { CrudService } from './services/crud.service';
import { SpScrollableComponent } from './components/sp-scrollable/sp-scrollable.component';

import { SpLibraryConfig } from './sp-library-config.interface';
import { SP_LIBRARY_CONFIG } from './sp-library.constants';

const components = [
  SpScrollableComponent
];

const pipes = [
  GenderPipe
];

const directives = [];

const services = [
  CrudService
]

@NgModule({
  declarations: [
    components,
    pipes,
    directives
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    A11yModule
  ],
  exports: [
    components,
    pipes,
    directives
  ],
  providers: [
    services
  ]
})
export class SpLibraryModule {
  static forRoot(config?: SpLibraryConfig): ModuleWithProviders<SpLibraryModule> {
    return {
      ngModule: SpLibraryModule,
      providers: [
        {
          provide: SP_LIBRARY_CONFIG,
          useValue: config || <SpLibraryConfig>{
            ws: 'wss://api.dev.soloperformance.app/ws/'
          }
        }
      ]
    }
  }
}
