import { Component, Input, Output, EventEmitter, SimpleChanges, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'sp-upload-video',
  templateUrl: './upload-video.component.html',
  styleUrls: ['./upload-video.component.scss']
})
export class UploadVideoComponent implements AfterViewInit {

  // si existe un video de entrada lo muestra al iniciar
  @Input() video: string;

  @Input() readonly = false;

  @Output() file = new EventEmitter<File>();

  @ViewChild('fileInput') fileInputRef: ElementRef;

  // muestra las validaciones de error necesarios
  error: string;

  // El tamaño se define en MB
  @Input() maxSize = 15;

  // Only accept mp4 files.
  mediaType = 'video/mp4';

  videoIsloading = true;

  @ViewChild('htmlVideo', { static: false })
  set htmlVideoContent(element: ElementRef) {

    if (!element?.nativeElement) return;

    this.videoIsloading = true;
    const htmlVideo = element.nativeElement as HTMLVideoElement;
    htmlVideo.addEventListener('loadeddata', () => {
      setTimeout(() => {
        this.videoIsloading = false;
      });
    });
  };

  constructor() { }

  ngAfterViewInit(): void { }

  handleInputFileChange(event: any): void {

    const file: File = event.target.files[0];

    this.readFile(file);
  }

  handleClick(
    event: PointerEvent
  ): void {

    if (this.readonly) return;

    // Added because when the click event of the input file is called programmatically and listening change event of same input file, then this handleClick is called twice
    if (!event.isTrusted || !event.pointerId) return;

    this.fileInputRef.nativeElement.click();
  }

  onFileDropped(fileList: FileList): void {

    const file: File = fileList[0];

    this.readFile(file);
  }

  private readFile(file: any): void {

    this.error = null;

    if (!file.type.includes(this.mediaType)) {
      this.error = `Solamente se aceptan los formatos ${this.mediaType}`;
      return;
    }

    if (file.size > (this.maxSize * 1024) * 1024) {
      this.error = `El límite máximo del tamaño es de ${this.maxSize} MB`;
      return;
    }

    const fileReader = new FileReader();
    fileReader.onload = (e: any) => {
      const videoUrl = e.target.result;
      
      this.video = videoUrl;

      // Create a video element to get dimensions
      const videoElement = document.createElement('video');
      videoElement.src = videoUrl;

      // Wait for the metadata to load
      videoElement.onloadedmetadata = () => {
        setTimeout(() => {
          const videos = document.querySelector('#spUploadVideoExercise');
          file.width =  videos.clientWidth;
          file.height = videos.clientHeight;
          this.file.next(file);
        }, 500);
      };

    };
    fileReader.readAsDataURL(file);
  }
}
