<sp-dialog class="sp-exercise-detail">

  <!-- Title -->
  <sp-dialog-title [title]="exercise.isNew?('new_exercise' | i18nextCap):exercise.name"
    [subtitle]="'exercise_form_subtitle' | i18next">
  </sp-dialog-title>

  <!-- Content -->
  <sp-dialog-content>

    <form [formGroup]="form">

      <div [formGroupName]="CONTROL_NAMES.namesGroup"
        class="sp-exercise-detail__names-group">
        <div class="d-flex"
          style="gap:10px">
          <!-- english name -->
          <mat-form-field class="w-50"
            appearance="outline">
            <mat-label>{{'exercise_form_name' | i18next: {lang: 'english'} }}</mat-label>
            <input type="text"
              matInput
              cdkFocusInitial
              [required]="namesForm.hasError('required')"
              [formControlName]="CONTROL_NAMES.namesGroupEnglish">
            <img matPrefix
              class="country"
              src="assets/icons/united-states.svg"
              alt="united states">
          </mat-form-field>
          <!-- spanish name -->
          <mat-form-field class="w-50"
            appearance="outline">
            <mat-label>{{'exercise_form_name' | i18next: {lang: 'spanish'} }}</mat-label>
            <input type="text"
              matInput
              [required]="namesForm.hasError('required')"
              [formControlName]="CONTROL_NAMES.namesGroupSpanish">
            <img matPrefix
              class="country"
              src="assets/icons/spain.svg"
              alt="spain">
          </mat-form-field>
        </div>
        <mat-error *ngIf="(englishNameCtrl.touched || spanishNameCtrl.touched) && namesForm.hasError('required')">
          {{'error_exercise_form_required' | i18next: {field: 'English or Spanish'} }}
        </mat-error>
      </div>

      <div class="d-flex"
          style="gap:10px">
          <!-- url youtube -->
          <mat-form-field class="w-50"
            appearance="outline">
            <mat-label>{{'exercise_form_youtube_url' | i18next}}</mat-label>
            <input type="text"
              matInput
              [formControlName]="CONTROL_NAMES.pathYoutube">
            <img matPrefix
              class="country"
              src="assets/icons/link.svg"
              alt="link">
            <mat-error *ngIf="pathYoutubeCtrl.errors && pathYoutubeCtrl.errors.pattern">
              {{'error_exercise_form_regex' | i18next:{field: 'URL'} }}
            </mat-error>
          </mat-form-field>

          <!-- folder select -->
          <mat-form-field class="w-50"
            appearance="outline">
            <mat-label>{{'exercise_form_folder' | i18next}}</mat-label>
            <mat-select [formControlName]="CONTROL_NAMES.folders" (openedChange)="onSelectOpened()" multiple #folderList>
              <mat-select-trigger>
                {{ foldersListSelected?.length ? foldersListSelected[0].name : '' }}
                <span *ngIf="foldersListSelected?.length > 1"  class="example-additional-selection">
                  (+ {{foldersListSelected.length - 1}} {{foldersListSelected?.length === 2 ? 'other' : 'others'}} )
                </span>
              </mat-select-trigger>
              <mat-option class="custom-option">
                <input matInput #folderInputSearch
                        placeholder="Buscar..."
                        (click)="$event.stopPropagation()"
                        (keydown)="onInputKeyDown($event)"
                       />
              </mat-option>  
              <mat-option *ngFor="let folder of foldersList" [value]="folder?.id">{{folder?.name}}</mat-option>
            </mat-select>
            <img matPrefix
              class="country"
              src="assets/icons/folder.svg"
              alt="link">
            <mat-error *ngIf="pathYoutubeCtrl.errors && pathYoutubeCtrl.errors.pattern">
              {{'error_exercise_form_regex' | i18next:{field: 'URL'} }}
            </mat-error>
          </mat-form-field>
      </div>

      <!-- Exercise media viewer -->
      <sp-exercise-media *ngIf="exerciseMedia?.externalMedia && !pathYoutubeCtrl.errors;else uploadVideo"
        [exercise]="exerciseMedia">
      </sp-exercise-media>

      <!-- Upload video -->
      <ng-template #uploadVideo>
        <sp-upload-video [readonly]="readonly"
          [video]="pathCtrl.value"
          (file)="handleUploadVideoFile($event)">
        </sp-upload-video>
      </ng-template>

      <!-- filter -->
      <sp-exercise-filter class="d-block mt-4"
        [readonly]="readonly"
        [subcategoryIds]="subcategoriesCtrl.value"
        (change)="handleExerciseFilterChange($event)">
      </sp-exercise-filter>
    </form>

  </sp-dialog-content>

  <!-- Actions -->
  <sp-dialog-actions>
    <!-- Created by -->
    <a *ngIf="!exercise.isNew && exercise.audit?.createdBy"
      class="create_by"
      mat-stroked-button
      [matTooltip]="'exercise_form_created_by' | i18next"
      href="https://google.com"
      target="_blank">
      <img class="profile"
        [src]="exercise.audit?.createdBy.photo"
        alt="">
      <span>{{exercise.audit?.createdBy.fullName}}</span>
    </a>

    <!-- Delete -->
    <button *ngIf="!exercise.isNew"
      mat-icon-button
      [disabled]="readonly"
      [matTooltip]="'commons.delete' | i18nextCap"
      (click)="handleDeleteClick()">
      <img src="assets/icons/trash.svg"
        alt="delete">
    </button>

    <!-- Dashboard app options -->
    <ng-container *ngIf="currentApplication===Application.dashboard">
      <!-- Publish/ Unpublish -->
      <button *ngIf="checkPublishUnpublishOption()"
        class="publish"
        mat-flat-button
        type="button"
        name="button"
        color="accent"
        (click)="handlePublishUnpublishClick()">
        {{(exercise.status === PublishStatus.empty ? 'buttons.publish' :'buttons.unpublish') | i18nextCap}}
        {{'on_sp' | i18next}}
      </button>
    </ng-container>

    <!-- Cancel -->
    <button type="button"
      mat-button
      color="primary"
      [mat-dialog-close]="null">
      {{'buttons.cancel' | i18nextCap}}
    </button>

    <!-- Admin app options -->
    <ng-container *ngIf="currentApplication===Application.admin">
      <button *ngIf="exercise.status === PublishStatus.notVerified"
        mat-flat-button
        color="warn"
        (click)="handleApproveRejectClick(PublishStatus.rejected)">
        Reject
      </button>
      <button *ngIf="exercise.status === PublishStatus.notVerified"
        mat-flat-button
        color="primary"
        (click)="handleApproveRejectClick(PublishStatus.verified)">
        Aprove
      </button>
    </ng-container>

    <!-- Exercises navigation -->
    <ng-container *ngIf="exercisesNavigation.length">
      <!-- Previous exercise -->
      <button class="sp-icon-button sp-icon-button--filled"
        mat-icon-button
        color="primary"
        matTooltip="Save and go to previous exercise"
        [disabled]="form.invalid || isFirst"
        (click)="handlePreviousClick()">
        <mat-icon svgIcon="navigate-before"></mat-icon>
      </button>
      <!-- Next exercise -->
      <button class="sp-icon-button sp-icon-button--filled"
        mat-icon-button
        color="primary"
        matTooltip="Save and go to next exercise"
        [disabled]="form.invalid || isLast"
        (click)="handleNextClick()">
        <mat-icon svgIcon="navigate-next"></mat-icon>
      </button>
    </ng-container>

    <!-- Save -->
    <button mat-flat-button
      color="primary"
      [disabled]="form.invalid || readonly"
      (click)="handleSaveClick()">
      {{'buttons.save' | i18nextCap}}
    </button>
  </sp-dialog-actions>
</sp-dialog>