import { Pipe, PipeTransform } from '@angular/core';
import { ExerciseCategoryGroup } from 'sp-core';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  transform(category: ExerciseCategoryGroup, ...args: unknown[]): unknown {
    const _language = localStorage.getItem('USER_LANGUAGE');

    return _language == 'es' ? category.spanish_name || category.name : category.name || category.spanish_name;
  }

}
