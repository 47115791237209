<div class="sp-upload-video d-flex flex-column align-items-center justify-content-center p-4"
    (click)="handleClick($event)"
    spDragNDrop
    [spDragNDropDisabled]="readonly"
    (fileDropped)="onFileDropped($event)">

    <input #fileInput
        type="file"
        accept="video/mp4"
        (change)="handleInputFileChange($event)">

    <div *ngIf="video;else noVideo"
        class="sp-upload-video__video d-flex">
        <video #htmlVideo
            id="spUploadVideoExercise"
            [src]="video"
            autoplay
            loop
            width="100%"
            [muted]="'muted'"
            spDisableContextmenu>
        </video>
        <div class="sp-upload-video__spinner"
            [class.sp-upload-video__spinner--loading]="videoIsloading">
            <sp-progress-spinner [isRunning]="videoIsloading"
                size="small">
            </sp-progress-spinner>
        </div>
    </div>

    <ng-template #noVideo>
        <svg fill="rgb(110, 120, 143)"
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            width="90px"
            height="90px"
            viewBox="0 0 490 490"
            style="enable-background:new 0 0 490 490;"
            xml:space="preserve">
            <path
                d="M0,5.593v478.813h490V5.593H0z M218.309,35.25h56.482V91.73h-56.482V35.25L218.309,35.25z M210.752,197.592 c0-5.599,3.141-10.724,8.128-13.266c4.988-2.542,10.98-2.071,15.51,1.22l65.272,47.406c3.857,2.801,6.14,7.28,6.14,12.047 c0,4.768-2.282,9.246-6.14,12.047l-65.272,47.407c-2.592,1.882-5.664,2.842-8.75,2.842c-2.309,0-4.626-0.536-6.76-1.623 c-4.988-2.543-8.128-7.668-8.128-13.266V197.592L210.752,197.592z M379.556,245c0-52.675-30.429-98.369-74.628-120.449h137.388 v240.898H304.928C349.126,343.369,379.556,297.674,379.556,245z M50.5,35.25h56.482V91.73H50.5V35.25z M47.687,124.55h137.386 c-44.199,22.08-74.628,67.774-74.628,120.449s30.43,98.369,74.628,120.449H47.687V124.55z M106.982,454.75H50.5v-56.482h56.482 V454.75z M274.791,454.75h-56.482v-56.482h56.482V454.75z M442.599,454.75h-56.481v-56.482h56.481V454.75z M442.599,91.73h-56.481 V35.25h56.481V91.73z" />
        </svg>
    </ng-template>

    <div [class.sp-disabled]="readonly">
        <p class="my-3">Drop your video here, or <a>browse</a></p>
        <small>Supported videos .mp4</small>
    </div>

    <small *ngIf="error"><a>{{error}}</a></small>

</div>