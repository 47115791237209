import { EMOM_TYPES } from "../constants";
import { BlockType, EMOMType } from "./enumerations";
import { Serializer, WorkoutBlockRequest, WorkoutBlockResponse } from "./interfaces";

import { AuditUser } from "./audit-user";
import { Athlete } from "./athlete";
import { BlockCoding } from "./block-coding";
import { BlockSubcoding } from "./block-subcoding";
import { ExerciseBlock } from "./exercise-block";
import { Workout } from "./workout";
import { WorkoutBlockType } from "./workout-block-type";
import { PhaseDay } from "./phase-day";

export class WorkoutBlock implements Serializer<WorkoutBlock> {

    get nameHasChanges(): boolean {
        return this.name !== this.initialValues.name;
    }

    get orderHasChanges(): boolean {
        return this.order !== this.initialValues.order;
    }

    get exercisesNumberHasChanges(): boolean {
        return this.exercisesNumber !== this.initialValues.exercisesNumber;
    }

    get isActiveHasChanges(): boolean {
        return this.isActive !== this.initialValues.isActive;
    }

    get hasLibraryHasChanges(): boolean {
        return this.hasLibrary !== this.initialValues.hasLibrary;
    }

    get typeHasChanges(): boolean {
        return this.type?.id !== this.initialValues.type?.id;
    }

    get workoutHasChanges(): boolean {
        return this.workout?.id !== this.initialValues.workout?.id;
    }

    get codingHasChanges(): boolean {
        return this.coding?.id !== this.initialValues.coding?.id;
    }

    get subcodingHasChanges(): boolean {
        return this.subcoding?.id !== this.initialValues.subcoding?.id;
    }

    get minutesHasChanges(): boolean {
        return this.minutes !== this.initialValues.minutes;
    }

    get roundsHasChanges(): boolean {
        return this.rounds !== this.initialValues.rounds;
    }

    get notesHasChanges(): boolean {
        return this.notes !== this.initialValues.notes;
    }

    get foldersHasChanges(): boolean {
        const sortAndJoin = (arr: Array<number>) => arr?.slice()?.sort()?.join();
        const arraysHaveSameElements = (arr1: Array<number>, arr2: Array<number>) => 
            arr1?.length === arr2?.length && sortAndJoin(arr1) === sortAndJoin(arr2);

        return !arraysHaveSameElements(this.folder, this.initialValues.folder)
    }
    get allAthletesHasChanges(): boolean {
        return this.allAthletes !== this.initialValues.allAthletes;
    }

    get athletesHasChanges(): boolean {

        if (this.athletes.length !== this.initialValues.athletes.length) return true;

        const athletesIds = this.athletes.map(x => x.id).sort((a, b) => a - b);
        const initialAthletesIds = this.initialValues.athletes.map(x => x.id).sort((a, b) => a - b);
        return athletesIds.join() !== initialAthletesIds.join();
    }

    private initialValues: WorkoutBlock;

    /**
     * Verifica si existen cambios en modelo respecto a sus valores iniciales de creación.
     */
    get hasChanges(): boolean {
        return this.orderHasChanges ||
            this.nameHasChanges ||
            this.exercisesNumberHasChanges ||
            this.workoutHasChanges ||
            this.typeHasChanges ||
            this.codingHasChanges ||
            this.subcodingHasChanges ||
            this.minutesHasChanges ||
            this.roundsHasChanges ||
            this.notesHasChanges ||
            this.allAthletesHasChanges ||
            this.athletesHasChanges ||
            this.hasLibraryHasChanges ||
            this.isActiveHasChanges ||
            this.foldersHasChanges;
    }

    /**
     * Obtiene el máximo número de series por ejercicio
     * ```
     * En caso de que sea nulo significa que no tiene límites de series, por lo que se puede agregar las que se requieran
     * ```
     */
    get maxSeriesPerExercise(): number {
        if (this.type.type === BlockType.amrap || this.type.type === BlockType.emom || this.type.type === BlockType.rft) return 1;
        return null;
    }

    /**
     * Obtiene e establece el tipo de EMOM 1,2..10 cuando el tipo de bloque se trata de un tipo EMOM. Es dependiendo de los minutos asignados
     */
    get emomType(): EMOMType {
        if (this.type.type !== BlockType.emom) return null;
        const emomTypes = EMOM_TYPES.filter(x => x.minutes === this.minutes)
        if (emomTypes.length) {
            return +emomTypes[0].key;
        } else {
            return EMOMType.emom;
        }
    }
    set emomType(type: EMOMType) {
        const emomTypes = EMOM_TYPES.filter(x => x.key === type.toString())
        if (emomTypes.length) {
            this.minutes = emomTypes[0].minutes;
        } else {
            this.minutes = 1;
        }
    }

    private _blocksExercises: Array<ExerciseBlock> = [];
    /**
     * Obtiene o establece la colección de relaciones entre bloque<->ejercicio
     */
    get blocksExercises(): Array<ExerciseBlock> {
        return this._blocksExercises;
    }
    set blocksExercises(values: Array<ExerciseBlock>) {
        this._blocksExercises = values;
        // Set superset relations
        this.processSupersetRelations(this);
    }

    /**
     * Obtiene si es bloque es de tipo regular
     */
    get isRegular(): boolean {
        return this.type.isRegular;
    }

    getDay(): PhaseDay {
        return this.workout?.day;
    }

    constructor(
        public id?: number,
        /**
         * Orden de visualización en phase/workout builder
         */
        public order?: number,
        public name?: string,
        /**
         * Número de ejercicios. Indica el máximo de ejercicios que se le pueden asignar a un bloque.
         */
        public exercisesNumber?: number,
        public isActive?: boolean,
        /**
         * Obtiene o establece si el bloque es de librería
         */
        public hasLibrary?: boolean,
        public coding?: BlockCoding,
        public subcoding?: BlockSubcoding,
        public type?: WorkoutBlockType,
        public allAthletes?: boolean,
        public workout?: Workout,
        public audit?: AuditUser,
        /**
         * Atletas asignados al bloque
         */
        public athletes: Array<Athlete> = [],
        /**
         * Obtiene o establece si el bloque está oculto en visualización.
         * NOTA: No se obtiene de backend. Es un campo auxiliar para frontend
         */
        public hidden?: boolean,
        public minutes?: number,
        public rounds?: number,
        public notes?: string, 
        public folder?: Array<number>
    ) {
        this.initialValues = {} as WorkoutBlock;
        this.initialValues.workout = {} as Workout;
        this.initialValues.coding = {} as BlockCoding;
        this.initialValues.subcoding = {} as BlockSubcoding;
        this.initialValues.type = {} as WorkoutBlockType;

        this.setInitialValues(this);
    }

    /**
     * Des-serializa la estructura a un objeto Bloque
     * @param response Datos
     */
    fromResponse(response: WorkoutBlockResponse): WorkoutBlock {

        const block = new WorkoutBlock(
            response.id,
            response.order,
            response.name,
            response.limit,
            response.active,
            response.has_library,
            response.coding ? new BlockCoding().fromResponse(response.coding) : undefined,
            response.sub_coding ? new BlockSubcoding().fromResponse(response.sub_coding) : undefined,
            response.type ? new WorkoutBlockType().fromResponse(response.type) : undefined,
            response.all_users,
            response.workout ? new Workout().fromResponseWihoutBlocks(response.workout) : undefined,
            new AuditUser().fromResponse(response),
            response.athletes ? response.athletes.map(x => new Athlete().fromResponse(x)) : [],
            false,
            response.minutes,
            response.rounds,
            response.notes,
            response.folder
        )

        // block exercises related to block
        const blockExercises = response.exercises || response.exerciseblock;
        if (blockExercises) {
            block.blocksExercises = blockExercises.map(x => new ExerciseBlock().fromResponse(x));
        }

        this.setInitialValues(block);

        return block;
    }

    /**
     * Serializa el bloque a una estructura para crear o actualizar el bloque
     */
    toRequest(): WorkoutBlockRequest {
        return <WorkoutBlockRequest>{
            order: this.orderHasChanges
                ? (this.order || (this.initialValues.order ? 0 : undefined))
                : undefined,
            name: this.nameHasChanges
                ? (this.name || (this.initialValues.name ? '' : undefined))
                : undefined,
            workout: this.workoutHasChanges ?
                this.workout && this.workout.id ? this.workout.id : undefined
                : undefined,
            type: this.typeHasChanges ?
                this.type && this.type.id ? this.type.id : undefined
                : undefined,
            coding: this.codingHasChanges ?
                this.coding && this.coding.id ? this.coding.id : undefined
                : undefined,
            sub_coding: this.subcodingHasChanges ?
                this.subcoding && this.subcoding.id ? this.subcoding.id : undefined
                : undefined,
            limit: this.exercisesNumberHasChanges
                ? (this.exercisesNumber || (this.initialValues.exercisesNumber ? 0 : undefined))
                : undefined,
            minutes: this.minutesHasChanges
                ? (this.minutes || (this.initialValues.minutes ? 0 : undefined))
                : undefined,
            rounds: this.roundsHasChanges
                ? (this.rounds || (this.initialValues.rounds ? 0 : undefined))
                : undefined,
            notes: this.notesHasChanges
                ? (this.notes || (this.initialValues.notes ? '' : undefined))
                : undefined,
            has_library: this.hasLibraryHasChanges
                ? ((this.hasLibrary !== null && this.hasLibrary !== undefined) ? this.hasLibrary : undefined)
                : undefined,
            all_users: this.allAthletesHasChanges
                ? ((this.allAthletes !== null && this.allAthletes !== undefined) ? this.allAthletes : undefined)
                : undefined,
            active: this.isActiveHasChanges
                ? ((this.isActive !== null && this.isActive !== undefined) ? this.isActive : undefined)
                : undefined,
            athletes_id: this.athletesHasChanges
                ? this.athletes.map(x => x.id)
                : undefined,
            folder: this.foldersHasChanges
                ? ((this.folder !== null && this.folder !== undefined) ? this.folder : undefined)
                : undefined
        }
    }

    /**
     * Serializa el bloque a una estructura para actualizar únicamente el nombre del bloque
     */
    toUpdateNameRequest(): any {
        return {
            name: this.name ? this.name : ''
        }
    }

    /**
     * Serializa el bloque a una estructura para actualizar únicamente el tipo del bloque
     */
    toUpdateTypeRequest(): any {
        return {
            type: this.type.id || undefined
        }
    }

    addBlockExerciseLink(blockExerciseLink: ExerciseBlock): void {
        this.blocksExercises.push(blockExerciseLink);
        this.processSupersetRelations();
    }

    removeBlockExerciseLink(blockExerciseLinkId: number): void {
        const links = this.blocksExercises.filter(x => x.id === blockExerciseLinkId)
        if (links.length) {
            const index = this.blocksExercises.indexOf(links[0]);
            this.blocksExercises.splice(index, 1);
        }
        this.processSupersetRelations();
    }

    /**
     * Asigna relación de bloque<->ejercicio anterior y siguiente a cada uno de los bloque<->ejercicio del bloque
     * @param block 
     * @returns 
     */
    processSupersetRelations(block?: WorkoutBlock): void {

        const blocksExercises = this.getBlockExercisesToProcess(block);
        if (!blocksExercises.length) return;

        // Assign previous and next relations
        let previousLink = null;
        blocksExercises.forEach((link) => {
            if (previousLink) {
                previousLink.nextLink = link;
                link.previousLink = previousLink;
            }
            previousLink = link;
        });
        previousLink.nextLink = null;

        // Assign indicators
        this.processIndicators(block);
    }


    /**
     * Procesa y asigna los indicadores según el tipo de bloque (A, B, ...).
     * Se les indica un consecutivo por ejemplo: A1, A2, B1, B2... reiniciando el consecutivo por cada tipo de bloque
     * @param block Bloque
     */
    processIndicators(block?: WorkoutBlock): void {
        const blocksExercises = this.getBlockExercisesToProcess(block);
        if (!blocksExercises.length) return;

        const valueCounts: Map<string, number> = new Map();
        blocksExercises.forEach(link => {
            const value = link.indicator.value;
            link.indicator.consecutive = valueCounts.has(value) ? valueCounts.get(value) + 1 : 1;
            valueCounts.set(value, link.indicator.consecutive);
        });
    }

    /**
     * Check if any blocks have more than one serie. Check different row number, if many exists the block have multiple serie
     * @returns 
     */
    checkManySeriesPerBlock(): boolean {
        return !!this.blocksExercises.find(exerciseBlock => exerciseBlock.getRowNumbers().length > 1);
    }

    /**
     * Actualizar los datos del bloque
     * @param blockUpdated Bloque con los datos actualizados o datos recientes
     */
    update(blockUpdated: WorkoutBlock): void {
        this.order = blockUpdated.order;
        this.name = blockUpdated.name;
        this.exercisesNumber = blockUpdated.exercisesNumber;
        this.hasLibrary = blockUpdated.hasLibrary;
        this.isActive = blockUpdated.isActive;
        this.coding = blockUpdated.coding;
        this.subcoding = blockUpdated.subcoding;
        this.type = blockUpdated.type;
        this.blocksExercises = blockUpdated.blocksExercises;
        this.athletes = blockUpdated.athletes;
        this.minutes = blockUpdated.minutes;
        this.rounds = blockUpdated.rounds;
        this.notes = blockUpdated.notes;
        this.workout = blockUpdated.workout;
        this.allAthletes = blockUpdated.allAthletes;
    }

    /**
     * Replace a exercise block of current collection from workout block
     * @param previous Exercise block to replace for
     * @param current Exercise block to set
     */
    replaceExerciseBlock(
        previous: ExerciseBlock,
        current: ExerciseBlock
    ): void {
        const index = this.blocksExercises.indexOf(previous);
        if (index >= 0) {
            this.blocksExercises[index] = current;
        }
    }

    /**
     * Aplica los valores actuales del objeto para una siguiente validación de cambios
     */
    applyChanges(): void {
        this.setInitialValues(this);
    }

    private getBlockExercisesToProcess(block?: WorkoutBlock): Array<ExerciseBlock> {
        const blockToProcess = block || this;
        return blockToProcess.blocksExercises?.length ? blockToProcess.blocksExercises : [];
    }

    /**
     * Asigna valores iniciales al bloque
     * @param block Bloque con los datos actuales
     */
    private setInitialValues(block: WorkoutBlock): void {
        this.initialValues.order = block.order;
        this.initialValues.name = block.name;
        this.initialValues.exercisesNumber = block.exercisesNumber;
        this.initialValues.workout.id = block.workout?.id;
        this.initialValues.coding.id = block.coding?.id;
        this.initialValues.subcoding.id = block.subcoding?.id;
        this.initialValues.type.id = block.type?.id;
        this.initialValues.hasLibrary = block.hasLibrary;
        this.initialValues.isActive = block.isActive;
        this.initialValues.minutes = block.minutes;
        this.initialValues.rounds = block.rounds;
        this.initialValues.notes = block.notes;
        this.initialValues.athletes = block.athletes.slice();
        this.initialValues.allAthletes = block.allAthletes;
        this.initialValues.blocksExercises = block.blocksExercises.slice();
    }
}