import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition, MatLegacySnackBarHorizontalPosition as MatSnackBarHorizontalPosition } from '@angular/material/legacy-snack-bar';

import { NotificationSuccessComponent } from './../notification-success/notification-success.component';
import { NotificationErrorComponent } from './../notification-error/notification-error.component';

@Injectable()
export class NotificationService {

  constructor(
    private snackBar: MatSnackBar
  ) { }

  /**
   * Visualiza una notificación tipo exitoso
   * @param message Mensaje de proceso exitoso
   * @param verticalPosition Posicion vertical
   * @param horizontalPosition Posicion Horizontal
   */
  public success(
    message: string,
    verticalPosition?: MatSnackBarVerticalPosition,
    horizontalPosition?: MatSnackBarHorizontalPosition
  ): void {
    this.snackBar.openFromComponent(NotificationSuccessComponent, {
      data: message,
      verticalPosition: verticalPosition ?? 'top',
      horizontalPosition: horizontalPosition ?? 'end',
      duration: 3000
    });
  }

  /**
   * Visualiza una notificación tipo error
   * @param message Mensaje de error
   * @param verticalPosition Posicion vertical
   * @param horizontalPosition Posicion Horizontal
   */
  public error(
    message: string,
    verticalPosition?: MatSnackBarVerticalPosition,
    horizontalPosition?: MatSnackBarHorizontalPosition,
    duration?: number
  ): void {
    this.snackBar.openFromComponent(NotificationErrorComponent, {
      data: message,
      verticalPosition: verticalPosition || 'top',
      horizontalPosition: horizontalPosition || 'end',
      duration: duration || 3000
    });
  }

  /**
   * Cierra la notificación
   */
  public close(): void {
    this.snackBar.dismiss();
  }
}
