import { Serializer } from './interfaces';

import { BlockSubcoding } from './block-subcoding';
import { BlockCodingResponse } from './interfaces/block-coding-response.interface';

export class BlockCoding implements Serializer<BlockCoding> {

    /**
     * Permite intercambiar la serie siempre y cuando se trate de Strength o Skill
     */
    get isSerieChangeable(): boolean {
        return (this.id === 5 || this.id === 6);
    }

    constructor(
        public id?: number,
        public name?: string,
        public icon?: string,
        public alternativeIcon?: string,
        public subcodings: Array<BlockSubcoding> = []
    ) { }

    fromResponse(response: BlockCodingResponse): BlockCoding {
        const _language = localStorage.getItem('USER_LANGUAGE');
        const _name = _language == 'es' ? response.name_es || response.name : response.name || response.name_es;
        return new BlockCoding(
            response.id,
            _name,
            response.icon,
            response.alternative_icon,
            response.subcodings ? response.subcodings.map(subcoding => new BlockSubcoding().fromResponse(subcoding)) : []
        );
    }
    toRequest() {
        throw new Error('Method not implemented.');
    }
}