import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService, WorksetTypeValue, WorksetTypeValueResponse } from 'sp-core';

@Injectable()
export class WorksetTypeService {

  constructor(
    private api: ApiService
  ) { }

  /**
   * Agregar el tipo para una serie
   * @param worksetTypeValue Tipo de serie
   */
  create(
    worksetTypeValue: WorksetTypeValue
  ): Observable<WorksetTypeValue> {
    return this.api
      .post<WorksetTypeValueResponse>(`row-block-value/`, worksetTypeValue.toRequest())
      .pipe(
        map(response => worksetTypeValue.fromResponse(response))
      );
  }

  /**
   * Editar el tipo de una serie
   * @param worksetTypeValue Tipo de la serie
   */
  update(
    worksetTypeValue: WorksetTypeValue
  ): Observable<WorksetTypeValue> {
    return this.api
      .patch<WorksetTypeValueResponse>(`row-block-value/${worksetTypeValue.id}/`, worksetTypeValue.toUpdateRequest())
      .pipe(
        map(response => worksetTypeValue.fromResponse(response))
      );
  }
}