import { BlockType } from './enumerations';
import { Serializer, WorkoutBlockTypeResponse } from './interfaces';

export class WorkoutBlockType implements Serializer<WorkoutBlockType> {

    get isRegular(): boolean {
        return this.type === BlockType.regular;
    }

    /**
     * Tipo de bloque según el identificador. 
     * Actualmente no se tiene un enumerador desde backend para el tipo de bloque
     */
    get type(): BlockType {
        switch (this.id) {
            case 2:
                return BlockType.superset;
            case 3:
                return BlockType.amrap;
            case 4:
                return BlockType.emom;
            case 5:
                return BlockType.rft;
            default:
                return BlockType.regular;
        }
    }

    constructor(
        public id?: number,
        public name?: string,
        public isActive?: boolean
    ) { }

    fromResponse(response: WorkoutBlockTypeResponse): WorkoutBlockType {
        const _language = localStorage.getItem('USER_LANGUAGE');
        const _name = _language == 'es' ? response.name_es || response.name : response.name || response.name_es;
        return new WorkoutBlockType(
            response.id,
            _name,
            response.active
        );
    }

    toRequest() {
        throw new Error('Method not implemented.');
    }
}