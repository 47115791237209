import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import moment from 'moment';

import { SPF_DATETIME_FORMAT, User } from 'sp-core';

import { ChatService } from '../../services';
import { ChatFile, SocketChatMessage, Type } from '../../models';
import { SubSink } from 'subsink';
import { UploadFileService } from '../../services/upload-files.service';

@Component({
  selector: 'sp-chat-new-message',
  templateUrl: './chat-new-message.component.html',
  styleUrls: ['./chat-new-message.component.scss']
})
export class ChatNewMessageComponent implements OnInit, OnDestroy {

  @Input() user: User;

  @Input() recipient: User;

  allowEmojis = false;

  msg = '';

  isEmojiPickerVisible: boolean = false;

  maxSize = 20;

  error: string;

  private subSink = new SubSink();

  constructor(
    private chatService: ChatService<SocketChatMessage>,
    private uploadSrv: UploadFileService
  ) { }

  ngOnInit(): void {
    this.subSink.sink = this.chatService.allowEmojis$.subscribe((value: boolean) => {
      this.allowEmojis = value;
    });
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  send(val: NgForm): void {

    if (!val || val.invalid || !this.msg.trim().length) return;

    const message = {} as SocketChatMessage;
    message.userId = this.user.id;
    message.recipientId = this.recipient.id;
    message.comment = this.msg;
    message.createdAt = moment().utc().format(SPF_DATETIME_FORMAT);

    this.chatService.sendNewMessage(message);
    this.msg = '';
  }

  public addEmoji(event: any): void {
    this.msg = `${this.msg}${event.emoji.native}`;
    this.isEmojiPickerVisible = false;
  }

  uploadFile(file: File): void {
    let type = null;
    if (file.type.includes('image')) {
      type = Type.Image;
    }
    if (file.type.includes('video')) {
      type = Type.Video;
    }
    if (file.type.includes('application')) {
      type = Type.File;
    }

    if (file.size > (this.maxSize * 1024) * 1024) { this.error = `El limite mamixo de tamaño es de ${this.maxSize} MB`; return }


    if (type !== null) {
       this.uploadSrv.uploadFile(file).subscribe(
        ({ id }: ChatFile) => {
          const message = {} as SocketChatMessage;
          message.userId = this.user.id;
          message.recipientId = this.recipient.id;
          message.comment = file.name;
          message.createdAt = moment().utc().format(SPF_DATETIME_FORMAT);
          message.id_file = id;
          message.file_type = type;

          this.chatService.sendNewMessage(message);
          this.msg = '';
        },
        (error) => console.log(error)
      );
    }
  }
}