<sp-header class="sp-header sp-header--raised web-header">

  <!-- Logo -->
  <sp-header-group>
    <a [routerLink]="['/']"
      class="web-header__logo"
      (mouseenter)="handleLogoMouseEnter($event)">
      <div #lottieLogo
        class="web-header__logo-lottie">
      </div>
    </a>
  </sp-header-group>

  <!-- Institution name -->
  <sp-header-group>
    <mat-select class="ml-2 sp-tag sp-tag--secondary sp-tag--institution"
      joyrideStep="step1"
      [stepContent]="joyrideStep1Content"
      [doneTemplate]="joyrideStep1DoneButton"
      (done)="handleJoyrideOnDone()"
      [value]="currentInstitution?.id"
      (selectionChange)="handleInstitutionSelectionChange($event)">
      <mat-option *ngFor="let institution of institutions"
        [value]="institution.id">
        <div class="d-flex align-items-center">
          <img *ngIf="institution.image;else noInstitutionImage"
            class="web-institution-image"
            [src]="institution.image"
            alt="">
          <ng-template #noInstitutionImage>
            <mat-icon
              class="sp-mat-icon-color sp-mat-icon-color--fill web-institution-image web-institution-image--no-image"
              svgIcon="account">
            </mat-icon>
          </ng-template>
          <span class="flex-grow-1">{{institution.name}}</span>
          <button *ngIf="institution.id===currentInstitution?.id"
            class="ml-2 sp-icon-button sp-icon-button--filled"
            mat-icon-button
            [matTooltip]="'Edit'"
            [disabled]="institution.userType!==UserType.institutionManager"
            (click)="handleInstitutionEditClick($event, institution)">
            <mat-icon style="margin-right: 0 !important;"
              svgIcon="mode-edit">
            </mat-icon>
          </button>
        </div>
      </mat-option>
    </mat-select>
    <ng-template #joyrideStep1Content>
      {{'header.tour.institution' | i18next}}
    </ng-template>
    <ng-template #joyrideStep1DoneButton>
      <button mat-flat-button
        color="primary">
        Done
      </button>
    </ng-template>
  </sp-header-group>

  <!-- Navigation options -->
  <sp-header-group [expanded]="true">
    <!-- Dashboard -->
    <button mat-button
      class="web-header__option web-header__option--hover ml-2"
      routerLinkActive="sp-link-button--selected"
      [routerLink]="['/dashboard']">
      <span class="web-header__option-text">
        {{'header.nav.dashboard' | i18next}}
      </span>
    </button>

    <!-- Library -->
    <button mat-button
      class="web-header__option web-header__option--hover"
      routerLinkActive="sp-link-button--selected"
      [routerLink]="['/library']">
      <span class="web-header__option-text">
        {{'header.nav.library' | i18next}}
      </span>
    </button>

    <!-- <button mat-button
      class="web-header__option web-header__option--hover">
      <span class="web-header__option-text">Analytics</span>
    </button>
    <button
      mat-button
      class="web-header__option web-header__option--hover"
      routerLinkActive="sp-link-button--selected"
      [routerLink]="['/profiling']">
      <span class="web-header__option-text">Profiling</span>
    </button> -->

    <!-- Marketplace -->
    <button mat-button
      class="web-header__option web-header__option--hover"
      routerLinkActive="sp-link-button--selected"
      [routerLink]="['/marketplace']">
      <span class="web-header__option-text">
        {{'header.nav.marketplace' | i18next}}
      </span>
    </button>
  </sp-header-group>

  <!-- Settings/ Notifications -->
  <sp-header-group>

    <!--Responsive Menu-->
    <mat-icon class="web-header__icon"
      svgIcon="menu"
      [matMenuTriggerFor]="responsiveMenu">
      <mat-menu #responsiveMenu="matMenu"
        xPosition="before">
        <button mat-menu-item
          [routerLink]="['/dashboard']">
          <span>
            {{'header.nav.dashboard' | i18next}}
          </span>
        </button>
        <button mat-menu-item
          [routerLink]="['/library']">
          <span>
            {{'header.nav.library' | i18next}}
          </span>
        </button>
        <button mat-menu-item
          [routerLink]="['/marketplace']">
          <span>
            {{'header.nav.marketplace' | i18next}}
          </span>
        </button>

        <web-header-subscription [subscription]="currentSubscription">
        </web-header-subscription>

        <div style="display: flex;" >
          <button class="sp-icon-button"
            mat-menu-item
            matTooltip="Help"
            (click)="handleHelpClick()">
            <mat-icon svgIcon="help"></mat-icon>
          </button>
      
          <!-- Setttings -->
          <button mat-menu-item
            matTooltip="Settings"
            [matMenuTriggerFor]="settingsMenu">
            <mat-icon svgIcon="settings"></mat-icon>
          </button>
      
          <!-- Notifications -->
          <button mat-menu-item
            matTooltip="Notifications">
            <mat-icon svgIcon="notifications"></mat-icon>
          </button>
        </div>
      </mat-menu>
    </mat-icon>

  </sp-header-group>
  
  <sp-header-group [expanded]="true" [classes]="'justify-content-end'">
    <!-- Subscription section -->
    <web-header-subscription [subscription]="currentSubscription">
    </web-header-subscription>

    <div style="display:flex; justify-content: space-evenly;">
      <button class="sp-icon-button"
        mat-icon-button
        matTooltip="Help"
        (click)="handleHelpClick()">
        <mat-icon svgIcon="help"></mat-icon>
      </button>
  
      <!-- Setttings -->
      <button mat-icon-button
        matTooltip="Settings"
        [matMenuTriggerFor]="settingsMenu">
        <mat-icon svgIcon="settings"></mat-icon>
      </button>
  
      <!-- Notifications -->
      <button mat-icon-button
        matTooltip="Notifications">
        <mat-icon svgIcon="notifications"></mat-icon>
      </button>
    </div>
  </sp-header-group>

  <!-- Profile -->
  <sp-header-group class="web-header__user">

    <!-- User -->
    <div class="web-header__left-options">
      <button mat-button
        [matMenuTriggerFor]="menu">
        <div class="d-flex align-items-center py-3">
          <div class="web-header__user-name d-flex align-items-center">
            <span>{{user?.fullName}}</span>
            <!-- Institution image -->
            <img *ngIf="currentInstitution?.image;else defaultProfilePhoto"
              class="ml-3 sp-rounded"
              style="width: 45px;height: 45px;"
              [src]="currentInstitution?.image">
            <ng-template #defaultProfilePhoto>
              <mat-icon svgIcon="account">
              </mat-icon>
            </ng-template>
          </div>
        </div>
      </button>
    </div>

    <!-- User menu -->
    <mat-menu #menu="matMenu"
      xPosition="before">
      <div class="web-header__user-menu">

        <!-- Email -->
        <!-- <div class="sp-menu-button sp-menu-button--profile web-header__user-info"
        mat-menu-item>
        <mat-icon svgIcon="account"></mat-icon>
        <span>{{user?.email}}</span>
        </div> -->

        <!-- Profile -->
        <button class="sp-menu-button sp-menu-button--profile"
          mat-menu-item
          [routerLink]="['/profile']">
          <img *ngIf="photoProfile;else defaultProfilePhoto"
            class="rounded-circle sp-menu-button__image sp-cursor-pointer"
            [style.margin-right.px]="16"
            [src]="photoProfile">
          <ng-template #defaultProfilePhoto>
            <mat-icon svgIcon="account"></mat-icon>
          </ng-template>
          <span>{{'header.nav.profile' | i18next}}</span>
        </button>

        <!-- Stripe dashboard -->
        <button *ngIf="isConnect"
          class="sp-menu-button sp-menu-button--stripe"
          mat-menu-item
          (click)="goStripeDashboard()">
          <mat-icon svgIcon="stripe"></mat-icon>
          <span>{{'header.nav.stripe' | i18next}}</span>
        </button>

        <!-- Theme  -->
        <!-- TODO: Se deshabilita de momento. Se habilitará de nuevo cuando se trabaje el estilo light -->
        <!-- <div mat-menu-item
        class="d-flex align-items-center">
        <mat-icon [svgIcon]="darkMode.value?'dark-mode':'light-mode'">
        </mat-icon>
        <mat-slide-toggle
          color="primary"
          labelPosition="before"
          [formControl]="darkMode">
          <span>{{darkMode.value?'Dark mode':'Light mode'}}</span>
        </mat-slide-toggle>
        </div> -->

        <!-- Suscription -->
        <div class="sp-menu-button sp-menu-button--profile"
          mat-menu-item
          [matMenuTriggerFor]="subscriptionsMenu">
          <mat-icon svgIcon="payment"></mat-icon>
          <span>{{'header.nav.subscriptions' | i18next}}</span>
        </div>
        <mat-menu #subscriptionsMenu="matMenu">
          <button mat-menu-item
            [routerLink]="['/pricing/upgrade']">
            {{'header.nav.subscriptions_licenses' | i18next}}
          </button>
          <button mat-menu-item
            [routerLink]="['/pricing/extra-coaches/all']">
            {{'header.nav.subscriptions_extra_coaches' | i18next}}
          </button>
          <button mat-menu-item
            (click)="handleCustomerPortalClick()">
            {{'header.nav.customer_portal' | i18next}}
          </button>
        </mat-menu>

        <!-- Logout -->
        <button class="sp-menu-button sp-menu-button--profile"
          mat-menu-item
          (click)="logout()">
          <mat-icon svgIcon="logout"></mat-icon>
          <span>{{'header.nav.logout' | i18next}}</span>
        </button>
      </div>
    </mat-menu>
  </sp-header-group>
</sp-header>

<mat-menu #settingsMenu="matMenu"
xPosition="before">
<button mat-menu-item
  [routerLink]="['/management']"
  [matMenuTriggerFor]="managementMenu">
  <span>{{'header.nav.management' | i18next}}</span>
</button>
</mat-menu>
<mat-menu #managementMenu="matMenu">
<button mat-menu-item
  [routerLink]="['/management/coaches']">
  {{'common.entities.coaches' | i18next}}
</button>
<button mat-menu-item
  [routerLink]="['/management/teams']">
  {{'common.entities.teams' | i18next}}
</button>
<button mat-menu-item
  [routerLink]="['/management/athletes']">
  {{'common.entities.athletes' | i18next}}
</button>
<button mat-menu-item
  [routerLink]="['/management/clients']">
  {{'common.entities.clients' | i18next}}
</button>
</mat-menu>