<div class="web-header-subscription d-flex align-items-center">
    <!-- Days left -->
    <web-left-days class="mr-4"
        [subscription]="subscription">
    </web-left-days>

    <!-- Upgrade button -->
    <web-upgrade-button class="mr-4"
        [subscription]="subscription">
    </web-upgrade-button>
</div>