import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { catchError, map, mapTo } from 'rxjs/operators';
import moment from 'moment';

import {
  REQUEST_PARAM_NAMES,
  ApiService, AuthService, EntityAction, EntityType, PeriodFilter,
  PeriodFilterType, RequestParam,
  UserWidget, Widget, WidgetData, WidgetType, Workout,
  WidgetDataResponse, WidgetsDataResponse, UserWidgetResponse, UserWidgetsDataResponse, Team, Athlete, WidgetMetadata, SPF_DATE_FORMAT, AthleteStatusLogResponse, AthleteStatusLog, WidgetAthleteStatus, WidgetAthleteStatusResponse, WorkoutLog, WorkoutLogResponse
} from 'sp-core';
import { WidgetAction } from '@web/modules/widgets/models/interfaces';

@Injectable()
export class WidgetService {

  private widgetAddRequestedSubject$ = new Subject<Widget>();
  /**
   * Observable para saber cuando se solicita crear un nuevo widget
   */
  widgetAddRequested$ = this.widgetAddRequestedSubject$.asObservable();

  private widgetDuplicateRequestedSubject$ = new Subject<Widget>();
  /**
   * Observable para saber cuando se solicita duplicar un widget
   */
  widgetDuplicateRequested$ = this.widgetDuplicateRequestedSubject$.asObservable();

  private userWidgetRemoveRequestedSubject$ = new Subject<number>();
  /**
   * Observable para saber cuando se solicita crear un nuevo widget
   */
  userWidgetRemoveRequested$ = this.userWidgetRemoveRequestedSubject$.asObservable();

  private userWidgetAddedSubject$ = new Subject<UserWidget>();
  /**
   * Observable para saber cuando un nuevo widget de usuario ha sido agregado.
   */
  userWidgetAdded$ = this.userWidgetAddedSubject$.asObservable();

  latestUserWidgetRemoved = 0;
  private userWidgetRemovedSubject$ = new BehaviorSubject<number>(this.latestUserWidgetRemoved);
  /**
   * Observable para saber cuando un widget de usuario ha sido eliminado.
   */
  userWidgetRemoved$ = this.userWidgetRemovedSubject$.asObservable();

  private workoutActionSubject$ = new Subject<WidgetAction<Workout>>();
  /**
   * Observable para emitir las acciones realizadas sobre un widget
   */
  workoutAction$ = this.workoutActionSubject$.asObservable();

  constructor(
    private authService: AuthService,
    private apiService: ApiService,
  ) { }

  /**
   * Obtiene los widget existentes en sistema
   * @param params Parámetros de búsqueda
   */
  getWidgets(
    params?: Array<RequestParam>
  ): Observable<Array<Widget>> {

    // Filtros o parámetros.
    let httpParams = new HttpParams();
    if (params) {
      params.forEach(filter => {
        httpParams = httpParams.set(filter.key, filter.value);
      });
    }

    return this.apiService.get<WidgetsDataResponse>(
      'widget/',
      httpParams
    ).pipe(
      map(response => response.data.map(x => new Widget().fromResponse(x))),
      catchError(this.apiService.processError('WidgetService.getWidgets'))
    );
  }

  /**
   * Obtiene la lista de widget configurados del usuario en sesión.
   */
  getUserWidgets(): Observable<Array<UserWidget>> {

    let params = new HttpParams().set(REQUEST_PARAM_NAMES.sort, 'order');

    return this.apiService.get<UserWidgetsDataResponse>(
      'current-widgets/',
      params
    ).pipe(
      map(response => response.data.map(x => new UserWidget().fromResponse(x))),
      catchError(this.apiService.processError('WidgetService.getUserWidgets'))
    );
  }

  getProgramsData(
    period: PeriodFilter,
    teamIds: Array<number> = [],
    athleteIds: Array<number> = []
  ): Observable<Array<WidgetData>> {

    let httpParams = new HttpParams()
      .set('institution', this.authService.institutionId.toString());

    if (period.type === PeriodFilterType.days) {
      httpParams = httpParams.set('type', '1');
      httpParams = httpParams.set('starts', moment(period.startDate).format('YYYY-MM-DD'));
      httpParams = httpParams.set('ends', moment(period.endDate).format('YYYY-MM-DD'));
    } else {
      httpParams = httpParams.set('type', '2');
      httpParams = httpParams.set('year', period.year.toString());
    }

    if (teamIds.length) {
      httpParams = httpParams.set('teams', teamIds.join(','));
    } else if (athleteIds.length) {
      httpParams = httpParams.set('athletes', athleteIds.join(','));
    }

    return this.apiService.get<Array<WidgetDataResponse>>(
      `programs-filter/`,
      httpParams
    ).pipe(
      map(response => (response && response.length)
        ? response.map(x => new WidgetData().fromResponse(x))
        : []
      ),
      catchError(this.apiService.processError('WidgetService.getProgramsData'))
    );
  }

  getPhasesData(
    period: PeriodFilter,
    teamIds: Array<number> = [],
    athleteIds: Array<number> = []
  ): Observable<Array<WidgetData>> {

    let httpParams = new HttpParams()
      .set('institution', this.authService.institutionId.toString());

    if (period.type === PeriodFilterType.days) {
      httpParams = httpParams.set('type', '1');
      httpParams = httpParams.set('starts', moment(period.startDate).format('YYYY-MM-DD'));
      httpParams = httpParams.set('ends', moment(period.endDate).format('YYYY-MM-DD'));
    } else {
      httpParams = httpParams.set('type', '2');
      httpParams = httpParams.set('year', period.year.toString());
    }

    if (teamIds.length) {
      httpParams = httpParams.set('teams', teamIds.join(','));
    } else if (athleteIds.length) {
      httpParams = httpParams.set('athletes', athleteIds.join(','));
    }

    return this.apiService.get<Array<WidgetDataResponse>>(
      `phases-filter/`,
      httpParams
    ).pipe(
      map(response => (response && response.length)
        ? response.map(x => new WidgetData().fromResponse(x))
        : []
      ),
      catchError(this.apiService.processError('WidgetService.getPhasesData'))
    );
  }

  getWorkoutsData(
    period: PeriodFilter,
    teamIds: Array<number> = [],
    athleteIds: Array<number> = []
  ): Observable<Array<WidgetData>> {

    let httpParams = new HttpParams()
      .set('institution', this.authService.institutionId.toString());

    if (period.type === PeriodFilterType.days) {
      httpParams = httpParams.set('type', '1');
      httpParams = httpParams.set('starts', moment(period.startDate).format('YYYY-MM-DD'));
      httpParams = httpParams.set('ends', moment(period.endDate).format('YYYY-MM-DD'));
    } else {
      httpParams = httpParams.set('type', '2');
      httpParams = httpParams.set('year', period.year.toString());
    }

    if (teamIds.length) {
      httpParams = httpParams.set('teams', teamIds.join(','));
    } else if (athleteIds.length) {
      httpParams = httpParams.set('athletes', athleteIds.join(','));
    }

    return this.apiService.get<Array<WidgetDataResponse>>(
      `workouts-filter/`,
      httpParams
    ).pipe(
      map(response => (response && response.length)
        ? response[0]?.ids?.length  ? response.map(x=> x.ids.map(( [id, name, programId, date, phaseId, day]: [number, string, number, string, number, number] ) => {
          const widget: any = (x.month) 
            ? { id, name, starts: x.month, athletes: [x.team_id], related: { id: date }, related3: phaseId, related2: {id:programId} } 
            : { id, name, starts: date, athletes: [x.team_id], related: { id: phaseId }, related3: day, related2: {id:programId} }; 
          return new WidgetData().fromResponse(widget)
        })).reduce((acc, val) => acc.concat(Array.isArray(val) ? val.reduce((a, v) => a.concat(v), []) : val), [])
        :
        response.map(x => new WidgetData().fromResponse(x))
        : []
      ),
      catchError(this.apiService.processError('WidgetService.getWorkoutsData'))
    );
  }

  getWorkoutLogsData(
    period: PeriodFilter,
    teamIds: Array<number> = [],
    athleteIds: Array<number> = []
  ): Observable<Array<WorkoutLog>> {

    let httpParams = new HttpParams()
      .set('institution', this.authService.institutionId.toString());

    if (period.type === PeriodFilterType.days) {
      httpParams = httpParams.set('type', '1');
      httpParams = httpParams.set('starts', moment(period.startDate).format('YYYY-MM-DD'));
      httpParams = httpParams.set('ends', moment(period.endDate).format('YYYY-MM-DD'));
    } else {
      httpParams = httpParams.set('type', '2');
      httpParams = httpParams.set('year', period.year.toString());
    }

    if (teamIds.length) {
      httpParams = httpParams.set('teams', teamIds.join(','));
    } else if (athleteIds.length) {
      httpParams = httpParams.set('athletes', athleteIds.join(','));
    }

    return this.apiService.get<Array<WorkoutLogResponse>>(
      `logs-filter/`,
      httpParams
    ).pipe(
      map(response => (response && response.length)
        ? response.map(x => WorkoutLog.fromResponse(x))
        : []
      ),
      catchError(this.apiService.processError('WidgetService.getWorkoutLogsData'))
    );
  }

  getAthleteStatusData(
    athleteIds?: Array<number>,
    startDate?: Date,
    endDate?: Date,
    latest?: boolean
  ): Observable<Array<WidgetAthleteStatus>> {

    let params = new HttpParams();
    params = params.set('athletes', athleteIds.join(','));

    if (startDate) {
      params = params.set('date_start', moment(startDate).format(SPF_DATE_FORMAT));
    }

    if (endDate) {
      params = params.set('date_end', moment(endDate).format(SPF_DATE_FORMAT));
    }

    if (latest) {
      params = params.set('latest', latest ? '1' : '0');
    }

    return this.apiService.get<Array<WidgetAthleteStatusResponse>>(
      `main-widget-player-status/`,
      params
    ).pipe(
      map(response => {
        if (!response || !response.length) return [];
        return response.map(x => WidgetAthleteStatus.fromResponse(x));
      })
    );
  }

  getAthleteLastStatusData(
    athleteIds?: Array<number>,
  ): Observable<Array<WidgetAthleteStatus>> {
    return this.getAthleteStatusData(
      athleteIds,
      null,
      null,
      true
    );
  }

  /**
   * Agrega/configura un widget al usuario en sesión
   * @param widget Widget a agregar
   * @param axisX Posición X de ordenamiento
   * @param axisY Posición Y de ordenamiento
   */
  addWidgetToUser(
    widget: Widget,
    axisX: number,
    axisY: number
  ): Observable<UserWidget> {

    const body = {
      widget: widget.id,
      axis_x: axisX,
      axis_y: axisY
    };

    return this.apiService.post<UserWidgetResponse>('current-widgets/', body).pipe(
      map((response) => {
        const userWidget = new UserWidget().fromResponse(response);
        // Notifica que se ha agregado un nuevo widget al usuario.
        this.userWidgetAddedSubject$.next(userWidget);
        return userWidget;
      })
    );
  }

  /**
   * Elimina/ desasigna un widget del usuario en sesión
   * @param widgetId Widget a eliminar
   */
  removeWidgetFromUser(
    widgetId: number
  ): Observable<boolean> {
    return this.apiService
      .delete(`current-widgets/${widgetId.toString()}/`)
      .pipe(
        map(() => {
          this.latestUserWidgetRemoved = widgetId;
          this.userWidgetRemovedSubject$.next(widgetId);
          return true;
        })
      );
  }

  /**
   * Actualiza la información de la asignación del widget del usuario en sesión
   * @param userWidget Datos del widget de usuario
   */
  updateWidgetUser(
    userWidget: UserWidget
  ): Observable<boolean> {

    const body = {
      axis_x: userWidget.axisX,
      axis_y: userWidget.axisY,
      width: userWidget.width && userWidget.widget.resizable ? userWidget.width : undefined,
      height: userWidget.height && userWidget.widget.resizable ? userWidget.height : undefined
    };

    return this.apiService
      .patch(`current-widgets/${userWidget.id.toString()}/`, body)
      .pipe(
        mapTo(true)
      );
  }

  updateUserWidgetMetadata(
    userWidgetId: number,
    metadata: WidgetMetadata
  ): Observable<boolean> {

    const payload = <UserWidgetResponse>{
      objectsData: metadata.toRequest()
    };

    return this.apiService.patch(
      `current-widgets/${userWidgetId.toString()}/`,
      payload
    ).pipe(
      mapTo(true)
    );
  }

  requestAddWidgetToUser(widget: Widget): void {
    this.widgetAddRequestedSubject$.next(widget);
  }

  requestDuplicateWidgetFromUser(widget: Widget): void {
    this.widgetDuplicateRequestedSubject$.next(widget);
  }

  requestRemoveWidgetFromUser(widgetId: number): void {
    this.userWidgetRemoveRequestedSubject$.next(widgetId);
  }

  emitMainWidgetWorkoutCreateAction(
    createdWorkouts: Array<Workout>,
    createdWorkoutDate: Date,
  ): void {
    this.emitWidgetWorkoutAction(
      EntityAction.create,
      WidgetType.mainWidget,
      EntityType.workout,
      createdWorkouts,
      createdWorkoutDate
    );
  }

  emitCalendarWidgetWorkoutCreateAction(
    createdWorkouts: Array<Workout>,
    createdWorkoutDate: Date,
    teams: Array<Team> = [],
    athletes: Array<Athlete> = []
  ): void {
    this.emitWidgetWorkoutAction(
      EntityAction.create,
      WidgetType.calendarWidget,
      EntityType.workout,
      createdWorkouts,
      createdWorkoutDate,
      teams,
      athletes
    );
  }

  emitCalendarWidgetWorkoutUpdateAction(
    updateWorkouts: Array<Workout>,
    updatedWorkoutDate: Date,
    teams: Array<Team> = [],
    athletes: Array<Athlete> = []
  ): void {
    this.emitWidgetWorkoutAction(
      EntityAction.update,
      WidgetType.calendarWidget,
      EntityType.workout,
      updateWorkouts,
      updatedWorkoutDate,
      teams,
      athletes
    );
  }

  emitCalendarWidgetWorkoutDeleteAction(
    removedWorkouts: Array<Workout>,
    removedWorkoutDate: Date,
    teams: Array<Team> = [],
    athletes: Array<Athlete> = []
  ): void {
    this.emitWidgetWorkoutAction(
      EntityAction.delete,
      WidgetType.calendarWidget,
      EntityType.workout,
      removedWorkouts,
      removedWorkoutDate,
      teams,
      athletes
    );
  }

  private emitWidgetWorkoutAction(
    action: EntityAction,
    widgetType: WidgetType,
    entityType: EntityType,
    entities: Array<Workout>,
    date: Date,
    teams: Array<Team> = [],
    athletes: Array<Athlete> = []
  ): void {
    this.workoutActionSubject$.next({
      action,
      widgetType,
      entityType,
      entities,
      date,
      teams,
      athletes
    });
  }
}