import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map} from 'rxjs/operators';

import { ApiService, AuthService, WorkoutLocation, WorkoutLocationsData, WorkoutLocationsDataResponse } from 'sp-core';

@Injectable()
export class WorkoutLocationService {

  private requestedInstitutionalLocationsSubject$ = new BehaviorSubject<Array<WorkoutLocation>>([]);
  /**
   * Escucha cuando se solicita obtener ubicaciones de institución del usuario en sesión
   */
  requestedInstitutionalLocations$ = this.requestedInstitutionalLocationsSubject$.asObservable();

  constructor(
    private api: ApiService,
    private auth: AuthService
  ) { }

  /**
   * Obtiene la lista de ubicaciones de entrenamiento para la institución del usuario en sesión
   * @returns Lista de ubicaciones de entrenamiento
   */
  getListForCurrentInstitution(): Observable<Array<WorkoutLocation>> {
    return this.getList(this.auth.institutionId)
      .pipe(
        map(response => {
          this.requestedInstitutionalLocationsSubject$.next(response);
          return response;
        })
      );
  }

  getList(
    institutionId: number
  ): Observable<Array<WorkoutLocation>> {

    return this.getPaginatedList(institutionId)
      .pipe(
        map(response => response.data)
      );
  }

  getPaginatedList(
    institutionId: number
  ): Observable<WorkoutLocationsData> {

    const params = new HttpParams()
      .set('institution', institutionId.toString());

    return this.api.get<WorkoutLocationsDataResponse>(`locations/`, params)
      .pipe(
        map(response => new WorkoutLocationsData().fromResponse(response))
      );
  }
}