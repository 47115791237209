import {Serializer} from './interfaces/serializer.interface';
import {SurveyRequest, SurveyResponse} from './interfaces/survey-response.interface';
import {SurveyType} from './enumerations/survey-type.enum';
import {AuditUser} from './audit-user';
import {Question} from './question';


export class Survey implements Serializer<Survey> {
  constructor(
    public id?: number,
    public title?: string,
    public description?: string,
    public surveyType?: SurveyType,
    public institution?: number,
    public audit?: Pick<AuditUser, 'updatedAt' | 'createdAt'>,
    public questionSet?: Question[]
  ) {
  }

  static fromResponse(response: SurveyResponse): Survey {
    return new Survey().fromResponse(response);
  }

  fromResponse(response: SurveyResponse): Survey {
    return new Survey(
      response.id,
      response.title,
      response.description,
      response.survey_type,
      response.institution,
      new AuditUser().fromResponse({
        created_at: response.created_at,
        created_by: null,
        updated_at: response.updated_at,
        updated_by: null
      })
    );
  }

  toRequest(): SurveyRequest {
    return {
      title: this.title,
      description: this.description,
      survey_type: this.surveyType
    };
  }
}
