import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ApiService, DataResponse, Region, RegionResponse } from 'sp-core';

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  constructor(
    private apiService: ApiService
  ) { }

  getRegions(): Observable<Array<Region>> {
    return this.apiService
      .get<DataResponse<RegionResponse>>(`regions/`)
      .pipe(
        map(response => response.data.map(x => new Region().fromResponse(x))),
        catchError(this.apiService.handleError('RegionService.getRegions'))
      );
  }
}
