import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { I18NextModule } from 'angular-i18next';

import { SpDialogModule } from '../sp-dialog';
import { SpUploadModule } from '../sp-upload';

import { PhotoEditorComponent } from './photo-editor.component';

const COMPONENTS = [
  PhotoEditorComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    I18NextModule,
    SpDialogModule,
    SpUploadModule
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class SpPhotoEditorModule {
  constructor(
    private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.iconRegistry.addSvgIcon('delete', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/delete-fill0-24.svg'));
  }
}
