import { EventEmitter, Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of, from, Subject } from 'rxjs';
import { map, concatMap, toArray, take, tap } from 'rxjs/operators';
import moment from 'moment';

import {
  ApiService, Athlete, ExerciseBlock, Phase, PhaseDay, PhaseResponse,
  PhaseWeek, PhaseWeekResponse, ServiceResponse, WeekDays, Workout,
  WorkoutBlock
} from 'sp-core';

import { PhaseWeekService } from '@web/core/services';

@Injectable()
export class PhaseService {

  private phaseCreatedSubject$ = new BehaviorSubject<Phase>(null);
  /**
   * Escuchador de fase
   * ```
   * Obtiene la última fase agregada
   * ```
   */
  phaseCreated$ = this.phaseCreatedSubject$.asObservable();

  private phaseDeletedSubject$ = new BehaviorSubject<Phase>(null);
  /**
   * Escuchador de fase
   * ```
   * Obtiene la última fase eliminada
   * ```
   */
  phaseDeleted$ = this.phaseDeletedSubject$.asObservable();

  /**
   * Emite cuando la colección de workouts de la fase ha sido modificado
   */
  workoutsChanged$ = new BehaviorSubject<Array<Workout>>([]);

  /**
   * Emite cuando la colección de bloques de la fase ha sido modificado
   */
  blocksChanged$ = new BehaviorSubject<Array<WorkoutBlock>>([]);


  printSubscribersCount: number = 0;
  copy_printSubscribersCount: number = 0;

  /**
   * 
   */
  exerciseTransfering$ = new EventEmitter<ExerciseBlock>();

  /**
   * Emite cuando la colección de atletas de la fase se modifica. Según el programa al que está asignado
   */
  athletesChanged$ = new BehaviorSubject<{ phase: Phase, athletes: Array<Athlete> }>(null);

  private collapseBlocksChangedSubject$ = new Subject<boolean>();
  /**
   * Emite cuando un cambio en la acción de colapsar bloques se ha realizado
   */
  collapseBlocksChanged$ = this.collapseBlocksChangedSubject$.asObservable();

  private collapseDayBlocksChangedSubject$ = new Subject<{ day: PhaseDay, collapsed: boolean }>();
  /**
   * Emite cuando un cambio en la acción de colapsar bloques se ha realizado
   */
  collapseDayBlocksChanged$ = this.collapseDayBlocksChangedSubject$.asObservable();

  private isImageSplit = false;

  constructor(
    private apiService: ApiService,
    private weekService: PhaseWeekService
  ) { }

  /**
   * Obtiene la colección de semanas que corresponden a la fase, incluyendo sus días asignados
   * @param phaseId Identificador de fase
   */
  getById(phaseId: number): Observable<any> {
    return this.apiService
      .get<any>(`phases/${phaseId}/`)
      .pipe(map(response => {
        return response
      }));
  }

  /**
   * Obtiene la colección de semanas que corresponden a la fase, incluyendo sus días asignados
   * @param phaseId Identificador de fase
   */
  getWeeksAndDaysByPhase(phaseId: number): Observable<Array<PhaseWeek>> {
    return this.apiService
      .get<Array<PhaseWeekResponse>>(`all-days-week/${phaseId}/`)
      .pipe(map(response => {
        return response
          .map(weekDaysResponse => new PhaseWeek().fromResponse(weekDaysResponse))
          .sort((a, b) => a.order - b.order);
      }));
  }

  /**
   * Agrega las semanas indicadas a la fase
   * @param phase Fase al que estará asignado la semana
   * @param weeks Colección de semanas a agregar
   * @returns 
   */
  createWeeks(
    phase: Phase,
    weeks: Array<WeekDays>
  ): Observable<Array<PhaseWeek>> {

    if (!weeks.length) return of([]);

    return from(weeks)
      .pipe(
        concatMap(week => {
          return this.createWeek(phase, week)
            .pipe(
              // Antes de devolver el resultado asigna el id de la semana recién creada
              map(weekCreated => {
                week.id = weekCreated.id;
                return weekCreated;
              })
            )
        }),
        toArray()
      );
  }

  createWeek(
    phase: Phase,
    week: WeekDays
  ): Observable<PhaseWeek> {

    // Sin programa asignado. Debe ser una fase de plantilla
    if (!phase.program?.id) {
      return this.weekService.createForPlaylistProgram(phase.id);
    }
    // Programa Playlist: Las semanas se crean sin fechas asignadas de inicio y fin
    else if (phase.program.isPlaylist) {
      return this.weekService.createForPlaylistProgram(phase.id);
    }
    // Programa Calendar: Las semanas se crean con fecha de inicio y fin
    else {

      const phaseWeeks = phase.weeks.sort((a, b) => a.order - b.order);

      const phaseWeek = phaseWeeks.find(x => x.order === week.order);
      if (!phaseWeek) return of(null);

      phaseWeek.phaseId = phase.id;
      const index = phase.weeks.indexOf(phaseWeek);
      // Si es la primera semana le asigna la fecha de hoy
      if (index === 0) {
        phaseWeek.date = new Date();
      }
      // Sino es la primera semana, la fecha se obtiene agregándole 7 días a la fecha de la semana previa
      else {
        const phaseWeekPrevious = phaseWeeks[index - 1];
        if (phaseWeekPrevious.starts) {
          phaseWeek.date = moment(phaseWeekPrevious.starts).add(7, 'd').toDate();
        }
      }

      // Agrega la semana a la fase y recorre las siguientes
      return this.weekService
        .createAndMove(phaseWeek)
        .pipe(
          map(response => {
            // Complementa información necesaria para una siguiente iteración
            phaseWeek.id = response.id;
            phaseWeek.starts = response.starts;
            phaseWeek.ends = response.ends;
            return response;
          })
        );
    }
  }

  removeWeeks(
    phase: Phase,
    weeks: Array<WeekDays>
  ): Observable<any> {

    if (!weeks.length) return of([]);

    return from(weeks.map(x => x.id))
      .pipe(
        concatMap(weekId => this.removeWeek(phase, weekId)),
        toArray()
      );
  }

  /**
   * Elimina una semana de la fase indicada
   * @param weekId Identificador de semana a eliminar
   * @returns 
   */
  removeWeek(
    phase: Phase,
    weekId: number
  ): Observable<ServiceResponse> {

    // Sin programa asignado. Debe ser una fase de plantilla
    // TODO: Confirmar si se puede utilizar el mismo servicio de borrado de semanas para una fase de program playlist
    if (!phase.program?.id) {
      return this.weekService.delete(weekId);
    }
    // Programa Playlist
    else if (phase.program.isPlaylist) {
      return this.weekService.deleteForPlaylistProgram(weekId);
    }
    // Programa Calendar
    else {
      return this.weekService.deleteAndMove(weekId);
    }
  }

  cloneWeeks(
    phase: Phase,
    weeks: Array<WeekDays>
  ): Observable<Array<PhaseWeek>> {

    if (!weeks.length) return of([]);

    return from(weeks)
      .pipe(
        concatMap(week => {
          return this.cloneWeek(phase, week)
            .pipe(
              map(weekCloned => {
                // Asigna ID recién asignado en la clonación
                week.id = weekCloned.id;
                // Asigna ID de días para creación correcta de workouts
                weekCloned.days.forEach(day => {
                  const weekDay = week.days.find(x => x.day === day.day);
                  if (weekDay) {
                    weekDay.id = day.id;
                  }
                });
                return weekCloned;
              })
            );
        }),
        toArray()
      );
  }

  /**
   * Clona una semana de fase.
   * TODO: Solicitar a backend que el clonado de librería y el clonado de programa calendar devuelvan sólo el objeto semana clonado con sus días. UNA VEZ REALIZADO ÉSTO MIGRAR EL MAPEO AL NIVEL SUPERIOR
   * @param phase Fase al que corresponde la semana a clonar
   * @param week Semana a clonar
   * @returns 
   */
  cloneWeek(
    phase: Phase,
    week: WeekDays
  ): Observable<PhaseWeek> {

    // Sin programa asignado. Debe ser una fase de plantilla
    if (!phase.program?.id) {

      return this.weekService.clone(week.originWeekId);
    }
    // Programa Playlist
    else if (phase.program.isPlaylist) {

      return this.weekService.cloneForPlaylistProgram(week.originWeekId);
    }
    // Programa Calendar
    else {

      return this.weekService.cloneAndMove(week.originWeekId);
    }
  }

  saveAsTemplate(
    phase: Phase
  ): Observable<any> {
    return this.apiService
      .post(`phase-template/${phase.id}/`, { name: phase.defaultName });
  }

  updateTemplate(
    phase: Phase
  ): Observable<any> {
    return this.apiService.patch(
      `phase-template/${phase.id}/`,
      { name: phase.name }
    );
  }

  create(
    phase: Phase
  ): Observable<Phase> {
    return this.apiService
      .post<PhaseResponse>('phases/', phase.toRequest())
      .pipe(
        map(response => {
          const phaseCreated = phase.fromResponse(response);
          this.phaseCreatedSubject$.next(phaseCreated);
          return phaseCreated;
        })
      );
  }

  updateName(
    phase: Phase
  ): Observable<any> {
    return this.apiService.patch(
      `phases/${phase.id}/`,
      { name: phase.name }
    );
  }

  updateFolder(
    phase: Phase
  ): Observable<any> {
    return this.apiService.patch<PhaseResponse>(
      `phase-library/${phase.id}/`,
      { folder: phase?.folder }
    ).pipe(map(response => {
      phase.folder = response.folder?.map(x => x);
      return phase;
    }));
  }

  delete(phase: Phase): Observable<Phase> {
    return this.apiService
      .delete(`phases/${phase.id}/`)
      .pipe(
        map(() => {
          this.phaseDeletedSubject$.next(phase);
          return phase;
        })
      );
  }

  deletePlaylist(phase: Phase): Observable<Phase> {
    return this.apiService
      .delete(`phase-list/${phase.id}/`)
      .pipe(
        map(() => {
          this.phaseDeletedSubject$.next(phase);
          return phase;
        })
      );
  }

  /**
   * Clonar una fase de un programa tipo playlist
   * @param phase Fase a clonar
   * @returns 
   */
  clonePlaylist(
    phase: Phase
  ): Observable<Phase> {
    return this.apiService
      .post<PhaseResponse>(`phase-list/${phase.id}/clone/`, {})
      .pipe(
        map(response => phase.fromResponse(response))
      )
  }

  collapseExpandBlocks(
    collapse: boolean
  ): void {
    this.collapseBlocksChangedSubject$.next(collapse);
  }

  collapseExpandDayBlocks(
    day: PhaseDay,
    collapse: boolean
  ): void {
    this.collapseDayBlocksChangedSubject$.next({
      day,
      collapsed: collapse
    });
  }

  // isPrinting(value: boolean){
  //   this.isPrinting$.next(value);
  // }
}
