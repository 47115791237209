import { Component, Input, OnInit } from '@angular/core';

import { Subscription, SubscriptionType } from 'sp-core';

@Component({
  selector: 'web-left-days',
  templateUrl: './left-days.component.html',
  styleUrls: ['./left-days.component.scss']
})
export class LeftDaysComponent implements OnInit {

  @Input() subscription: Subscription;

  SubscriptionType = SubscriptionType;

  constructor() { }

  ngOnInit(): void {
  }

}
