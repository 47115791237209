import { Injectable } from '@angular/core';

import { I18NextPipe } from 'angular-i18next';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(
    private i18NextPipe: I18NextPipe
  ) { }

  /**
   * Traduce el texto con la clave indicada
   * @param key Clave del texto a traducir
   * @returns Texto traducido en el lenguage actual
   */
  translate(key: string): string {

    return this.i18NextPipe.transform(key);
  }

  /**
   * Traduce el texto con la clave indicada. En mayúsculas
   * @param key Clave del texto a traducir
   * @returns Texto traducido en el lenguage actual
   */
  translateCap(key: string): string {
    return this.i18NextPipe.transform(key, { format: 'cap' });
  }
}
