import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { filter } from 'rxjs/operators';
import moment from 'moment';
import _ from 'lodash';
import { SubSink } from 'subsink';

import { ScrollService, User } from 'sp-core';

import { SocketChatMessage } from '../../models';
import { Subject } from 'rxjs';
import { ChatService } from '../../services';

@Component({
  selector: 'sp-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() messages: Array<SocketChatMessage> = [];

  @Input() update: Subject<null>;

  private _channel: string;
  get channel(): string {
    return this._channel;
  }
  @Input('channel') set setChannel(value: string) {
    this._channel = value;
    if (!this._channel) return;
    this.createAndConnectToChannel();
  }

  @Output() scrollEnd = new EventEmitter<null>();

  @ViewChild('chatsContainer', { read: ElementRef }) chatsContainerRef: ElementRef;

  @ViewChild('chatMessages') chatMessagesRef: ElementRef;

  remitent: User;

  recipient: User;

  private subSink = new SubSink();

  constructor(
    private scrollService: ScrollService,
    private chatService: ChatService<SocketChatMessage>
  ) { }

  ngOnInit(): void {

    this.subSink.sink = this.chatService.remitent$.subscribe((user: User) => {
      this.remitent = user;
    });

    this.subSink.sink = this.chatService.recipient$.subscribe((user: User) => {
      this.recipient = user;
    });

    this.subSink.sink = this.scrollService.end.pipe(
      filter(el => el.nativeElement === this.chatsContainerRef.nativeElement)
    ).subscribe(() => {
      this.scrollEnd.emit();
    });

    if (this.update) {
      this.subSink.sink = this.update.subscribe(() => {
        this.scrollToBottom();
      });
    }
  }

  ngAfterViewInit(): void {
    this.scrollToBottom();
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
    this.chatService.disconnect();
  }

  getDate(id?: number): string {
    const getIndex = _.findIndex(this.messages, ['id', id]);
    return moment(this.messages?.[getIndex]?.createdAt).isSame(this.messages?.[getIndex - 1]?.createdAt, 'day')
      ? ''
      : moment(this.messages?.[getIndex]?.createdAt).isValid()
        ? moment(this.messages[getIndex].createdAt).format('YYYY, MMM D')
        : '';
  }

  /**
   * Retorna si el mensaje anteriro es del mismo usuaio.
   * @param i index del mensaje a consultar
   * @author Martin Batun Tec.
  */
  prevMsgIsMe = (i: number) => i > 0 ? (this.messages[i]?.userId === this.messages[i - 1]?.userId) : false;


  /**
   * Retorna si el mensaje siguiente es del mismo usuaio.
   * @param i index del mensaje a consultar
   * @author Martin Batun Tec.
  */
  nextMsgIsMe = (i: number) => i < this.messages.length - 1 ? (this.messages[i]?.userId === this.messages[i + 1]?.userId) : true;

  private createAndConnectToChannel() {
    // Create and connect to chat channel
    this.chatService.createChannel(this.channel);
    this.chatService.connect((message) => {
      this.messages.push(message);
    });
  }

  private scrollToBottom(): void {
    if (!this.chatMessagesRef?.nativeElement) return;
    const chatMessages = (<HTMLElement>this.chatMessagesRef.nativeElement);
    //chatMessages.scrollTop = 50//chatMessages.scrollHeight - 10;
    chatMessages.scrollIntoView({ block: 'end', behavior: 'smooth' });
  }
}