import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import moment from 'moment';

import { ApiService, SPF_DATE_FORMAT, WorkoutLog, WorkoutLogResponse, WorkoutLogSaveRequest } from 'sp-core';

@Injectable()
export class WorkoutLogService {

  constructor(
    private api: ApiService
  ) { }

  /**
   * Obtiene los registros de log para los atletas asignados a un workout.
   * ```
   * EP: GET workout-atlete-log/<workoutId>
   * ```
   * @param workoutId Identificador de workout original (padre)
   * @returns 
   */
  getByParentId(
    workoutId: number
  ): Observable<Array<WorkoutLog>> {

    return this.api.get<Array<WorkoutLogResponse>>(
      `workout-atlete-log/${workoutId}/`
    ).pipe(
      map(response => response.map(x => new WorkoutLog().fromResponse(x))),
      catchError(this.api.processError('WorkoutLogService.getByParentId'))
    );
  }

  /**
   * Actualiza/crea registros de log para el workout y los atletas indicados
   * ```
   * EP: POST start-workout-atlete-logs/
   * ```
   * @param workoutId Identificador de workout
   * @param workoutDate Fecha del workout
   * @param logs Registros de logs a guardar
   * @returns 
   */
  udpateMultipleAthletes(
    workoutId: number,
    workoutDate: Date,
    logs: Array<WorkoutLog>
  ): Observable<boolean> {

    const payload = <WorkoutLogSaveRequest>{
      workout_id: workoutId,
      date: moment.utc(workoutDate).format(SPF_DATE_FORMAT),
      athletes_info: logs.map(x => x.toAthleteInformationRequest())
    };

    return this.api.post(
      `start-workout-atlete-logs/`,
      payload
    ).pipe(
      catchError(this.api.processError('WorkoutLogService.updateMultipleAthletes')),
      map(() => true)
    );
  }

  /**
   * Create/update log. The log is corresponding to one athlete
   * @param workoutId 
   * @param workoutDate 
   * @param workoutLog 
   * @returns 
   */
  update(
    workoutId: number,
    workoutDate: Date,
    workoutLog: WorkoutLog
  ): Observable<number> {

    const payload = <WorkoutLogSaveRequest>{
      workout_id: workoutId,
      date: moment(workoutDate).format(SPF_DATE_FORMAT),
      athletes_info: [workoutLog.toAthleteInformationRequest()]
    };

    return this.api.post(
      `workout-atlete-logs/`,
      payload
    ).pipe(
      catchError(this.api.processError('WorkoutLogService.updateMultipleAthletes')),
      map((response: { log_workout_set: number }) => {
        return response.log_workout_set;
      })
    );
  }
}
