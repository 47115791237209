import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { themeMode, User } from 'sp-core';

import { AppInitialData } from '../models';

@Injectable()
export class AppService {

  /**
   * Observable para indicar cambio de tema
   */
  themeChanged$ = new BehaviorSubject<themeMode>(null);

  /**
   * Observable para indicar que el componente de dashboard ha sido cargado
   */
  dashboardLoaded$ = new BehaviorSubject<boolean>(false);

  /**
   * Observable para indicar que los widgets han sido cargados
   */
  widgetsLoaded$ = new BehaviorSubject<boolean>(false);

  private initialDataSubject$ = new BehaviorSubject<AppInitialData>(null);
  /**
   * Observable para indicar que los datos de inicio de la aplicación web se han cargado
   */
  initialData$ = this.initialDataSubject$.asObservable().pipe(filter(x => !!x));

  private documentClickSubject$ = new BehaviorSubject<any>(null);
  /**
   * Observable para escuchar el evento click del documento
   */
  documentClick$ = this.documentClickSubject$.asObservable();

  closeMainMenu$ = new Subject<string>();
  
  userPermissions: User;

  constructor() { }

  /**
   * Notifica el evento click del documento
   * @param event 
   */
  documentClicked(event: any): void {
    this.documentClickSubject$.next(event);
  }

  emitInitialDataChanged(
    initialData: AppInitialData
  ) {
    this.userPermissions = initialData.authenticatedUser
    this.initialDataSubject$.next(initialData);
  }

  closeMainMenu(submenuOpened: string) {
    this.closeMainMenu$.next(submenuOpened);
  }
}