import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';

import { UpgradeButtonComponent } from './upgrade-button/upgrade-button.component';
import { LeftDaysComponent } from './left-days/left-days.component';

const COMPONENTS = [
  LeftDaysComponent,
  UpgradeButtonComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class SubscriptionModule { }
