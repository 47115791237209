import { Component, OnInit, ViewEncapsulation, AfterViewInit, ViewChild, ElementRef, OnDestroy, Inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UntypedFormBuilder, UntypedFormGroup, AbstractControl, Validators } from '@angular/forms';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { forkJoin } from 'rxjs';
import { finalize, map, mergeMap, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import Swiper, { SwiperOptions } from 'swiper';
import { SwiperComponent } from 'ngx-useful-swiper';

// TODO: Mientras se publica versión compatible con angular 13
// import { Country as ngxCountry } from 'ngx-mat-intl-tel-input/lib/model/country.model';
import { Country as ngxCountry } from 'sp-library';

import {
  ProfessionalType, LanguageCountryCode, UserIsoLanguage,
  Institution, Team, Region, Country, Subscription, UserLanguage,
  UtilitiesService, TeamService, CatalogService, AuthService, User, UserService,
  InstitutionService, ProgressSpinnerService
} from 'sp-core';

import { RegionService } from '@web/core/services';

import { USER_COMPLEMENT_DATA_CONTROL_NAMES } from './user-complement-data.constants';
import { OverlayContainer } from '@angular/cdk/overlay';
import { UserComplementDataSlide } from './user-complement-data.models';
import { of } from 'rxjs/internal/observable/of';

@Component({
  selector: 'web-user-complement-data',
  templateUrl: './user-complement-data.component.html',
  styleUrls: ['./user-complement-data.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserComplementDataComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('swiper', { static: false }) swiperComponent: SwiperComponent;
  @ViewChild('institutionNameInput') institutionNameInputRef: ElementRef;
  @ViewChild('teamNameInput') teamNameInputRef: ElementRef;
  @ViewChild('fullNameInput') fullNameInputRef: ElementRef;
  @ViewChild('phoneInput', { read: ElementRef }) phoneInputRef: ElementRef;
  @ViewChild('regionSelect') regionSelectRef: MatSelect;
  @ViewChild('professionalTypeSelect') professionalTypeSelectRef: MatSelect;

  @ViewChild('btnFinish') btnFinishRef: ElementRef;

  CONTROL_NAMES = USER_COMPLEMENT_DATA_CONTROL_NAMES;

  form: UntypedFormGroup;

  formLanguage: UntypedFormGroup;

  isBeginning = true;

  isEnd = false;

  currentActiveIndex = 0;

  professionalTypes: Array<ProfessionalType> = [];

  regions: Array<Region> = [];

  CountryCode = LanguageCountryCode;

  UserLanguage = UserLanguage;

  team: Team;

  user: User;

  expand: boolean;

  /**
   * Región inicial. Valor pre-seleccionado: Europa
   */
  private initialRegion = 3;

  get languageCtrl(): AbstractControl {
    return this.formLanguage.get(this.CONTROL_NAMES.language);
  }

  /**
   * Región por defecto en caso de que no se encuentre región: Rest of world
   */
  private defaultRegion = 4;
  get countryCodeCtrl(): AbstractControl {
    return this.form.get(this.CONTROL_NAMES.countryCode);
  }

  get institutionNameCtrl(): AbstractControl {
    return this.form.get(this.CONTROL_NAMES.institutionName);
  }

  get institutionNameInput(): HTMLInputElement {
    return this.institutionNameInputRef?.nativeElement;
  }

  get teamNameCtrl(): AbstractControl {
    return this.form.get(this.CONTROL_NAMES.teamName);
  }

  get teamNameInput(): HTMLInputElement {
    return this.teamNameInputRef?.nativeElement;
  }

  get fullNameCtrl(): AbstractControl {
    return this.form.get(this.CONTROL_NAMES.fullname);
  }

  get fullNameInput(): HTMLInputElement {
    return this.fullNameInputRef?.nativeElement;
  }

  get phoneCtrl(): AbstractControl {
    return this.form.get(this.CONTROL_NAMES.phone);
  }

  get phoneInput(): HTMLInputElement {
    return this.phoneInputRef?.nativeElement?.querySelector('.mat-input-element');
  }

  get phoneCountrySelector(): HTMLInputElement {
    return this.phoneInputRef?.nativeElement?.querySelector('.country-selector');
  }

  get regionCtrl(): AbstractControl {
    return this.form.get(this.CONTROL_NAMES.region);
  }

  get professionalTypeCtrl(): AbstractControl {
    return this.form.get(this.CONTROL_NAMES.professionalType);
  }

  get btnFinish(): HTMLButtonElement {
    return this.btnFinishRef?.nativeElement;
  }

  SLIDES: Array<UserComplementDataSlide> = [];

  swiperConfig: SwiperOptions;

  private defaultInstitution: Institution;

  private firstTeam: Team;

  private subSink = new SubSink();

  private requestOnlyRegion: boolean;

  private addInstitutionSlide = false;
  private addTeamSlide = false;
  private addUserSlide = false;
  private addPhoneSlide = false;
  private addProfessionalTypeSlide = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { requestOnlyRegion: boolean },
    private dialogRef: MatDialogRef<UserComplementDataComponent>,
    private overlayContainer: OverlayContainer,
    private fb: UntypedFormBuilder,
    private sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
    private institutionService: InstitutionService,
    private teamService: TeamService,
    private authService: AuthService,
    private catalogService: CatalogService,
    private spinnerService: ProgressSpinnerService,
    private regionService: RegionService,
    private userService: UserService
  ) {

    this.requestOnlyRegion = data.requestOnlyRegion;

    this.iconRegistry.addSvgIcon('united-states', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/united-states.svg'));
    this.iconRegistry.addSvgIcon('spain', this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/spain.svg'));

    this.overlayContainer.getContainerElement().classList.add('ngx-mat-intl-tel-input');

    this.createForms();

    this.expand = false;
  }

  ngOnInit(): void {

    // Escucha cuando se obtiene información del usuario actual
    this.subSink.sink = this.userService.user$
      .pipe(
        mergeMap(user => {
          return this.institutionService.defaultInstitution$.pipe(
            map(defaultInstitution => {
              this.defaultInstitution = defaultInstitution;
              return user
            })
          );
        })
      ).subscribe(user => {

        this.user = user;

        this.languageCtrl.setValue(user.language);
        this.countryCodeCtrl.setValue(user.countryCode);

        // En caso de que sea la institución por defecto, elimina el nombre para indicar que no tiene institución asignada
        if (user.institution?.id === this.defaultInstitution.id) {
          this.institutionNameCtrl.setValue('');
        } else {
          this.institutionNameCtrl.setValue(user.institution?.name);
        }
        // Nombre de equipo. Asigna el del primer equipo, sino una creada en base al nombre de la institución
        if (!this.teamNameCtrl.value) {
          this.teamNameCtrl.setValue(`Team ${user.institution?.name}`);
        }
        this.fullNameCtrl.setValue(user.fullName);
        this.phoneCtrl.setValue(user.phone);

        // Specifies slides NOT add per default
        this.addInstitutionSlide = this.addTeamSlide = this.addUserSlide = this.addProfessionalTypeSlide = false;

        // Phone/ region slide always visible
        this.addPhoneSlide = true;

        // Check if dialog is NOT for select only region, to show the rest of slides
        if (!this.requestOnlyRegion) {

          // Opciones sólo para usuarios que se están registrando desde auth/register
          // O que se les haya asignado la insitución por defecto
          if (user.isFromRegister || user.institution?.id === this.defaultInstitution.id) {
            this.addInstitutionSlide = this.addTeamSlide = this.addUserSlide = true;
          };

          this.addProfessionalTypeSlide = true;
        }

        const slides: Array<UserComplementDataSlide> = [];

        // Institution slide
        if (this.addInstitutionSlide) {
          slides.push({
            name: 'institution',
            checkHidden: () => { return !this.institutionNameCtrl.value },
            updateMethod: () => { this.updateInstitution(); },
            focusMethod: () => { this.institutionNameInput.focus(); }
          });
        }

        // Team slide
        if (this.addTeamSlide) {
          slides.push({
            name: 'team',
            checkHidden: () => { return false; },
            updateMethod: () => { this.updateTeam(); },
            focusMethod: () => { this.teamNameInput.focus(); }
          });
        }

        // User slide
        if (this.addUserSlide) {
          slides.push({
            name: 'user',
            checkHidden: () => { return false; },
            updateMethod: () => { this.updateUserData() },
            focusMethod: () => { this.fullNameInput.focus(); }
          });
        }

        // Phone/ region slide
        if (this.addPhoneSlide) {
          slides.push({
            name: 'phone',
            checkHidden: () => { return false; },
            updateMethod: () => { this.updatePhone(); },
            focusMethod: () => { this.phoneInput.focus(); }
          });
        }

        // Professional type slide
        if (this.addProfessionalTypeSlide) {
          slides.push({
            name: 'professional',
            checkHidden: () => { return false; },
            updateMethod: () => { },
            focusMethod: () => { this.professionalTypeSelectRef.focus(); }
          });
        }

        this.SLIDES = slides;

        this.swiperConfig = {
          allowTouchMove: false,
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          navigation: {
            prevEl: '.web-swiper-button--prev',
            nextEl: '.web-swiper-button--next'
          },
          on: {
            afterInit: (swiper: Swiper) => {
              this.isBeginning = swiper.isBeginning;
              this.isEnd = swiper.isEnd;
              this.SLIDES[this.currentActiveIndex].focusMethod();
            },
            activeIndexChange: () => {
              // Ejecuta el método update del slide previamente seleccionado.
              // currentActiveIndex se asigna posteriormente al finalizar la animación
              setTimeout(() => {
                this.SLIDES[this.currentActiveIndex].updateMethod();
              });
            },
            slideChangeTransitionEnd: (swiper: Swiper) => {
              this.isBeginning = swiper.isBeginning;
              this.isEnd = swiper.isEnd;
              this.currentActiveIndex = swiper.activeIndex;
              setTimeout(() => {
                this.SLIDES[this.currentActiveIndex].focusMethod();
              });
            },
            slideChange: (swiper: Swiper) => {
              this.expand = this.SLIDES[swiper.activeIndex]?.name === 'phone';
            }
          }
        }

        // Obtiene catálogos de: Tipos de profesional y regiones
        this.spinnerService.start();
        forkJoin([
          this.addProfessionalTypeSlide
            ? this.catalogService.getProfessionalTypes(user.language ? UserIsoLanguage[user.language] : null)
            : of<Array<ProfessionalType>>([]),
          this.regionService.getRegions(),
          // Primer equipo asignado a la institución
          this.teamService.getFirstTeam()
        ]).pipe(
          finalize(() => this.spinnerService.stop())
        ).subscribe(data => {
          // Tipos de profesional
          this.professionalTypes = data[0];
          // Regiones
          this.regions = data[1].sort((a, b) => a.id - b.id);
          this.regionCtrl.setValue(this.initialRegion);
          // Primer equipo
          this.firstTeam = data[2];
          if (this.firstTeam)
            this.teamNameCtrl.setValue(this.firstTeam.name);
        });
      });

    // Determinar cambios pero sin que esté dentro de la suscripción a la obtención de datos
    this.languageCtrl.valueChanges.subscribe(language => {

      if (this.user && this.user.language !== language) {
        const userToUpdate = new User();
        userToUpdate.language = language;

        this.spinnerService.start();
        this.userService
          .update(userToUpdate)
          .pipe(
            finalize(() => this.spinnerService.stop())
          ).subscribe();
      }
    });
  }

  ngAfterViewInit(): void {
    if(!this.phoneInput) return;
    this.phoneInput.tabIndex = -1;
    this.phoneCountrySelector.tabIndex = -1;
    this.phoneInput.addEventListener('keypress', (e) => {
      if (e.key.toLowerCase() === 'enter') {
        this.onInputKeyupEnter(e, this.CONTROL_NAMES.phone);
      }
    });
    this.phoneInput.addEventListener('keydown', (e) => {
      if (e.key.toLowerCase() === 'tab') {
        this.onInputKeyupEnter(e, this.CONTROL_NAMES.phone);
      }
    });
  }

  ngOnDestroy(): void {
    this.overlayContainer.getContainerElement().classList.remove('ngx-mat-intl-tel-input');
    this.subSink.unsubscribe();
  }

  onInputKeyupEnter(event: any, controlName: string): void {

    if (event) {
      event.preventDefault();
    }

    // En caso de que se trate del campo teléfono, se pasa al campo de región
    if (controlName === this.CONTROL_NAMES.phone) {
      this.regionSelectRef.focus();
      return;
    }

    // Verifica si es el último slide
    if (this.swiperComponent.swiper.isEnd) {
      this.btnFinish.focus();
    }
    // Sino pasa al siguiente
    else {
      this.swiperComponent.swiper.slideNext();
    }
  }

  phoneCountryChanged(ngxCountry: ngxCountry): void {

    if (!this.regions.length) return;

    // Obtiene y selecciona región en base al país y código de país selecionado
    const country = Country.getByCode(ngxCountry.iso2);
    if (!country) return;

    // Asigna código de país en base a la región del teléfono seleccionado
    this.countryCodeCtrl.setValue(country.countryCode);

    // Región de stripe
    const regionFound = this.regions.find(x => x.id === country.spfRegionId);
    if (regionFound) {
      this.regionCtrl.setValue(regionFound.id);
    }
    // Sino se le encontró región le coloca el predeterminado
    else {
      this.regionCtrl.setValue(this.defaultRegion);
    }
  }

  handleFinishClick(): void {

    if (this.requestOnlyRegion) {
      const slide = this.SLIDES.find(x => x.name === 'phone');
      if (slide) {
        slide.updateMethod();
      }
      return;
    }

    const user = new User();
    user.professionalTypeId = this.professionalTypeCtrl.value;
    user.countryCode = this.countryCodeCtrl.value;

    // Marca el perfil como completo
    user.isProfileComplete = true;

    this.spinnerService.start();
    this.userService
      .update(user)
      .pipe(
        finalize(() => this.spinnerService.stop())
      ).subscribe(() => {
        this.teamService.emitInitialDataUpdated(this.team);
        this.dialogRef.close();
      });
  }

  private createForms(): void {

    this.form = this.fb.group({});

    const institutionNameCtrl = this.fb.control(null, [Validators.required]);
    this.form.addControl(this.CONTROL_NAMES.institutionName, institutionNameCtrl);

    const teamNameCtrl = this.fb.control(null, [Validators.required]);
    this.form.addControl(this.CONTROL_NAMES.teamName, teamNameCtrl);

    const fullNameCtrl = this.fb.control(null, [Validators.required]);
    this.form.addControl(this.CONTROL_NAMES.fullname, fullNameCtrl);

    const phoneCtrl = this.fb.control(null, [Validators.required]);
    this.form.addControl(this.CONTROL_NAMES.phone, phoneCtrl);

    // Se le asigna por defecto la región inicial
    const regionCtrl = this.fb.control(this.initialRegion, [Validators.required]);
    this.form.addControl(this.CONTROL_NAMES.region, regionCtrl);

    const professionalTypeCtrl = this.fb.control(null, this.requestOnlyRegion ? [] : [Validators.required]);
    this.form.addControl(this.CONTROL_NAMES.professionalType, professionalTypeCtrl);

    const countryCodeCtrl = this.fb.control(LanguageCountryCode.unitedStates, [Validators.required]);
    this.form.addControl(this.CONTROL_NAMES.countryCode, countryCodeCtrl);

    this.formLanguage = this.fb.group({});
    const languageCtrl = this.fb.control(UserLanguage.English, [Validators.required]);
    this.formLanguage.addControl(this.CONTROL_NAMES.language, languageCtrl);
  }

  private updateInstitution(): void {

    if (!this.institutionNameCtrl.value) return;

    const institution = new Institution();
    institution.name = this.institutionNameCtrl.value;
    institution.identifierName = UtilitiesService.getDbName(institution.name);

    // Verifica si el id de institución asignado es la institución por defecto (Institución para usuarios sin asignación de institución)
    if (this.user.institution.id === this.defaultInstitution.id) {
      institution.subscription = Subscription.trialSubscription();
      institution.isActive = true;
      this.spinnerService.start();
      this.institutionService
        .create(
          institution
        ).pipe(
          mergeMap(createdInstitution => {
            // Se asigna al usuario la institución recién creada y se le asigna el perfil de institution manager
            this.user.institution = createdInstitution;
            return this.userService.setAsInstitutionManager(this.user.id, createdInstitution.id);
          }),
          finalize(() => this.spinnerService.stop())
        ).subscribe(modifiedUser => {
          // Almacena el cambio de institución
          this.authService.institutionId = modifiedUser.institution.id;
        });
    }
    else {
      institution.id = this.user.institution.id;
      institution.identifierName = `${institution.id}-${institution.identifierName}`;
      this.spinnerService.start();
      this.institutionService
        .update(
          institution
        ).pipe(
          finalize(() => this.spinnerService.stop())
        ).subscribe();
    };
  }

  private updateUserData(): void {

    if (!this.fullNameCtrl.value) return;

    const user = new User();
    user.fullName = this.fullNameCtrl.value;
    this.spinnerService.start();
    this.userService
      .update(user)
      .pipe(
        finalize(() => this.spinnerService.stop())
      ).subscribe();
  }

  private updateTeam(): void {

    if (!this.teamNameCtrl.value) return;

    const team = this.team || new Team();
    team.name = this.teamNameCtrl.value;

    this.spinnerService.start();
    if (team.id) {

      this.teamService.update(
        team
      ).pipe(
        finalize(() => this.spinnerService.stop()),
      ).subscribe(teamUpdated => this.team = teamUpdated);

    } else {

      // Si se tiene dato de primer equipo se actualiza éste
      if (this.firstTeam) {
        this.firstTeam.name = team.name;
      }

      const saveTeamRequest = this.firstTeam
        ? this.teamService.update(this.firstTeam)
        : this.teamService.create(team, this.authService.institutionId);

      saveTeamRequest.pipe(
        finalize(() => this.spinnerService.stop())
      ).subscribe(teamUpdated => this.team = teamUpdated);
    }
  }

  private updatePhone(): void {

    if (this.phoneCtrl.invalid || this.regionCtrl.invalid) return;

    const user = new User();
    user.phone = this.phoneCtrl.value;
    user.region = new Region();
    user.region.id = this.regionCtrl.value;

    this.spinnerService.start();
    this.userService.update(
      user
    ).pipe(
      finalize(() => this.spinnerService.stop())
    ).subscribe(() => {
      if (this.requestOnlyRegion) {
        // Reload page to update all request
        window.location.reload();
      }
    });
  }
}
