import { Component, OnInit, OnDestroy, HostListener, Inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ITranslationService, I18NEXT_SERVICE } from 'angular-i18next';
import { filter } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { UserService } from 'sp-core';

import { environment } from '@web/env/environment';

import { AppService } from './core/services';

declare var gtag: any;

@Component({
  selector: 'web-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private subSink = new SubSink();

  @HostListener('click', ['$event'])
  public click(event: any): void {
    this.appService.documentClicked(event);
  }

  constructor(
    private router: Router,
    private appService: AppService,
    private userService: UserService,
    @Inject(I18NEXT_SERVICE) private iTranslationService: ITranslationService
  ) {
    // Agrega scripts de google analytics
    const gaTrackingId = environment.googleAnalytics.trackindId;
    let gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + gaTrackingId;
    document.head.prepend(gtagScript);

    /** Disable automatic page view hit to fix duplicate page view count  **/
    gtag('config', gaTrackingId, { send_page_view: false });

    // TODO: Check duplicate events. Implement APP_INITIALIZER: https://medium.com/beingcoders/how-to-use-google-analytics-in-angular-9a6131979819

    gtagScript.addEventListener('load', () => {
      this.subSink.sink = this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects
        });
      });
    });
  }

  ngOnInit(): void {

    // Compare language between user stored and localStorage
    this.subSink.sink = this.userService.user$.pipe(
      filter(x => !!x)
    ).subscribe(user => {
      const appLanguage = this.iTranslationService.language;
      const userLanguage = user.languageCode;
      // If both not match, lets change with user language information storage
      if ((appLanguage && userLanguage) && (appLanguage !== userLanguage)) {
        this.iTranslationService.changeLanguage(
          userLanguage
        ).then(() => {
          window.location.reload();
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }
}