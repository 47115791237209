import { Component, OnInit, OnDestroy } from '@angular/core';
import { SubSink } from 'subsink';

import { ProgressSpinnerService } from 'sp-core';

@Component({
  selector: 'web-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit, OnDestroy {

  spinnerIsRunning = false;

  private subsink = new SubSink();

  constructor(
    private spinnerService: ProgressSpinnerService
  ) { }

  ngOnInit(): void {
    this.subsink.sink = this.spinnerService.stateChange.subscribe(state => this.spinnerIsRunning = state);
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }
}