// Enum for identity the input type of question
export enum InputType {
  string = 'string',
  integer = 'integer',
  decimal = 'decimal',
  date = 'date',
  time = 'time',
}

export enum QuestionLanguage {
  english = 'english',
  spanish = 'spanish',
}

export enum QuestionType {
  range = 'range',
  input = 'input',
  slider = 'slider',
  choices = 'choices',
  // This value is same like 'choices', the only reason for his name are in singular is for a frontend purposes
  choice = 'choice',
}
