import { ExerciseSubcategoryResponse, Serializer } from './interfaces';

import { Audit } from './audit';
import { Exercise } from './exercise';

export class ExerciseSubcategory implements Serializer<ExerciseSubcategory> {
    constructor(
        public id?: number,
        public name?: string,
        public code?: string,
        public level?: number,
        public isActive?: boolean,
        public audit?: Audit,
        public categoryId?: number,
        /**
         * TODO: De momento no se utiliza, verificar a qué información se refiere
         */
        public levelCategories: Array<any> = [],
        /**
         * Ejercicios que corresponden a la categoría.
         * NOTA: No se obtiene del endpoint. Es un campo auxiliar para frontend
         */
        public exercises: Array<Exercise> = [],
        /**
         * Indica si la subcategoría está o no seleccionado.
         * NOTA: No se obtiene del endpoint. Es un campo auxiliar para frontend
         */
        public selected = false,
        /**
         * Indica si la subcategoría está o no oculto.
         * NOTA: No se obtiene del endpoint. Es un campo auxiliar para frontend
         */
        public hidden = false
    ) { }
    fromResponse(response: ExerciseSubcategoryResponse): ExerciseSubcategory {
        const _language = localStorage.getItem('USER_LANGUAGE');
        const _name = _language == 'es' ? response.spanish_name || response.name : response.name || response.spanish_name;
        return new ExerciseSubcategory(
            response.id,
            _name,
            response.code,
            response.level,
            response.active,
            new Audit().fromResponse(response),
            response.category
        )
    }
    toRequest() {
        throw new Error('Method not implemented.');
    }
    select(): void {
        this.selected = true;
    }

    unselect(): void {
        this.selected = false;
    }
    hide(): void {
        this.hidden = true;
    }
    show(): void {
        this.hidden = false;
    }
}