import { Component, Input, OnInit } from '@angular/core';

import { Subscription } from 'sp-core';

@Component({
  selector: 'web-header-subscription',
  templateUrl: './header-subscription.component.html',
  styleUrls: ['./header-subscription.component.scss']
})
export class HeaderSubscriptionComponent implements OnInit {

  @Input() subscription: Subscription;

  constructor() { }

  ngOnInit(): void {
  }

}
