import { Pipe, PipeTransform } from '@angular/core';
import { I18NextPipe } from 'angular-i18next';

import { Day } from 'sp-core';

const Days = {
  'M': {
    abbreviation: '',
    name: ''
  },
  'TU': {
    abbreviation: '',
    name: ''
  },
  'W': {
    abbreviation: '',
    name: ''
  },
  'TH': {
    abbreviation: '',
    name: ''
  },
  'F': {
    abbreviation: '',
    name: ''
  },
  'SA': {
    abbreviation: '',
    name: ''
  },
  'SU': {
    abbreviation: '',
    name: ''
  },
}

@Pipe({
  name: 'day'
})
export class DayPipe implements PipeTransform {

  constructor(private i18NextPipe: I18NextPipe) {
    Days.M.abbreviation = this.translate('common.weekDays.M.abbreviation');
    Days.TU.abbreviation = this.translate('common.weekDays.TU.abbreviation');
    Days.W.abbreviation = this.translate('common.weekDays.W.abbreviation');
    Days.TH.abbreviation = this.translate('common.weekDays.TH.abbreviation');
    Days.F.abbreviation = this.translate('common.weekDays.F.abbreviation');
    Days.SA.abbreviation = this.translate('common.weekDays.SA.abbreviation');
    Days.SU.abbreviation = this.translate('common.weekDays.SU.abbreviation');
    
    Days.M.name = this.translate('common.weekDays.M.name');
    Days.TU.name = this.translate('common.weekDays.TU.name');
    Days.W.name = this.translate('common.weekDays.W.name');
    Days.TH.name = this.translate('common.weekDays.TH.name');
    Days.F.name = this.translate('common.weekDays.F.name');
    Days.SA.name = this.translate('common.weekDays.SA.name');
    Days.SU.name = this.translate('common.weekDays.SU.name');
  }

  transform(day: Day, abbreviation = false): string {
    switch (day) {
      case Day.monday:
        return abbreviation ? Days.M.abbreviation : Days.M.name;
      case Day.tuesday:
        return abbreviation ? Days.TU.abbreviation : Days.TU.name;
      case Day.wednesday:
        return abbreviation ? Days.W.abbreviation : Days.W.name;
      case Day.thursday:
        return abbreviation ? Days.TH.abbreviation : Days.TH.name;
      case Day.friday:
        return abbreviation ? Days.F.abbreviation : Days.F.name;
      case Day.saturday:
        return abbreviation ? Days.SA.abbreviation : Days.SA.name;
      case Day.sunday:
        return abbreviation ? Days.SU.abbreviation : Days.SU.name;
      default:
        return '';
    }
  }
  
  private translate(text: string, format?: any): string {
    return this.i18NextPipe.transform(text, format);
  }
}
