import moment from "moment";

import { Athlete } from "./athlete";
import { Serializer, WorkoutLogAthleteInfoRequest, WorkoutLogResponse } from "./interfaces";

import { WorkoutStatus } from "./enumerations";
import { WorkoutJsonData } from "./workout-json-data";

export class WorkoutLog implements Serializer<WorkoutLog> {

    /**
     * Get workout log status based data contains in jsonData
     */
    get status(): WorkoutStatus {
        return this.jsonData?.status;
    }

    constructor(
        public id?: number,
        public name?: string,
        public date?: Date,
        /**
         * TODO: Confirmar si se requiere éste campo. El dato se tiene también en jsonData
         */
        public rpe?: number,
        public athlete?: Athlete,
        public jsonData = new WorkoutJsonData(),
        /**
         * Workout original o padre del que derivo éste registro de log
         */
        public workoutId?: number,
        public programId?: number,
        public phaseId?: number,
        public workoutDayId?: number,
    ) { }

    static fromResponse(response: WorkoutLogResponse): WorkoutLog {

        const log = new WorkoutLog(
            response.id,
            response.name,
            response.date
                ? moment(response.date).toDate()
                : null,
            response.jsonData.rpe,
            new Athlete(),
            response.jsonData
                ? new WorkoutJsonData().fromResponse(response.jsonData)
                : null,
            response.workout
        )

        if (response.athlete_id || response.athlete) {
            log.athlete.id = response.athlete_id || response.athlete;
        }

        return log;
    }

    fromResponse(response: WorkoutLogResponse): WorkoutLog {
        return WorkoutLog.fromResponse(response);
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }

    toAthleteInformationRequest(): WorkoutLogAthleteInfoRequest {

        if (!this.athlete?.id) {
            throw new Error("Athlete data cannot be null");
        }

        return <WorkoutLogAthleteInfoRequest>{
            athlete_id: this.athlete.id,
            rpe: (this.rpe || 0),
            jsonData: this.jsonData.toRequest()
        }
    }
}