<button *ngIf="subscription?.type === SubscriptionType.trial || subscription?.type === SubscriptionType.free"
    class="web-upgrade-button expand-button"
    mat-flat-button
    [routerLink]="['/pricing']">
    {{text}}
</button>
<button *ngIf="subscription?.type === SubscriptionType.trial || subscription?.type === SubscriptionType.free"
    class="web-upgrade-button short-button"
    mat-menu-item
    [routerLink]="['/pricing']">
    {{text}}
</button>