import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import es from '@angular/common/locales/es';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { AngularFireModule } from '@angular/fire/compat';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { NgxStripeModule } from 'ngx-stripe';
import { I18NextModule } from 'angular-i18next';
import { NgxSkeletonLoaderConfig, NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { JoyrideModule } from 'ngx-joyride';

import { SpCoreConfig, SpCoreModule } from 'sp-core';
import { SpLibraryConfig, SpLibraryModule, SpProgressSpinnerModule, SpScrollModule } from 'sp-library';

import { environment } from '@web/env/environment';
import { CoreModule } from '@web/core/core.module';
import { SharedModule } from '@web/shared/shared.module';

import { I18N_PROVIDERS } from './i18next.providers';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';

registerLocaleData(es, 'es');

const skeletonConfig = <NgxSkeletonLoaderConfig>{
  animation: 'progress-dark'
};

const spCoreConfig = <SpCoreConfig>{
  apiBaseUrl: environment.apiBaseUrl,
  baseUrl: environment.baseUrl,
  tokenUrl: environment.tokenUrl,
  refreshTokenUrl: environment.refreshTokenUrl,
  userInfoUrl: environment.userInfoUrl,
  forgotPasswordUrl: environment.forgotPasswordUrl,
  loginUrl: environment.loginUrl,
  youtubeApiKey: environment.youtubeApiV3.apiKey
};

const spLibraryConfig = <SpLibraryConfig>{
  ws: environment.ws
};

@NgModule({
  declarations: [
    AppComponent,
    AuthLayoutComponent,
    MainLayoutComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatProgressBarModule,
    AngularFireModule.initializeApp(environment.firebase),
    NgxStripeModule.forRoot(environment.stripe.publishableKey),
    NgxSkeletonLoaderModule.forRoot(skeletonConfig),
    JoyrideModule.forRoot(),
    SpCoreModule.forRoot(spCoreConfig),
    SpLibraryModule.forRoot(spLibraryConfig),
    SpProgressSpinnerModule,
    CoreModule,
    SharedModule,
    SpScrollModule,
    PickerModule,
    I18NextModule.forRoot()
  ],
  providers: [
    ...I18N_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
