import { Component, Input, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component({
  selector: 'sp-photo-editor',
  templateUrl: './photo-editor.component.html',
  styleUrls: ['./photo-editor.component.scss']
})
export class PhotoEditorComponent implements OnInit {

  @Input() title = "";

  @Input() photo: string;

  private photoBase64: string;
  private photoFile: File;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { title: string, photo: string },
    private dialogRef: MatDialogRef<PhotoEditorComponent>
  ) {
    this.title = this.data.title;
    this.photo = this.data.photo;
  }

  ngOnInit(): void {
  }

  handleUploadImageCropped(img: string): void {
    this.photoBase64 = img;
  }

  handleUploadImageFile(file: File): void {
    this.photoFile = file;
  }

  handleSaveClick(): void {
    this.dialogRef.close({
      base64: this.photoBase64,
      file: this.photoFile
    });
  }

  handleUploadImageOnDelete(): void {
    this.photoBase64 = null;
    this.photoFile = null;
  }
}