import { Pipe, PipeTransform } from '@angular/core';

import { UserType } from 'sp-core';

@Pipe({
  name: 'userType'
})
export class UserTypePipe implements PipeTransform {

  transform(userType: UserType): string {
    switch (userType) {
      case UserType.superAdmin:
        return 'common.roles.superAdmin';
      case UserType.institutionManager:
        return 'common.roles.institutionManager';
      case UserType.strengthCoach:
        return 'common.roles.strengthCoach';
      case UserType.athlete:
        return 'common.roles.Athlete';
      default:
        return userType;
    }
  }

}
