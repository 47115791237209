import { Data, ExerciseParameter, FolderBlockData, Institution, Subscription, User, UserProfile, WorkoutTag, WorkoutTypesData } from "sp-core";

export class AppInitialData {
    constructor(
        public defaultInstitution?: Institution,
        /**
         * Tipos de workout
         * ```
         * IMPORTANTE: Los íconos se deben almacenar en el registro de Angular Material (MatIconRegistry) ya que se accederán por medio de mat-icon -> svgIcon
         * ```
         */
        public workoutTypesData?: WorkoutTypesData,
        public authenticatedUser?: User,
        public authenticatedUserProfile?: UserProfile,
        public currentSubscription?: Subscription,
        public parameters: Array<ExerciseParameter> = [],
        public institutionalTagsFirstPage?: Data<WorkoutTag>,
        public institutionalFoldersFirstPage?: Data<FolderBlockData>,
    ) { }
}