import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {webSocket, WebSocketSubject} from 'rxjs/webSocket';

import {Notification} from '@web/shared/components/chat/chat.interface';
import {environment} from '@web/env/environment';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  notificationSubject: WebSocketSubject<Notification>;
  private notification = new BehaviorSubject<Notification | null>(null);
  $notification = this.notification.asObservable();

  constructor() { }

  connectWebSocket(): void {
    this.notificationSubject = webSocket(`${environment.ws}notifications/CB155214990FC60D2D1FCAED0DACF0092/`);
    this.notificationSubject.subscribe((notification) => {
      this.notification.next(notification);
    });
  }

  destroyWebSocket(): void {
    this.notificationSubject.complete();
  }
}
