/**
 * Nombre de control para formulario de detalle de ejercicio
 */
export const EXERCISE_DETAIL_CONTROL_NAMES = {
    namesGroup: 'namesGroup',
    namesGroupEnglish: 'english',
    namesGroupSpanish: 'spanish',

    path: 'path',
    pathFile: 'pathFile',
    pathYoutube: 'pathYoutube',
    subcategories: 'subcategories',
    folders: 'folder'
}