import { ExerciseParameterResponse, Serializer } from './interfaces';

import { ExerciseSubparameter } from "./exercise-subparameter";

export class ExerciseParameter implements Serializer<ExerciseParameter>{

    /**
     * Obtiene el parámetro de tipo Reps.
     * Los parámetros no tienes alguna característica que lo defina como Reps. Se considera el registro con id=1.
     * En dado caso se podría agregar por backend algún campo y aquí iniciar la instancia por dicho campo
     */
    static get parameterReps(): ExerciseParameter {
        return new ExerciseParameter(1);
    }

    constructor(
        public id?: number,
        public title?: string,
        public order?: number,
        public iconOn?: string,
        public iconOff?: string,
        /**
         * Obtiene o establece si el parámetro se refiere a un parámetro de descanso
         */
        public isRest?: boolean,
        /**
         * Obtiene o establece si el elemento está seleccionado.
         * NOTA: No se obtiene de backend. Es un campo auxiliar para frontend.
         */
        public isSelected = false,
        /**
         * Subparámetros asignados.
         * NOTA: No se obtiene por el mismo endpoint que mapea el objeto actual, se procesa posteriormente
         */
        public subparameters: Array<ExerciseSubparameter> = []
    ) { }

    fromResponse(response: ExerciseParameterResponse): ExerciseParameter {
        const _language = localStorage.getItem('USER_LANGUAGE');
        const _title = _language == 'es' ? response.title_es || response.title : response.title || response.title_es;
        return new ExerciseParameter(
            response.id,
            _title,
            response.order,
            response.icon_on,
            response.icon_off,
            response.has_rest
        );
    }

    toRequest() {
        throw new Error("Method not implemented.");
    }

    clone(): ExerciseParameter {
        return new ExerciseParameter(
            this.id,
            this.title,
            this.order,
            this.iconOn,
            this.iconOff,
            this.isRest,
            this.isSelected,
            this.subparameters.map(subparameter => subparameter.clone())
        );
    }

    /**
     * Asigna los subparámetros correspondientes al parámetro
     * @param subparameters Colección de subparámetros a asignar
     */
    assignSubparameters(subparameters: Array<ExerciseSubparameter>): void {
        this.subparameters = subparameters.slice();
    }

    select(): void {
        this.isSelected = true;
    }

    unselect(): void {
        this.isSelected = false;
    }
}