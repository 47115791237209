import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import moment from 'moment';

import { environment } from '@web/env/environment';

import {
  ApiService, Athlete, AthleteResponse, PaginatedParams, Phase,
  PhaseResponse,
  PhaseWeeks, PhaseWeeksResponse, Program, ProgramAssignment, ProgramPhaseDetail, ProgramPhaseDetailResponse,
  ProgramRequestParams, ProgramResponse, ProgramsData, ProgramsDataResponse
} from 'sp-core';

@Injectable()
export class ProgramService {

  private programSubject$ = new BehaviorSubject<Program>(null);
  /**
   * Escuchador de programa
   * ```
   * Obtiene el programa actual. El último programa obtenido, agregado, modificado o eliminado
   * ```
   */
  program$ = this.programSubject$.asObservable();

  constructor(
    private api: ApiService
  ) { }

  /**
   * Obtiene detalle del programa indicado
   * @param programId Identificador de programa
   * @returns Programa
   */
  getById(programId: number): Observable<Program> {
    return this.api
      .get<ProgramResponse>(`programs/${programId}/`)
      .pipe(
        map(response => {
          const program = new Program().fromResponse(response);
          this.programSubject$.next(program);
          return program;
        })
      );
  }

  getPaginatedLibraries(
    httpParams?: HttpParams
  ): Observable<any> {

    let params = httpParams || new HttpParams();

    return this.api
      .get<ProgramsDataResponse>(`program-library/`, params)
      .pipe(
        map(response => new ProgramsData().fromResponse(response))
      );
  }

  getWeeksByPhase(phaseId: number): Observable<PhaseWeeks> {

    let params = new HttpParams()
      .set('active', '1')
      .set('phase', phaseId.toString())
      .set('ordering', 'starts');

    return this.api
      .get<PhaseWeeksResponse>('weeks/', params)
      .pipe(
        map(response => {
          return new PhaseWeeks().fromResponse(response);
        })
      );
  }

  /**
   * Obtiene los atletas asignados al programa
   * ```
   * EP: user-program/{programId}/
   * ```
   * @param programId Identificador de programa
   */
  getAssignedAthletes(programId: number): Observable<Array<Athlete>> {
    return this.api
      .get<Array<AthleteResponse>>(`user-program/${programId}/`)
      .pipe(
        map(response => response.map(x => new Athlete().fromResponse(x)))
      )
  }

  getProgramPhaseDetail(
    programId?: number,
    phaseId?: number
  ): Observable<ProgramPhaseDetail> {

    let body = {};

    if (programId) {
      body = {
        program: programId
      }
    } else if (phaseId) {
      body = {
        phase: phaseId
      }
    }

    return this.api
      .post<ProgramPhaseDetailResponse>('program-phase-detail/', body)
      .pipe(
        map(response => new ProgramPhaseDetail().fromResponse(response))
      );
  }

  getPaginated(
    params: ProgramRequestParams,
    paginatedParams?: PaginatedParams
  ): Observable<any> {

    let requestParams = params.toRequest();

    // Agrega los parámetros de paginación
    if (paginatedParams) {
      requestParams = paginatedParams.toRequest(requestParams);
    }

    return this.api
      .get(`programs/`, requestParams)
      .pipe(
        map(response => {
          return response;
        })
      );
  }

  create(
    program: Program
  ): Observable<Program> {
    return this.api
      .post<ProgramResponse>('program-list/', program.toRequest())
      .pipe(
        map(response => {
          const programCreated = program.fromResponse(response);
          this.programSubject$.next(programCreated);
          return programCreated;
        })
      );
  }

  /**
   * Actualiza o modifica en BD el programa indicado
   * // TODO: No está finalizado, falta el mapeo de los modelos anidados de fases, etc...
   * // Se intentó utilizar en la actualización de program-builder pero varían demasiado los modelos que ahora se utilizan en ésta pantalla
   * @param program Objeto programa con los datos a actualizar en BD
   * @returns Programa con los datos actualizados
   */
  update(
    program: Program
  ): Observable<Program> {
    return this.api
      .patch<ProgramResponse>(`programs/${program.id}/`, program.toRequest())
      .pipe(
        map(response => {
          const programUpdated = program.fromResponse(response)
          this.programSubject$.next(programUpdated);
          return programUpdated;
        })
      )
  }

  delete(
    program: Program
  ): Observable<Program> {
    return this.api
      .delete(`programs/${program.id}/`)
      .pipe(
        map(() => {
          this.programSubject$.next(program);
          return program;
        })
      );
  }

  deleteLibrary(
    program: Program
  ): Observable<Program> {
    return this.api
      .delete(`program-library/${program.id}/`)
      .pipe(
        map(() => program)
      );
  }

  saveAsTemplate(
    program: Program
  ): Observable<any> {
    return this.api
      .post(`program-template/${program.id}/`, { name: program.defaultName });
  }

  /**
   * Ordena las fases del programa indicado
   * @param program Programa al que corresponden las fases
   * @returns 
   */
  sortPhases(
    program: Program,
    phases: Array<Phase>
  ): Observable<Program> {
    return this.api
      .post<ProgramResponse>(`phases-sorting/${program.id}/`, phases.map(x => x.toProgramSortPhasesRequest()))
      .pipe(
        map(response => {
          const programSorted = program.fromResponse(response);
          this.programSubject$.next(programSorted);
          return programSorted;
        })
      )
  }

  /**
   * Clona semanas de un programa
   * @param weekId Identificador de la semana a clonar
   * @returns 
   */
  cloneWeeks(
    weekId: number
  ): Observable<Program> {
    return this.api
      .post<ProgramResponse>(`copy-weeks/${weekId}/`, {})
      .pipe(
        map(response => {
          const program = new Program().fromResponse(response);
          this.programSubject$.next(program);
          return program;
        })
      )
  }

  /**
   * Elimina una semana del programa y mueve las semanas siguientes
   * @param weekId Identificador de la semana a eliminar
   * @param deletePhaseRequest Solicitud para eliminar la fase al que corresponde la semana
   * @returns 
   */
  deleteAndMoveWeeks(
    weekId: number,
    deletePhaseRequest = of(null)
  ): Observable<Program> {

    return this.api
      .delete<ProgramResponse>(`delete-move-weeks/${weekId}/`)
      .pipe(
        // Solicitud para eliminar fase
        mergeMap(response => {

          let removePhases = false;
          // Si se envió solicitud para borrar fases se indica que se debe eliminar del programa a retornar
          if (deletePhaseRequest) {
            removePhases = true;
          }
          else {
            deletePhaseRequest = of(null);
          }

          return deletePhaseRequest
            .pipe(
              // Retorna la misma respuesta del EP delete-move-weeks
              map(() => {
                if (removePhases) {
                  response.phases = [];
                }
                return response;
              })
            )
        }),
        map(response => {
          const program = new Program().fromResponse(response);
          this.programSubject$.next(program);
          return program;
        })
      );
  }

  importPhaseLibrary(
    programId: number,
    phase: Phase
  ): Observable<Phase> {
    return this.api
      .post(`phases-library-import/${programId}/`, phase)
  }

  cloneLibrary(programId: number): Observable<Program> {
    return this.api
      .post<ProgramResponse>(
        `clone-library/${programId}/`,
        { model: 'program', from_dashboard: '1' }
      ).pipe(
        map(response => new Program().fromResponse(response))
      );
  }

  /**
   * Clonar una fase de un programa tipo calendarizado
   * @param phase Fase a clonar
   * @returns 
   */
  cloneScheduledPhase(
    phase: Phase
  ): Observable<Program> {
    return this.api
      .post<ProgramResponse>(`clone-move-phases/${phase.id}/`, {})
      .pipe(
        map(response => {
          const program = Program.fromResponse(response);
          this.programSubject$.next(program);
          return program;
        })
      )
  }

  importTemplatePhase(
    program: Program,
    phase: Phase,
    date: Date
  ): Observable<Program> {

    const data = this.getTemplatePhaseData(program, phase, date);

    return this.api
      .post<ProgramResponse>(`phases-library-import/${program.id}/`, data)
      .pipe(
        map(response => {
          const programUpdated = Program.fromResponse(response);
          this.programSubject$.next(programUpdated);
          return programUpdated;
        })
      );
  }

  importPlaylistTemplatePhase(
    program: Program,
    phase: Phase,
    date: Date
  ): Observable<Phase> {

    const data = this.getTemplatePhaseData(program, phase, date);

    return this.api
      .post<PhaseResponse>(`phases-library-import/${program.id}/`, data)
      .pipe(
        map(response => {
          const phaseImported = Phase.fromResponse(response);
          program.phases.push(phaseImported);
          this.programSubject$.next(program);
          return phaseImported;
        })
      );
  }

  private getTemplatePhaseData(
    program: Program,
    phase: Phase,
    date: Date
  ): any {

    const data = {
      phase: phase.id,
      starts: moment(date).format(environment.apiDateFormat),
      order: null
    }

    let _phase = program.phases.find(x => new Date(x.starts) > date);
    // if: si existe phase delante de la pocicion en la que se crearia
    if (_phase) {
      data.order = _phase['order'];
    } else {
      data.order = program.phases.length > 0 ? program.phases[program.phases.length - 1]['order'] + 1 : 1;
    }

    return data;
  }

  assignProgram(
    program: Program,
    programAssignment: ProgramAssignment
  ): Observable<Program> {
    return this.api
      .patch<ProgramResponse>(
        `program-list/${program.id}/assign/`,
        programAssignment.toRequest()
      ).pipe(
        map(response => program.fromResponse(response))
      );
  }
}