import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, Subject, of } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import moment from 'moment';

import { TEAM_PARAM_NAMES } from '../constants';
import { Athlete, Institution, PaginatedParams, Team, Teams } from '../models';
import { TeamUserType } from '../models/enumerations';
import { AthleteResponse, RequestParam, TeamResponse, TeamsResponse } from '../models/interfaces';

import { AuthService } from './auth.service';
import { ApiService } from './api.service';

@Injectable()
export class TeamService {

  private initialDataUpdatedSubject$ = new Subject<Team>();
  /**
   * Indica que se ha solicitado actualizar información de inicio. Al registrar un nuevo usuario
   */
  initialDataUpdated$ = this.initialDataUpdatedSubject$.asObservable().pipe(filter(x => !!x));

  constructor(
    private api: ApiService,
    private auth: AuthService
  ) { }

  /**
   * Get team with the specified id
   * @param teamId Team identifier
   * @returns 
   */
  getById(teamId: number) {
    return this.api.get<TeamResponse>(
      `teams-catalog/${teamId}/`
    ).pipe(
      map(response => {
        return new Team().fromResponse(response);
      })
    );
  }

  /**
   * Obtiene la lista paginada de equipos correspondientes a la institución del usuario en sesión.
   * @param search Filtro de búsqueda
   */
  getPaginatedList(
    search?: string,
    paginatedParams?: PaginatedParams,
    params?: HttpParams
  ): Observable<Teams> {

    // Filtros o parámetros.
    let httpParams = params || new HttpParams()

    httpParams = httpParams.set(TEAM_PARAM_NAMES.dashboard, true);

    // Filtro de búsqueda.
    if (search) {
      httpParams = httpParams.set(TEAM_PARAM_NAMES.search, search);
    }
    if (paginatedParams) {
      httpParams = httpParams
      .set('perpage', paginatedParams.pageSize)
      .set(TEAM_PARAM_NAMES.page, paginatedParams.page);
    }

    return this.api.get<TeamsResponse>(
      'teams-catalog/',
      httpParams
    ).pipe(
      map(response => {
        return new Teams().fromResponse(response);
      })
    );
  }

  /**
   * Obtiene la lista de equipos correspondientes a la institución del usuario en sesión.
   * @param search Filtro de búsqueda
   */
  getList(
    search?: string
  ): Observable<Array<Team>> {

    return this.getPaginatedList(search).pipe(
      map(response => response.data)
    );
  }

  /**
   * Obtiene el primer equipo de la institución
   * @returns 
   */
  getFirstTeam(): Observable<Team> {

    const paginatedParams = new PaginatedParams();
    paginatedParams.page = 1;
    paginatedParams.pageSize = 1;

    return this.getPaginatedList(
      null,
      paginatedParams
    ).pipe(
      map(response => response.data && response.data.length ? response.data[0] : null)
    );
  }

  /**
   * Obtiene lista de equipos con estructura o campos mínimos
   * @returns Listado de equipos
   */
  getFlatList(): Observable<Array<Team>> {
    return this.api
      .get<Array<{
        id: number,
        name: string,
        image: string,
        total_messages?: number,
        room: number;
        athletes: Array<Athlete>
      }>>('teams-widget/')
      .pipe(
        map(response => {
          return response.map(teamResponse => {
            const team = new Team();
            team.id = teamResponse.id;
            team.name = teamResponse.name;
            team.image = teamResponse.image;
            team.totalMessages = teamResponse?.total_messages ?? 0;
            team.room = teamResponse.room;
            team.athletes = teamResponse.athletes;
            return team;
          });
        })
      );
  }

  addAthlete(
    teamId: number,
    athleteId: number
  ): Observable<any> {

    const body = {
      type: 1,
      team: teamId,
      user: athleteId
    }

    return this.api.post('current-user-team/', body);
  }

  /**
   * Obtiene la lista de atletas del equipo indicado
   * @param teamId Identificador de equipo
   */
  getAthletes(
    teamId: number
  ): Observable<Array<Athlete>> {

    // Filtros o parámetros.
    let httpParams = new HttpParams()
      .set('type', TeamUserType.athlete.toString());

    return this.api
      .get<Array<AthleteResponse>>(`current-user-team/${teamId.toString()}/`, httpParams)
      .pipe(map(response => {

        const athletes: Array<Athlete> = [];
        response.forEach(athleteResponse => {
          athletes.push(<Athlete>{
            id: athleteResponse.id,
            fullName: athleteResponse.full_name,
            email: athleteResponse.email,
            photo: athleteResponse.photo,
            birthdate: moment(athleteResponse.birthday, false).toDate(),
            totalMessages: athleteResponse.total_messages,
            room: athleteResponse.room
          });
        })

        return athletes;
      }));
  }

  getUsers(
    params?: Array<RequestParam>
  ): Observable<any> {

    // Filtros o parámetros.
    let httpParams = new HttpParams();
    if (params) {
      params.forEach(filter => {
        httpParams = httpParams.set(filter.key, filter.value);
      });
    }

    return this.api.get('user-team/', httpParams);
  }

  /**
   * Crea un equipo
   * @param team 
   * @param institutionId TODO: Revisar si se requiere éste parámetro o se puede utilizar el campo en el mismo modelo Team
   * @param withFormData 
   * @returns 
   */
  create(
    team: Team,
    institutionId?: number,
    withFormData = false
  ): Observable<Team> {

    // Si la entidad no tiene cambios retorna el mismo objeto enviado
    if (!team.hasChanges) return of<Team>(team);

    // Asigna institución
    if (!team.institution) {
      team.institution = new Institution();
      team.institution.id = institutionId || this.auth.institutionId;
    }

    return this.api
      .post<TeamResponse>(
        `teams/`,
        withFormData ? team.toFormDataRequest() : team.toRequest()
      ).pipe(
        catchError(this.api.processError('Team.create')),
        map(response => {
          // Aplica cambios para una siguiente validación de cambios
          team.applyChanges();
          return team.fromResponse(response);
        })
      );
  }

  /**
   * Modifica el equipo indicado
   * @param team 
   * @param withFormData 
   * @returns 
   */
  update(
    team: Team,
    withFormData = false
  ): Observable<Team> {

    // Si la entidad no tiene cambios retorna el mismo objeto enviado
    if (!team.hasChanges) return of<Team>(team);

    if (!team.institution) {
      team.institution = new Institution();
      team.institution.id = this.auth.institutionId;
    }

    return this.api
      .patch<TeamResponse>(
        `teams/${team.id}/`,
        withFormData ? team.toFormDataRequest() : team.toRequest()
      ).pipe(
        catchError(this.api.processError('TeamService.update')),
        map(response => {
          // Aplica cambios para una siguiente validación de cambios sobre el objeto enviado a actualizar
          team.applyChanges();
          // Equipo con los datos actualizados
          const teamUpdated = team.fromResponse(response);
          teamUpdated.imageFile = team.imageFile; // Ésta propiedad no se retorna de backend
          return teamUpdated;
        })
      );
  }

  /**
   * Modifica la imagen del equipo indicado
   */
  updateImage(
    team: Team,
  ): Observable<Team> {

    let payload: FormData | { image: string } = null;

    // Verifica si se ha asignado archivo de imagen
    if (team.imageFileHasChanges && team.imageFile) {
      payload = new FormData();
      payload.append('image', team.imageFile, team.imageFile.name);
    }
    // Verifica si la imagen se ha modificado. Permite eliminar de BD en caso de que se haya eliminado la imagen
    else if (team.imageHasChange) {
      payload = {
        image: team.imageToSet
      }
    }
    // Si la entidad no tiene cambios retorna el mismo objeto enviado
    else {
      return of(team);
    }

    return this.api
      .patch<TeamResponse>(
        `teams/${team.id}/`,
        payload
      ).pipe(
        catchError(this.api.processError('TeamService.updateImage')),
        map(response => {
          // Aplica cambios para una siguiente validación de cambios sobre el objeto enviado a actualizar
          team.applyChanges();
          // Equipo con los datos actualizados
          const teamUpdated = team.fromResponse(response);
          teamUpdated.imageFile = team.imageFile; // Ésta propiedad no se retorna de backend
          return teamUpdated;
        })
      );
  }

  emitInitialDataUpdated(team: Team): void {
    this.initialDataUpdatedSubject$.next(team);
  }
}