import {Serializer} from './interfaces/serializer.interface';
import {AuditUser} from './audit-user';
import {QuestionRequest, QuestionResponse} from './interfaces/question-response.interface';
import {InputType, QuestionLanguage, QuestionType} from './enumerations';

export class Question implements Serializer<Question> {
  constructor(
  public id?: number,
  public survey?: number,
  public questionType?: QuestionType,
  public inputType?: InputType,
  public language?: QuestionLanguage,
  public title?: string,
  public description?: string,
  public unit?: string,
  public maxSelect?: number,
  public required?: boolean,
  public hasLibrary?: boolean,
  public order?: number,
  public audit?: Pick<AuditUser, 'updatedAt' | 'createdAt'>
  ) {
  }

  static fromResponse = (response: QuestionResponse): Question => new Question().fromResponse(response);

  fromResponse(response: QuestionResponse): Question {
    return new Question(
      response.id,
      response.survey,
      response.question_type,
      response.input_type,
      response.language,
      response.title,
      response.description,
      response.unit,
      response.max_select,
      response.required,
      response.has_library,
      response.order,
      new AuditUser().fromResponse({
        created_by: null,
        created_at: response.created_at,
        updated_by: null,
        updated_at: response.updated_at
      })
    );
  }

  toRequest(): QuestionRequest {
    return {
      survey: this.survey,
      question_type: this.questionType,
      input_type: this.inputType,
      language: this.language,
      title: this.title,
      description: this.description,
      unit: this.unit,
      max_select: this.maxSelect,
      required: this.required,
      has_library: this.hasLibrary,
      order: this.order
    };
  }
}
