import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacySnackBarConfig as MatSnackBarConfig, MatLegacySnackBarModule as MatSnackBarModule, MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/legacy-snack-bar';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

import { NotificationService } from './services/notification.service';
import { NotificationComponent } from './notification/notification.component';
import { NotificationSuccessComponent } from './notification-success/notification-success.component';
import { NotificationErrorComponent } from './notification-error/notification-error.component';

const COMPONENTS = [
  NotificationComponent,
  NotificationSuccessComponent,
  NotificationErrorComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatButtonModule
  ],
  exports: [
    ...COMPONENTS
  ],
  providers: [
    NotificationService,
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: <MatSnackBarConfig>{
        duration: 3000,
        horizontalPosition: 'right',
        verticalPosition: 'top',
        panelClass: ['web-notification__panel-class']
      }
    }
  ]
})
export class SpNotificationModule { }
