import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl } from '@angular/forms';
import { SubSink } from 'subsink';

import { Institution, InstitutionService, ProgressSpinnerService, User, UserService, UtilitiesService } from 'sp-core';

import { INSTITUTION_DETAIL_CONTROL_NAMES } from './institution-detail.constants';
import { finalize } from 'rxjs/operators';
import { NotificationService } from './../../sp-notification/services/notification.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'sp-institution-detail',
  templateUrl: './institution-detail.component.html',
  styleUrls: ['./institution-detail.component.scss']
})
export class InstitutionDetailComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;

  CONTROL_NAMES = INSTITUTION_DETAIL_CONTROL_NAMES;

  institution: Institution;

  isNew = false;

  get nameCtrl(): AbstractControl {
    return this.form.get(this.CONTROL_NAMES.name);
  }

  get imageCtrl(): AbstractControl {
    return this.form.get(this.CONTROL_NAMES.image);
  }

  get imageFileCtrl(): AbstractControl {
    return this.form.get(this.CONTROL_NAMES.imageFile);
  }

  private subSink = new SubSink();

  constructor(
    @Inject(MAT_DIALOG_DATA) data: Institution,
    private dialogRef: MatDialogRef<InstitutionDetailComponent>,
    private fb: UntypedFormBuilder,
    private institutionService: InstitutionService,
    private notificationService: NotificationService,
    private spinnerService: ProgressSpinnerService
  ) {

    this.institution = data || new Institution;
    this.isNew = !this.institution.id;

    this.createForm();
  }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.subSink.unsubscribe();
  }

  handleSaveClick(): void {

    const institution = new Institution();
    institution.name = this.nameCtrl.value;
    institution.identifierName = UtilitiesService.getDbName(institution.name);
    institution.imageFile = this.imageFileCtrl.value;

    this.spinnerService.start();
    this.institutionService
      .update(institution, true)
      .pipe(
        finalize(() => this.spinnerService.stop())
      ).subscribe(() => {
        this.dialogRef.close();
        this.notificationService.success('Institution updated successfully');
      });
  }

  handleUploadImageFile(file: File): void {
    this.imageFileCtrl.setValue(file);
  }

  private createForm(): void {

    this.form = this.fb.group({});

    const nameCtrl = this.fb.control(this.institution.name, [Validators.required]);
    this.form.addControl(this.CONTROL_NAMES.name, nameCtrl);

    const imageCtrl = this.fb.control(this.institution.image);
    this.form.addControl(this.CONTROL_NAMES.image, imageCtrl);

    const imageFileCtrl = this.fb.control(null);
    this.form.addControl(this.CONTROL_NAMES.imageFile, imageFileCtrl);
  }
}