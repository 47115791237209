import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {
  ApiService, BlockCoding, BlockCodingResponse, ExerciseBlock, ExerciseBlocksData, ExerciseBlocksDataResponse, PaginatedParams, WorkoutBlock,
  WorkoutBlockResponse, WorkoutBlocks, WorkoutBlocksResponse, WorkoutBlockType, WorkoutBlockTypeResponse
} from 'sp-core';

import { ParameterService } from './parameter.service';

@Injectable()
export class WorkoutBlockService {

  private blockRemovedSubject$ = new BehaviorSubject<WorkoutBlock>(null);
  /**
   * Observable para notificar cuando un bloque de workout ha sido eliminado.
   */
  blockRemoved$ = this.blockRemovedSubject$.asObservable();

  private codingsSubject$ = new BehaviorSubject<Array<BlockCoding>>([]);
  /**
   * Obtiene la lista de codings actuales obtenidos de backend
   */
  codings$ = this.codingsSubject$.asObservable();

  private blockTypesSubject$ = new BehaviorSubject<Array<WorkoutBlockType>>([]);
  /**
   * Obtiene la lista actual de tipos de bloque obtenidos de backend
   */
  blockTypes$ = this.blockTypesSubject$.asObservable();

  constructor(
    private api: ApiService,
    private parameterService: ParameterService
  ) { }

  /**
   * Obtiene el bloque que corresponda al identificador indicado
   */
  getById(
    blockId: number
  ): Observable<WorkoutBlock> {

    return this.api.get<WorkoutBlockResponse>(
      `blocks/${blockId}/`
    ).pipe(
      map(response => new WorkoutBlock().fromResponse(response))
    );
  }

  /**
   * Obtiene los bloques que correspondan al workout/ rutina
   * @param workoutId Identificador de rutina
   */
  getPaginatedList(
    workoutId?: number,
    has_library?: boolean,
    paginatedParams?: PaginatedParams
  ): Observable<WorkoutBlocks> {

    let params = new HttpParams();

    if (workoutId) {
      params = params.set('workout', workoutId.toString())
    }

    params = params
      .set('active', '1')
      .set('has_library', has_library ? 'true' : 'false');

    if (paginatedParams) {
      params = paginatedParams.toRequest(params);
    } else {
      // Se envía 0 para obtener todos los registros en una sola petición
      params = params.set('perpage', '0')
    }

    return this.api.get<WorkoutBlocksResponse>(
      'builder/blocks/',
      params
    ).pipe(
      map(response => new WorkoutBlocks().fromResponse(response))
    );
  }

  /**
   * Obtiene los bloques que correspondan al workout indicado
   * @param workoutId Identificador de rutina
   */
  get(
    workoutId?: number,
    has_library?: boolean,
  ): Observable<Array<WorkoutBlock>> {

    return this.getPaginatedList(
      workoutId,
      has_library
    ).pipe(
      map(response => response.data)
    );
  }

  /**
   * Obtiene los bloques que correspondan al workout/ rutina
   * @param workoutLogId Identificador de rutina
   */
  getPaginatedLogs(
    workoutLogId: number,
    paginatedParams?: PaginatedParams
  ): Observable<WorkoutBlocks> {

    let params = new HttpParams().set('active', '1');

    if (paginatedParams) {
      params = paginatedParams.toRequest(params);
    } else {
      // Se envía 0 para obtener todos los registros en una sola petición
      params = params.set('perpage', '0')
    }

    return this.api.get<WorkoutBlocksResponse>(
      `copy-block-values/${workoutLogId}/`,
      params
    ).pipe(
      map(response => new WorkoutBlocks().fromResponse(response))
    );
  }

  /**
   * ```
   * EP GET copy-block-values/
   * ```
   * @param workoutLogId 
   * @returns 
   */
  getLogs(
    workoutLogId: number
  ): Observable<Array<WorkoutBlock>> {
    return this.getPaginatedLogs(
      workoutLogId
    ).pipe(
      map(response => response.data)
    );
  }

  /**
   * Obtiene bloques de librería
   */
  getPaginatedLibraries(
    params?: HttpParams,
    paginatedParams?: PaginatedParams
  ): Observable<WorkoutBlocks> {

    if (!params) {
      params = new HttpParams();
    }

    if (paginatedParams) {
      params = paginatedParams.toRequest(params);
    }

    if (!params.get('tab')) {
      params = params.set('tab', 'all');
    }

    params = params
      .set('active', '1')
      .set('has_library', 'true');

    return this.api.get<WorkoutBlocksResponse>(
      'block-library/',
      params
    ).pipe(
      map(response => new WorkoutBlocks().fromResponse(response))
    );
  }

  /**
   * Obtiene colección de bloques de librería
   */
  getLibraries(
    params?: HttpParams,
    search?: string
  ): Observable<Array<WorkoutBlock>> {

    let paginatedParams: PaginatedParams;
    if (search) {
      paginatedParams = new PaginatedParams();
      paginatedParams.search = search;
    }

    return this.getPaginatedLibraries(
      params,
      paginatedParams
    ).pipe(
      map(response => response.data)
    );
  }

  getBlockCodings(): Observable<Array<BlockCoding>> {
    return this.api.get<Array<BlockCodingResponse>>(
      'coding/'
    ).pipe(
      map(response => {
        const blockCodings = response.map(codingResponse => new BlockCoding().fromResponse(codingResponse))
          .sort((a, b) => a.id - b.id);
        // Notifica que se ha obtenido de la bd los registros de codings
        this.codingsSubject$.next(blockCodings);
        return blockCodings;
      })
    );
  }

  getBlockTypes(): Observable<Array<WorkoutBlockType>> {
    return this.api.get<Array<WorkoutBlockTypeResponse>>(
      'blocktypes/'
    ).pipe(
      map(response => {
        const blockTypes = response.map(blockTypeResponse => new WorkoutBlockType().fromResponse(blockTypeResponse));
        // Notifica que se ha obtenido de la bd los registros de codings
        this.blockTypesSubject$.next(blockTypes);
        return blockTypes;
      })
    )
  }

  /**
   * Obtiene los ejercicios correspondientes a un bloque para phase builder
   * @param blockId Identificador de bloque
   * @returns 
   */
  getBuilderPaginatedExercises(
    blockId: number,
    paginatedParams?: PaginatedParams
  ): Observable<ExerciseBlocksData> {

    let params = new HttpParams().set('block', blockId.toString());

    if (paginatedParams) {
      params = paginatedParams.toRequest(params);
    }

    return this.api.get<ExerciseBlocksDataResponse>(
      `builder/exercises/`,
      params
    ).pipe(
      map(response => new ExerciseBlocksData().fromResponse(response)),
      catchError(this.api.handleError('WorkoutBlockService.getBuilderPaginatedExercises'))
    );
  }

  create(workoutBlock: WorkoutBlock): Observable<WorkoutBlock> {
    return this.api.post<WorkoutBlockResponse>(
      'blocks/',
      workoutBlock.toRequest()
    ).pipe(
      map((response) => workoutBlock.fromResponse(response))
    );
  }

  /**
   * ```
   * EP: PATCH blocks/<blockId>/
   * ```
   * @param block 
   * @returns 
   */
  update(block: WorkoutBlock): Observable<WorkoutBlock> {

    // En caso de que el bloque no tenga cambios retorna el mismo objeto sin enviar a actualizar en BD
    if (!block.hasChanges) return of(block);

    return this.api.patch<WorkoutBlockResponse>(
      `blocks/${block.id}/`,
      block.toRequest()
    ).pipe(
      map((response) => {
        block.applyChanges();
        return block.fromResponse(response);
      })
    );
  }

  updateName(workoutBlock: WorkoutBlock): Observable<WorkoutBlock> {
    return this.api.patch<WorkoutBlockResponse>(
      `blocks/${workoutBlock.id}/`,
      workoutBlock.toUpdateNameRequest()
    ).pipe(
      map((response) => workoutBlock.fromResponse(response))
    );
  }

  updateType(workoutBlock: WorkoutBlock): Observable<WorkoutBlock> {
    return this.api.patch<WorkoutBlockResponse>(
      `blocks/${workoutBlock.id}/`,
      workoutBlock.toUpdateTypeRequest()
    ).pipe(
      map((response) => workoutBlock.fromResponse(response))
    );
  }

  clone(block: WorkoutBlock): Observable<WorkoutBlock> {

    const body = {
      model: 'block'
    };

    return this.api.post<WorkoutBlockResponse>(
      `clone-library/${block.id}/`, body
    ).pipe(
      map(response => {
        return new WorkoutBlock().fromResponse(response);
      })
    );
  }

  /**
   * Elimina un bloque de la BD
   * @param block Bloque a eliminar
   */
  delete(block: WorkoutBlock): Observable<WorkoutBlock> {
    return this.api.delete<WorkoutBlockResponse>(
      `blocks/${block.id}/`
    ).pipe(
      map(response => {
        const removedBlock = new WorkoutBlock().fromResponse(response);
        this.blockRemovedSubject$.next(block);
        return removedBlock;
      })
    );
  }

  /**
   * Actualiza el orden de bloques<->ejercicios de un bloque
   * @param blockId Identificador de bloque que contiene los ejercicios a modificar su orden
   * @param blockExercises Colección de bloques<->ejercicios en el orden a actualizar
   * @returns 
   */
  updateBlockExercisesOrder(
    blockId: number,
    blockExercises: Array<ExerciseBlock>
  ): Observable<boolean> {

    return this.api.post(
      `exercise-blocks-sorting/${blockId}/`,
      blockExercises.map(x => x.id)
    ).pipe(
      map(() => true)
    );
  }

  /**
   * Guarda el bloque como template o librería
   * @param block 
   * @returns 
   * @author José Chin
   */
  saveAsTemplate(
    block: WorkoutBlock
  ): Observable<boolean> {

    const data = {
      name: block.name
    }

    return this.api.post(
      `blocks/${block.id}/library/`,
      data
    ).pipe(
      catchError(this.api.processError('WorkoutBlockService.saveAsTemplate')),
      map(() => true)
    );
  }
}