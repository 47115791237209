import { Subject } from "rxjs";

import { Serializer } from "./interfaces";
import { FolderBlockDataResponse } from "./interfaces/folder-block-data-response";
import { FolderBlockDataRequest } from "./interfaces/folder-block-data-request";
import { FolderType } from "./enumerations/folder-type.enum";
import { Institution } from ".";

export class FolderBlockData implements Serializer<FolderBlockData>{
    
    /**
     * Objeto para verificar valores previos a una modificación y permitir enviar sólo los campos modificados
     */
    private initialValues: FolderBlockData; 

    private _isSelected = false;

    get hasChanges(): boolean {
        return this.name !== this.initialValues.name;
    }

    /**
     * Obtiene si la carpeta está seleccionada. No se obtiene de backend, es un campo auxiliar para frontend
     */
    get isSelected(): boolean {
        return this._isSelected;
    }

    constructor(
        public id?: number,
        public name?: string,
        public hasLibrary?: boolean,
        public folder_type?: FolderType,
        public institution?: Institution,
        /**
         * Campo auxiliar para front. Se utiliza para solicitar modo edición para el nombre de la carpeta
         */
        public changeEditMode?: Subject<boolean>
    ) {
        // Se inicializan los valores iniciales sólo indicado la estructura para evitar instanciar y generar max stack
        this.initialValues = {} as FolderBlockData;
        this.setInitialValues(this);
    }

    fromResponse(response: FolderBlockDataResponse): FolderBlockData {

        const folder = new FolderBlockData(
            response.id,
            response.name,
            response.has_library
        );

        if (response.institution) {
            const institution = new Institution();
            institution.id = response.institution;
            folder.institution = institution;
        }

        return folder;
    }

    toRequest(): FolderBlockDataRequest {
        return <FolderBlockDataRequest>{
            name: this.name,
            folder_type: this.folder_type,
        }
    }

    select(): void {
        this._isSelected = true;
    }

    unselect(): void {
        this._isSelected = false;
    }

    /**
     * Selecciona o des-selecciona la carpeta en base al su estatus actual. Si el elemento NO está seleccionado lo seleciona y viceversa
     */
    selectUnselect(): void {
        if (this.isSelected) {
            this.unselect();
        } else {
            this.select();
        }
    }

    /**
     * Aplica los valores actuales del objeto para una siguiente validación de cambios
     */
    applyChanges(): void {
        this.setInitialValues(this);
    }

    /**
     * Asigna valores iniciales a los campos de validación de cambios
     * @param folder Carpeta con los datos actuales
     */
    private setInitialValues(folder: FolderBlockData): void {
        this.initialValues.name = folder.name;
    }
}