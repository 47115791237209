<div [class.extra-size]="expand" class="user-complement-data d-flex flex-column">
    <div class="web-languages d-flex justify-content-end mb-2"
        [formGroup]="formLanguage">
        <mat-form-field appearance="outline">
            <mat-label>{{'language' | i18next}}</mat-label>
            <mat-select [formControlName]="CONTROL_NAMES.language">
                <mat-option [value]="UserLanguage.English">
                    <mat-icon svgIcon="united-states"></mat-icon>
                    <span>English</span>
                </mat-option>
                <mat-option [value]="UserLanguage.Spanish">
                    <mat-icon svgIcon="spain"></mat-icon>
                    <span>Español</span>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <swiper #swiper
        class="flex-grow-1 w-100"
        [config]="swiperConfig">
        <form class="swiper-wrapper"
            [formGroup]="form">
            <div class="swiper-slide"
                *ngFor="let slide of SLIDES;let index=index">
                <div class="slide__content h-100 d-flex flex-column"
                    [ngSwitch]="slide.name">
                    <!-- Institution data -->
                    <ng-container *ngSwitchCase="'institution'">
                        <label class="web-swiper-slide__question">
                            {{'set_institution_name' | i18next}}
                        </label>
                        <mat-form-field appearance="outline">
                            <input #institutionNameInput
                                tabindex="-1"
                                type="text"
                                [placeholder]="'institution_name_placeholder' | i18next"
                                matInput
                                [formControlName]="CONTROL_NAMES.institutionName"
                                (keyup.enter)="onInputKeyupEnter($event, CONTROL_NAMES.institutionName)"
                                (keydown.tab)="onInputKeyupEnter($event, CONTROL_NAMES.institutionName)">
                            <mat-error *ngIf="institutionNameCtrl.hasError">
                                {{'institution_name_required' | i18next}}
                            </mat-error>
                        </mat-form-field>
                    </ng-container>
                    <!-- Team data -->
                    <ng-container *ngSwitchCase="'team'">
                        <label class="web-swiper-slide__question">
                            {{'set_team_name' | i18next}}
                        </label>
                        <mat-form-field appearance="outline">
                            <input #teamNameInput
                                type="text"
                                tabindex="-1"
                                [placeholder]="'team_name_placeholder' | i18next"
                                matInput
                                [formControlName]="CONTROL_NAMES.teamName"
                                (keyup.enter)="onInputKeyupEnter($event, CONTROL_NAMES.teamName)"
                                (keydown.tab)="onInputKeyupEnter($event, CONTROL_NAMES.teamName)">
                            <mat-error *ngIf="teamNameCtrl.hasError">
                                {{'team_name_required' | i18next}}
                            </mat-error>
                        </mat-form-field>
                    </ng-container>
                    <!-- User data -->
                    <ng-container *ngSwitchCase="'user'">
                        <label class="web-swiper-slide__question">
                            {{'set_full_name' | i18next}}
                        </label>
                        <mat-form-field appearance="outline">
                            <input #fullNameInput
                                type="text"
                                tabindex="-1"
                                [placeholder]="'full_name_placeholder' | i18next"
                                matInput
                                [formControlName]="CONTROL_NAMES.fullname"
                                (keyup.enter)="onInputKeyupEnter($event, CONTROL_NAMES.fullname)"
                                (keydown.tab)="onInputKeyupEnter($event, CONTROL_NAMES.fullname)">
                            <mat-error *ngIf="fullNameCtrl.hasError">
                                {{'full_name_required' | i18next}}
                            </mat-error>
                        </mat-form-field>
                    </ng-container>
                    <!-- Phone and region data -->
                    <ng-container *ngSwitchCase="'phone'">
                        <label class="web-swiper-slide__question">
                            {{'set_phone_number' | i18next}}
                        </label>
                        <mat-form-field appearance="outline">
                            <ngx-mat-intl-tel-input #phoneInput
                                [preferredCountries]="['es', 'mx', 'us']"
                                [inputPlaceholder]="'phone_placeholder' | i18next"
                                [enablePlaceholder]="true"
                                [enableSearch]="true"
                                [formControlName]="CONTROL_NAMES.phone"
                                (countryChanged)="phoneCountryChanged($event)">
                            </ngx-mat-intl-tel-input>
                            <mat-error *ngIf="phoneCtrl.hasError">
                                {{'phone_required' | i18next}}
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label>{{'region' | i18next}}</mat-label>
                            <mat-select #regionSelect
                                tabindex="-1"
                                [formControlName]="CONTROL_NAMES.region"
                                (keydown.tab)="onInputKeyupEnter($event, CONTROL_NAMES.region)">
                                <mat-option *ngFor="let region of regions"
                                    [value]="region.id">
                                    {{region.name}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                      <p *ngIf="regionCtrl.value === 4">
                        <span>{{'other_countries_info' | i18next}} </span>
                        <a target="_blank" href="https://stripe.com/global">{{'other_countries_link' | i18next}}</a>
                        <mat-icon
                          class="myIcon"
                        >info</mat-icon>
                      </p>
                    </ng-container>
                    <!-- Professional type data-->
                    <ng-container *ngSwitchCase="'professional'">
                        <label class="web-swiper-slide__question">
                            {{'choose_professional_type' | i18next}}
                        </label>
                        <mat-form-field appearance="outline">
                            <mat-label>{{'professional_type_label' | i18next}}</mat-label>
                            <mat-select #professionalTypeSelect
                                tabindex="-1"
                                [formControlName]="CONTROL_NAMES.professionalType">
                                <mat-option *ngFor="let professionalType of professionalTypes"
                                    [value]="professionalType.id">
                                    {{professionalType.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="professionalTypeCtrl.hasError">
                                {{'professional_type_required' | i18next}}
                            </mat-error>
                        </mat-form-field>
                    </ng-container>
                </div>
            </div>
        </form>
        <div class="swiper-pagination"></div>
    </swiper>

    <div class="d-flex">

        <!-- Previuos button -->
        <button class="web-swiper-button--prev"
            mat-stroked-button
            [disabled]="isBeginning">
            {{'action_previous' | i18next}}
        </button>

        <div class="flex-grow-1 d-flex justify-content-end">

            <!-- Next button -->
            <ng-container *ngIf="!isEnd;else finishOptions">
                <button class="web-swiper-button--next"
                    mat-stroked-button
                    [disabled]="isEnd">
                    {{'action_next' | i18next}}
                </button>
            </ng-container>

            <!-- Finish options -->
            <ng-template #finishOptions>

                <!-- Error messages -->
                <button *ngIf="form.invalid"
                    class="sp-icon-button sp-icon-button--filled"
                    mat-icon-button
                    color="error"
                    popoverClass="sp-card"
                    triggers="mouseenter:mouseleave"
                    [ngbPopover]="popoverContent">
                    <mat-icon svgIcon="info"></mat-icon>
                </button>
                <ng-template #popoverContent>
                    <ul class="web-error-list">
                        <li *ngIf="institutionNameCtrl.errors">
                            {{'institution_name_required' | i18next}}
                        </li>
                        <li *ngIf="teamNameCtrl.errors">
                            {{'team_name_required' | i18next}}
                        </li>
                        <li *ngIf="fullNameCtrl.errors">
                            {{'full_name_required' | i18next}}
                        </li>
                        <li *ngIf="phoneCtrl.errors">
                            {{'phone_required' | i18next}}
                        </li>
                        <li *ngIf="professionalTypeCtrl.errors">
                            {{'professional_type_required' | i18next}}
                        </li>
                    </ul>
                </ng-template>

                <!-- Finish button -->
                <button #btnFinish
                    [hidden]="!isEnd"
                    mat-flat-button
                    color="primary"
                    [disabled]="form.invalid || formLanguage.invalid"
                    (click)="handleFinishClick()">
                    {{'action_finish' | i18next}}
                </button>

            </ng-template>
        </div>
    </div>
</div>
