<div class="sp-exercise-card-list d-flex flex-wrap">
    <div *ngFor="let exercise of exercises"
        [ngClass]="{'sp-exercise-card-list__vertical': isVertical(exercise)}"
        class="sp-exercise-card-list__card">
        <div class="h-100">
            <sp-exercise-card
                [exercise]="exercise"
                (exerciseClick)="handleExerciseCardClick($event)">
            </sp-exercise-card>
        </div>
    </div>
</div>