import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiService } from "sp-core";
import { ChatFile } from "../models";

@Injectable({
    providedIn: 'root',
})
export class UploadFileService {

    constructor(
        private API: ApiService,
    ) { }

    uploadFile(file: File): Observable<ChatFile> {
        const formData = new FormData();
        formData.append('file', file);
        return this.API.post('chat-media/', formData);
    }
}