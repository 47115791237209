import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharingDataComponentsService {

  ///////////////////////////////////////////////////
  /////////////////// data to get ///////////////////
  ///////////////////////////////////////////////////
  private wayToEditPictureProfile = new BehaviorSubject(null);
  getPictureProfile = this.wayToEditPictureProfile.asObservable();
  
  constructor() { }
  
  ///////////////////////////////////////////////////
  /////////////////// data to set ///////////////////
  ///////////////////////////////////////////////////
  setPictureProfile(img: string){
    this.wayToEditPictureProfile.next(img)
  }
}