import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ImageCropperModule } from 'ngx-image-cropper';
import { I18NextModule } from 'angular-i18next';

import { SpDisableContextmenuModule, SpDragNDropModule } from '../../directives';
import { SpProgressSpinnerModule } from '../sp-progress-spinner';
import { UploadImageComponent } from './upload-image/upload-image.component';
import { UploadVideoComponent } from './upload-video/upload-video.component';

const COMPONENTS = [
  UploadImageComponent,
  UploadVideoComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS
  ],
  imports: [
    CommonModule,
    ImageCropperModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    I18NextModule,
    SpDisableContextmenuModule,
    SpProgressSpinnerModule,
    SpDragNDropModule
  ],
  exports: [
    ...COMPONENTS
  ]
})
export class SpUploadModule {
  constructor(
    private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.iconRegistry.addSvgIcon('image', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/image.svg'))
    this.iconRegistry.addSvgIcon('delete', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/delete-fill0-24.svg'));
  }
}
