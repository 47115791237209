import { Component, ContentChildren, Input, AfterContentInit, QueryList, ViewChild, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { MatLegacyColumnDef as MatColumnDef, MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { Router } from '@angular/router';

import { Data } from 'sp-core';

import { PageConfig, PaginatorConfig, Sort } from './table.interface'
import { List, NoContent } from '../../../animations/animations';

@Component({
  selector: 'sp-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [NoContent, List]
})
export class TableComponent<T> implements OnChanges, AfterContentInit {

  @Input() columns: Array<string> = [];
  @Input() data: Data<any>;
  @Input() footer: boolean;
  @Input() noContentText: string;
  @Input() expandedDetail = false;
  @Input() sort: Sort;
  @Input() createParams = true;
  @Input() defaultPageSize = 0;
  @Input() stickyHeader = false;
  @Input() selectableRow = true;

  @Output() rowClick = new EventEmitter<any>();
  @Output() pageChange = new EventEmitter<PaginatorConfig>();

  paginatorConfig: PaginatorConfig = {} as PaginatorConfig;
  dataSource: T[] = [];
  loader: boolean;

  // this is where the magic happens:
  @ViewChild(MatTable, { static: true }) table: MatTable<T>;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;

  constructor(
    private RT: Router
  ) { }

  /**
  * Crea la data a enviar a setear.
  * @params SC recive el cambio de todo los Input.
  * @author Martin Batun Tec.
  */
  ngOnChanges(SC: SimpleChanges): void {
    this.loader = true;

    if (SC.data && !SC.data.firstChange) {
      this.paginatorConfig = new PageConfig(SC.data.currentValue.pagination);
      this.dataSource = SC.data.currentValue.data;
      this.loader = false;
    }
    if (SC.sort && !SC.sort.firstChange) {
      this.setSort(SC.sort.currentValue)
    }
  }

  // after the <ng-content> has been initialized, the column definitions are available.
  // All that's left is to add them to the table ourselves:
  ngAfterContentInit() {
    this.columnDefs.forEach(columnDef => this.table.addColumnDef(columnDef));
  }

  /**
  * Crea la data a enviar a setear.
  * @params event la configuracion de la paginacion.
  * @author Martin Batun Tec.
  */
  setPage(event: PaginatorConfig): void {

    if (this.createParams) {
      let queryParams: { perpage: number, page: number };
      queryParams = {
        // si el tamaño de la pagina es diferente al default lo agrega sino lo quita
        perpage: event.pageSize != 25 ? event.pageSize : null,
        // si la pagina es diferente a 1 la agrega sino lo quita
        page: event.pageIndex != 0 ? event.pageIndex + 1 : null,
      }
      this.setParams(queryParams);
    }

    this.pageChange.emit(event);
  }

  /**
  * Crea la data a enviar a setear.
  * @params sort recive el sort.
  * @author Martin Batun Tec.
  */
  setSort(sort: Sort): void {
    if (!this.createParams) return;
    this.setParams({
      ordering: sort.direction
        ? `${(sort.direction == 'asc' ? '' : '-')}${sort.active}`
        : null
    });
  }

  /**
  * Setea los params en la url.
  * @params queryParams recive los params.
  * @author Martin Batun Tec.
  */
  setParams(queryParams: any): void {
    const urlTree = this.RT.createUrlTree([], {
      queryParams: queryParams,
      queryParamsHandling: "merge",
      preserveFragment: true
    });
    this.RT.navigateByUrl(urlTree);
  }

  handleRowClick(row: any): void {
    this.rowClick.emit(row);
  }
}
