import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { SpCardModule } from '../../components';
import { SpScrollModule } from '../../directives';

import { ChatService } from './services';
import { ChatComponent, ChatMessageComponent, ChatNewMessageComponent } from './components';

const COMPONENTS = [
  ChatComponent,
  ChatMessageComponent,
  ChatNewMessageComponent
];

const PROVIDERS = [
  ChatService
];

@NgModule({
  declarations: [
    ...COMPONENTS,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    NgScrollbarModule,
    SpCardModule,
    SpScrollModule
  ],
  exports: [
    ...COMPONENTS
  ],
  providers: [
    ...PROVIDERS
  ]
})
export class SpChatModule { }
