import moment from 'moment';

import { LoadStatus, WorkoutView } from './enumerations';
import {
  Serializer,
  WorkoutCreateRequest,
  WorkoutResponse,
  WorkoutUpdateRequest,
} from './interfaces';
import { SPF_SHORTTIME_FORMAT } from '../constants';

import { Audit } from './audit';
import { PhaseDay } from './phase-day';
import { WorkoutLocation } from './workout-location';
import { WorkoutBlock } from './workout-block';
import { Athlete } from './athlete';
import { Phase } from './phase';
import { WorkoutType } from './workout-type';
import { Team } from './team';
import { Program } from './program';
import { WorkoutLog } from './workout-log';
import { WorkoutTag } from './workout-tag';
import { UtilitiesService } from '../services';
import { FolderBlockData } from './folder-block-data';

const WORKOUT_DEFAULT_NAME = "Workout's name";

export class Workout implements Serializer<Workout> {
  /**
   * Elementos para selector RPE
   */
  static get rpeSelector(): Array<number> {
    return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  }

  /**
   * Objeto para verificar valores previos a una modificación y permitir enviar sólo los campos modificados
   */
  private initialValues: Workout;

  get startTimeString(): string {
    return UtilitiesService.getTimeString(this.startTime);
  }

  get endTimeString(): string {
    return UtilitiesService.getTimeString(this.endTime);
  }

  get allAthletesHasChanges(): boolean {
    return this.allAthletes !== this.initialValues.allAthletes;
  }

  get athletesHasChanges(): boolean {
    if (this.athletes.length !== this.initialValues.athletes.length)
      return true;

    const athletesIds = this.athletes.map((x) => x.id).sort((a, b) => a - b);
    const initialAthletesIds = this.initialValues.athletes
      .map((x) => x.id)
      .sort((a, b) => a - b);
    return athletesIds.join() !== initialAthletesIds.join();
  }

  get tagsHasChanges(): boolean {
    if (this.tags?.length !== this.initialValues.tags?.length) return true;

    const tagsIds = this.tags.map((x) => x.id).sort();
    const initialTagsIds = this.initialValues.tags.map((x) => x.id).sort();
    return tagsIds.join() !== initialTagsIds.join();
  }

  get foldersHasChanges(): boolean {
    if (this.folder?.length !== this.initialValues.folder?.length) return true;

    const foldersIds = this.folder;
    const initialFoldersIds = this.initialValues.folder;
    return foldersIds.join() !== initialFoldersIds.join();
  }

  get startTimeChanged() {
    // Cause initialValues don't created with new, it doesn't have the get properties like startTimeString
    const initialTime = this.initialValues.startTime
      ? UtilitiesService.getTimeString(this.initialValues.startTime)
      : null;

    const time = this.startTimeString || null;

    return initialTime !== time;
  }

  get endTimeChanged() {
    // Cause initialValues don't created with new, it doesn't have the get properties like endTimeString
    const initialTime = this.initialValues.endTime
      ? UtilitiesService.getTimeString(this.initialValues.endTime)
      : null;

    const time = this.endTimeString || null;

    return initialTime !== time;
  }

  /**
   * Verifica si existen cambios en modelo respecto a sus valores iniciales de creación.
   * TODO: De momento sólo se comprueban algunos campos. Crear una clase con la propiedad hasChanges y comparar por medio de JSON.stringify para verificar todos los campos
   */
  get hasChanges(): boolean {
    return (
      this.initialValues.name !== this.name ||
      this.initialValues.location?.id !== this.location?.id ||
      this.initialValues.rpe !== this.rpe ||
      this.startTimeChanged ||
      this.endTimeChanged ||
      this.initialValues.notes !== this.notes ||
      this.initialValues.type?.id !== this.type?.id ||
      this.initialValues.day?.id !== this.day?.id ||
      this.allAthletesHasChanges ||
      this.athletesHasChanges ||
      this.tagsHasChanges ||
      this.foldersHasChanges ||
      this.logsHasChanges
    );
  }

  get logsHasChanges(): boolean {
    return this.initialValues.log?.jsonData?.rpe !== this.log?.jsonData?.rpe;
  }
  /**
   * Nombre predeterminado del workout. Cuando el campo name está vacío
   */
  get defaultName(): string {
    return this.name ? this.name : WORKOUT_DEFAULT_NAME;
  }

  /**
   * Obtiene la fase al que corresponde el workout. Hereda la fase del día-semana al que está asignado el workout
   */
  get phase(): Phase {
    if (!this.day || !this.day.week) return null;

    // Si no tiene objeto fase pero sí tiene el id
    if (!this.day.week.phase && this.day.week.phaseId) {
      const phase = new Phase();
      phase.id = this.day.week.phaseId;
      return phase;
    }

    return this.day.week.phase;
  }

  /**
   * Obtiene si el workout tiene registro de log
   */
  get hasLog() {
    return !!this.log;
  }

  constructor(
    public id?: number,
    public name?: string,
    public startTime?: Date,
    public endTime?: Date,
    public rpe?: number,
    public srpe?: number,
    public order?: number,
    /**
     * Obtiene o establece si el workout es una plantilla o un workout asignado a Programa/Semana/Día
     */
    public hasLibrary = false,
    public isActive?: boolean,
    public notes?: string,
    public day?: PhaseDay,
    public location?: WorkoutLocation,
    public type?: WorkoutType,
    public audit?: Audit,
    public allAthletes?: boolean,
    public blocks: Array<WorkoutBlock> = [],
    /**
     * Atletas asignados
     */
    public athletes: Array<Athlete> = [],
    public teams?: Array<Team>,
    public program?: Program,
    /**
     * TODO: revisar el tipo correspondiente
     */
    public noteCreatedBy?: any,
    /**
     * TODO: Crear el modelo correspondiente
     */
    public status?: any,
    /**
     * TODO: Revisar si es necesario éste campo y si puede agregarse en el modelo que se cree para status
     */
    public statusDisplay?: any,
    /**
     * TODO: Verificar la información que contiene así como el modelo correspondiente
     */
    public log?: WorkoutLog,
    /**
     * Obtiene o establece la vista desde donde se está manipulando el workout
     * ```
     * Es un campo auxiliar para frontend. Se utiliza para que el backend determine la estructura a retornar del modelo de día
     * ```
     */
    public view?: WorkoutView,
    /**
     * Conteo de bloques. Cuando se obtiene información resumida de los workouts (pocos campos)
     */
    public blocksCount?: number,
    /**
     * Conteo de ejercicios. Cuando se obtiene información resumida de los workouts (pocos campos)
     */
    public exercisesCount?: number,
    /**
     * Obtiene o establece si el workout está siendo editado
     * ```
     * Campo auxiliar para frontend
     * ```
     */
    public isEditing = false,
    /**
     * Obtiene o establece si el workout está seleccionado
     * ```
     * Campo auxiliar para frontend
     * ```
     */
    public isSelected = false,
    /**
     * Obtiene o establece si el objeto está oculto en visualización.
     * NOTA: No se obtiene de backend. Es un campo auxiliar para frontend
     */
    public hidden?: boolean,
    /**
     * Obtiene o establece el estatus de carga de bloques del workout
     * NOTA: No se obtiene de backend. Es un campo auxiliar para frontend
     */
    public blocksLoadStatus = LoadStatus.pending,
    /**
     * TODO: Pendiente definir el tipo de dato
     */
    public tags: Array<WorkoutTag> = [],
    public folders: Array<number> = [],
    public folder: Array<FolderBlockData> = [],
  ) {
    // Se inicializan los valores iniciales sólo indicado la estructura para evitar instanciar y generar max stack
    this.initialValues = {} as Workout;
    this.initialValues.location = {} as WorkoutLocation;
    this.initialValues.day = {} as PhaseDay;
    this.initialValues.type = {} as WorkoutType;

    this.setInitialValues(this);
  }

  /**
   * Realiza la serialización de un objeto de backend a modelo
   * @param response Datos obtenidos de bd
   */
  fromResponse(response: WorkoutResponse): Workout {
    const workout = this.fromResponseCommonFields(response);

    // Workout blocks
    workout.blocks =
      response.blocks && response.blocks.length
        ? response.blocks.map((x) => new WorkoutBlock().fromResponse(x))
        : [];

    workout.setInitialValues(workout);

    return workout;
  }

  /**
   * Realiza la serialización de un objeto response de backend a modelo de workout.
   * ```
   * Se utiliza en el mapeo de workout-block
   * Sin considerar mapeo de blocks para evitar referencia circular
   * ```
   * @param response Datos obtenidos de bd
   */
  fromResponseWihoutBlocks(response: WorkoutResponse): Workout {
    const workout = this.fromResponseCommonFields(response);

    workout.setInitialValues(workout);

    return workout;
  }

  toRequest() {
    throw new Error('Method not implemented.');
  }

  /**
   * Realiza la serialización de la clase a un objeto para solicitar creación de un workout
   */
  toCreateRequest(): WorkoutCreateRequest {
    // NOTA: Si NO tiene dato verifica si antes tenía para permitir asignar vacío. undefined evita que se envíe en la solicitud
    // Ejemplo: (this.name || (this.initialValues.name ? '' : undefined))

    return <WorkoutCreateRequest>{
      name:
        this.name !== this.initialValues.name
          ? this.name || (this.initialValues.name ? '' : undefined)
          : undefined,
      order:
        this.order !== this.initialValues.order
          ? this.order || (this.initialValues.order ? 0 : undefined)
          : undefined,
      day:
        this.day?.id !== this.initialValues.day?.id
          ? this.day?.id || (this.initialValues.day?.id ? 0 : undefined)
          : undefined,
      start_time: !this.startTime
        ? '09:00'
        : this.startTimeChanged
          ? UtilitiesService.getTimeString(this.startTime)
          : undefined,
      end_time: !this.endTime
        ? '10:00'
        : this.endTimeChanged
          ? UtilitiesService.getTimeString(this.endTime)
          : undefined,
      location:
        this.location?.id !== this.initialValues.location?.id
          ? this.location?.id ||
            (this.initialValues.location?.id ? 0 : undefined)
          : undefined,
      rpe:
        this.rpe != this.initialValues.rpe
          ? this.rpe || 0 // Para RPE es válido el CERO
          : undefined,
      notes:
        this.notes != this.initialValues.notes
          ? this.notes || (this.initialValues.notes ? '' : undefined)
          : undefined,
      place_catalog:
        this.type?.id !== this.initialValues.type?.id
          ? this.type?.id || (this.initialValues.type?.id ? 0 : undefined)
          : undefined,
      all_users: this.allAthletesHasChanges
        ? this.allAthletes !== null && this.allAthletes !== undefined
          ? this.allAthletes
          : undefined
        : undefined,
      // En caso de que all athletes sea verdadero no es necesario enviar la lista de atletas. Backend realiza la asignación de todos los que tenga el programa
      athletes_id: this.allAthletes
        ? undefined
        : this.athletesHasChanges
          ? this.athletes.map((x) => x.id)
          : undefined,
      // Tags asignados
      tags_id: this.tagsHasChanges ? this.tags.map((x) => x.id) : undefined,
      // Folders asignados
      folder: this.foldersHasChanges ? this.folder.map((f) => f.id) : undefined,
      jsonData: this.logsHasChanges
        ? {
            rpe: this.log.jsonData.rpe,
            status: this.log.jsonData.status,
          }
        : this.log.jsonData,
    };
  }

  /**
   * Envía a actualizar el workout
   * ```
   * TODO: De momento sólo se considera la modificación de tags y carpetas
   * ```
   * @returns
   */
  toUpdateRequest(): WorkoutUpdateRequest {
    return <WorkoutUpdateRequest>{
      // Tags asignados
      tags_id: this.tagsHasChanges ? this.tags.map((x) => x.id) : undefined,
      folder: this.foldersHasChanges ? this.folder.map((f) => f.id) : [],
    };
  }

  /**
   * Obtiene el bloque por el identificador indicado
   * @param blockId Identificador de bloque a obtener
   */
  getBlockById(blockId: number): WorkoutBlock {
    if (!this.blocks.length) return null;

    const blocks = this.blocks.filter((block) => block.id === blockId);
    if (!blocks.length) return null;

    return blocks[0];
  }

  /**
   * Elimina el bloque según el identificador indicado
   * @param blockId Identificador de bloque a eliminar
   */
  removeBlockById(blockId: number): void {
    const blockToDelete = this.getBlockById(blockId);
    if (!blockToDelete) return;

    const index = this.blocks.indexOf(blockToDelete);
    this.blocks.splice(index, 1);
  }

  /**
   * Actualiza fecha de última edición
   */
  updateLastEdit(lastEditDate: Date): void {
    if (!this.audit) return;
    this.audit.updatedAt = lastEditDate;
  }

  /**
   * Agrega el bloque indicado
   * @param block BLoque a agregar
   */
  addBlock(block: WorkoutBlock): void {
    // Asigna workout al que pertenece
    block.workout = this;

    this.blocks.push(block);
  }

  /**
   * Modifica el bloque indicado. En caso de que no exista se agrega
   * @param block Bloque con los datos nuevos o a asignar
   */
  updateOrAddBlock(block: WorkoutBlock): void {
    // Asigna workout al que pertenece
    block.workout = this;

    // Sino tiene Id significa que no debería existir en la colección por lo que se agrega
    if (!block.id) {
      this.blocks.push(block);
      return;
    }

    // Obtiene el bloque para actualizar.
    const blockToUpdate = this.getBlockById(block.id);

    // En caso de que exista el bloque se tendría que actualizar
    if (blockToUpdate) {
      blockToUpdate.update(block);
    }
    // De lo contrario se agrega
    else {
      this.blocks.push(block);
    }
  }

  /**
   * Ordena los bloques del workout en base al campo order
   */
  sortBlocksByOrder(): void {
    this.blocks = this.blocks.sort((a, b) => a.order - b.order);
  }

  /**
   * Aplica los valores actuales del objeto para una siguiente validación de cambios
   */
  applyChanges(): void {
    this.setInitialValues(this);
  }

  /**
   * Asigna valores iniciales a los campos de validación de cambios
   * @param workout Workout con los datos actuales
   */
  private setInitialValues(workout: Workout): void {
    this.initialValues.name = workout.name;
    this.initialValues.order = workout.order;
    this.initialValues.rpe = workout.rpe;
    this.initialValues.startTime = workout.startTime;
    this.initialValues.endTime = workout.endTime;
    this.initialValues.notes = workout.notes;
    this.initialValues.day.id = workout.day?.id;
    this.initialValues.type.id = workout.type?.id;
    this.initialValues.location.id = workout.location?.id;
    this.initialValues.athletes = workout.athletes.slice();
    this.initialValues.allAthletes = workout.allAthletes;
    this.initialValues.tags = workout.tags.map((x) => x);
    this.initialValues.folder = workout.folder?.map((x) => x);
  }

  /**
   * Map workout response common fields for workout with and without blocks
   * @param response
   * @returns
   */
  private fromResponseCommonFields(response: WorkoutResponse): Workout {
    const workout = new Workout();

    workout.id = response.id;
    workout.startTime = UtilitiesService.getDateTimeFromTimeString(
      response.start_time,
    );
    workout.endTime = UtilitiesService.getDateTimeFromTimeString(
      response.end_time,
    );
    workout.rpe = response?.log_workout_set?.jsonData?.rpe || response.rpe;
    workout.srpe = response.srpe;
    workout.order = response.order || null;
    workout.hasLibrary = response.has_library;
    workout.isActive = response.active;
    workout.notes = response.notes;
    workout.isEditing = false;
    workout.isSelected = false;
    workout.hidden = false;

    // Name
    workout.name = response.name ? response.name : WORKOUT_DEFAULT_NAME;

    // Day
    workout.day = response.day
      ? new PhaseDay().fromResponse(response.day)
      : undefined;

    // Location/ Gym
    workout.location = response.location
      ? new WorkoutLocation().fromResponse(response.location)
      : null;

    // Type
    workout.type = response.place_catalog
      ? new WorkoutType().fromResponse(response.place_catalog)
      : null;

    // Audit properties
    workout.audit = new Audit().fromResponse(response);

    // Verifica que en caso de estar marcado con asignación de todos los atletas realmente tenga atletas asignados
    workout.allAthletes =
      response.all_users && response.athletes.length ? true : false;

    // Athletes
    workout.athletes = response.athletes
      ? response.athletes.map((x) => new Athlete().fromResponse(x))
      : [];

    // Teams
    workout.teams = response.team
      ? response.team.map((x) => new Team().fromResponse(x))
      : [];

    // Program
    workout.program = response.program
      ? new Program().fromResponse(response.program)
      : null;

    // Status
    workout.status = response.status;

    // Log workout set
    workout.log = response.log_workout_set
      ? new WorkoutLog().fromResponse(response.log_workout_set)
      : null;

    // TODO: Determinar si el status en log es el mismo dato al que hace referencia el campo en workout
    if (workout.log) {
      workout.status = workout.log.jsonData?.status;
    }

    // Conteo de bloques y ejercicios
    workout.blocksCount = response.blocks_count || 0;
    workout.exercisesCount = response.exercises_count || 0;

    // Tags
    workout.tags = response.tags?.length
      ? WorkoutTag.sortByName(
          response.tags.map((x) => WorkoutTag.fromResponse(x)),
        )
      : [];

    // Folders
    workout.folders = response?.folder;

    return workout;
  }
}
