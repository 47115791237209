import { Serializer, BlockSubcodingResponse } from './interfaces';

export class BlockSubcoding implements Serializer<BlockSubcoding> {
    constructor(
        public id?: number,
        public name?: string,
        public blockCodingId?: number
    ) { }
    fromResponse(response: BlockSubcodingResponse): BlockSubcoding {
        const _language = localStorage.getItem('USER_LANGUAGE');
        const _name = _language == 'es' ? response.name_es || response.name : response.name || response.name_es;
        return new BlockSubcoding(
            response.id,
            _name,
            response.coding
        )
    }
    toRequest() {
        throw new Error('Method not implemented.');
    }
}