<div class="sp-chat-message">
    <div #messageContainer
        class="sp-chat-message__container d-flex flex-column align-s">
        <p *ngIf="message?.type === typeText">{{message?.comment}}</p>
        <a [href]="message?.set_file?.file" target="_blank" class="file" *ngIf="message?.type === typeFile">
            <mat-icon aria-hidden="false" aria-label="Example home icon" color="accent">picture_as_pdf</mat-icon>
            <span>{{message?.comment}}</span>
        </a>
        <a *ngIf="message?.type === typeImage" [href]='message.set_file?.file' target='_blank'>
            <img class="img" width="100" [src]='message.set_file?.file' alt="image" loading='lazy'>
        </a>
        <video *ngIf="message?.type === typeVideo" controls width="220" height="120">
            <source [src]="message?.set_file?.file" type="video/mp4">
        </video>
        <small #messageTime
            class="sp-chat-message__time">
            {{timeString(message?.createdAt)}}
        </small>
    </div>
</div>