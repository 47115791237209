
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import {
  ApiService, PhaseDay, PhaseDayResponse, PhaseDays, PhaseDaysResponse,
  Workout, WorkoutResponse
} from 'sp-core';

import { WorkoutService } from './workout.service';

@Injectable()
export class PhaseDayService {

  private daySubject$ = new BehaviorSubject<PhaseDay>(null);
  /**
   * Indica el último día creado ó modificado
   */
  day$ = this.daySubject$.asObservable();

  private dayRemovedSubject$ = new BehaviorSubject<number>(null);
  /**
   * Observable para notificar que se ha eliminado un de día de fase
   */
  dayRemoved$ = this.dayRemovedSubject$.asObservable();

  /**
   * Emite cuando la colección de workouts del día ha sido modificado. Tendrá la colección actual de workouts del día
   */
  workouts$ = new BehaviorSubject<Array<Workout>>([]);

  constructor(
    private api: ApiService,
    private workoutService: WorkoutService
  ) { }

  /**
   * Obtiene la colección de días
   * @param weekId Identificador de semana al que corresponden los días a obtener
   */
  getList(
    weekId?: number
  ): Observable<Array<PhaseDay>> {
    return this.getPaginatedList(weekId)
      .pipe(
        map(data => data.data)
      );
  }

  /**
   * Obtiene la colección de días. Paginados
   * @param weekId Identificador de semana al que corresponden los días a obtener
   */
  getPaginatedList(
    weekId?: number
  ): Observable<PhaseDays> {

    let params = new HttpParams()
      .set('perpage', '5000');  // TODO: Verificar que funcione con 0 para obtener todos los registros

    if (weekId) {
      params = params.set('week', weekId.toString());
    }

    return this.api
      .get<PhaseDaysResponse>('days/', params)
      .pipe(
        map(response => new PhaseDays().fromResponse(response))
      );
  }

  /**
   * Crea un día de semana
   * @param day Datos de día a crear
   */
  create(day: PhaseDay): Observable<PhaseDay> {
    return this.api
      .post<PhaseDayResponse>('days/', day.toRequest())
      .pipe(
        map(response => {
          const dayCreated = day.fromResponse(response);
          this.daySubject$.next(dayCreated);
          return dayCreated;
        })
      );
  }

  /**
   * Crea un día de semana para un programa tipo playlist. TODO: Consultar la diferencia de éste EP respecto al de days.POST
   * @param day Datos de día a crear
   */
  createForPlaylist(day: PhaseDay): Observable<PhaseDay> {
    return this.api
      .post<PhaseDayResponse>('day-list/', day.toRequest())
      .pipe(
        map(response => {
          const dayCreated = day.fromResponse(response);
          this.daySubject$.next(dayCreated);
          return dayCreated;
        })
      );
  }

  /**
   * Elimina un día del programa/fase
   * @param dayId Identificador de día a eliminar
   */
  delete(dayId: number): Observable<any> {
    return this.api
      .delete(`days/${dayId}/`)
      .pipe(response => {
        this.dayRemovedSubject$.next(dayId);
        return response;
      });
  }

  moveWorkouts(
    day: PhaseDay,
    workouts: Array<Workout>
  ): Observable<PhaseDay> {
    return this.api
      .post<PhaseDayResponse>(`day-list/${day.id}/move_workout/`, workouts.map(e => e.id))
      .pipe(
        map(response => day.fromResponse(response))
      );
  }

  /**
   * Genera una copia del workout en el día indicado
   * @param dayId Día en la que se copiará el workout
   * @param workoutId Identificador de workout a copiar
   * @returns 
   */
  copyWorkout(
    dayId: number,
    workoutId: number
  ): Observable<Workout> {

    // Crea una copia del workout indicado
    return this.api
      .post<WorkoutResponse>(`workout-copy/${workoutId}/`, { day: dayId })
      .pipe(
        // Asigna por defecto todos los atletas del programa al que se encuentra asignado el workout
        mergeMap(response => {
          const workoutCopied = new Workout().fromResponse(response);
          workoutCopied.allAthletes = true;
          return this.workoutService.update(workoutCopied);
        })
      )
  }

  /**
   * Ordena los workouts indicados en el día también especificado.
   * Caso 1: Si un workout se mueve dentro del mismo día aún así se indica el día donde se encuentra 
   * Caso 2: Si un workout se mueve a otro día, se indica el día en el que se está asignando
   * @param dayId Identificador del día en que se ordenarán los workouts
   * @param workouts Colección de workouts en el orden actual al que se desea ordenar
   * @returns No content
   */
  sortWorkoutsInDay(dayId: number, workouts: Array<Workout> = []): Observable<any> {
    return this.api.post(
      `workouts-sorting/${dayId}/`,
      workouts.map(x => x.id)
    );
  }

  /**
   * Importa workouts de librería
   * @param day Día en la que se importará(n) el(los) workout(s) indicados
   * @param workouts Workouts a importar o asignar al día indicado
   * @param workoutIdToReplace Si se envía significa que se reemplazará el workout indicado
   * @returns 
   */
  importLibraryWorkout(
    day: PhaseDay,
    workouts: Array<Workout>,
    workoutIdToReplace: number = null
  ): Observable<Array<Workout>> {

    const data = {
      workouts: workouts.map(x => x.id),
      replace: workoutIdToReplace
    }

    return this.api
      .post<Array<WorkoutResponse>>(`workout-library-import/${day.id}/`, data)
      .pipe(
        map(response => {
          // Asigna el objeto día para referencia externa
          const importedWorkouts = response.map(x => new Workout().fromResponse(x));
          return importedWorkouts.map(importedWorkout => {
            importedWorkout.day = day;
            return importedWorkout;
          })
        }),
        // Por cada workout importado obtiene sus bloques
        mergeMap(workouts => {
          return this.workoutService.loadBlocks(workouts)
        })
      );
  }

  /**
   * Agrega un workout al día de fase indicada
   * @param day Día en el que se agregará el workout
   * @returns 
   */
  addWorkout(
    day: PhaseDay
  ): Observable<Workout> {

    const workoutToCreate = new Workout();
    workoutToCreate.day = day;

    return this.workoutService.create(
      workoutToCreate
    ).pipe(
      map(createdWorkout => {
        createdWorkout.day = day;
        return createdWorkout;
      })
    );
  }
}
