import {Serializer} from './interfaces/serializer.interface';
import {AuditUser} from './audit-user';
import {ProgramSurveyRequest, ProgramSurveyResponse} from './interfaces';
import {Survey} from './survey';
import {Question} from './question';
import moment from 'moment';
import {Athlete} from './athlete';

export class ProgramSurvey implements Serializer<ProgramSurvey> {
  constructor(
    public id?: number,
    public athletes: Array<Athlete> = [],
    public date?: Date,
    public time?: string,
    public allAthletes?: boolean,
    public program?: number,
    public survey?: Survey,
    public audit?: Pick<AuditUser, 'updatedAt' | 'createdAt'>
  ) {
  }

  fromResponse(response: ProgramSurveyResponse): ProgramSurvey {
        return new ProgramSurvey(
          response.id,
          response.athletes?.map((value) => {
            const athlete = new Athlete(value.id);
            athlete.photo = value.image;
            return athlete;
          }) ?? [],
          new Date(response.date),
          response.time,
          response.all_athletes,
          response.program,
          new Survey(
            response.survey?.id,
            response.survey?.title,
            response.survey?.description,
            response.survey?.survey_type,
            response.survey?.institution,
            new AuditUser().fromResponse({
              created_at: response.survey?.created_at,
              created_by: null,
              updated_at: response.survey?.updated_at,
              updated_by: null
            }),
            response.survey?.question_set?.map((q) => new Question(
              q.id,
              q.survey,
              q.question_type,
              q.input_type,
              q.language,
              q.title,
              q.description,
              q.unit,
              q.max_select,
              q.required,
              q.has_library,
              q.order
            )) ?? []
          ),
          new AuditUser().fromResponse({
            created_by: null,
            created_at: response.created_at,
            updated_by: null,
            updated_at: response.updated_at
          })
        );
    }
    toRequest(): ProgramSurveyRequest {
        return {
          all_athletes: this.allAthletes,
          athletes_id: this.allAthletes ? undefined : this.athletes.map((a) => a.id),
          date: moment.utc(this.date).format('YYYY-MM-DD'),
          program: this.program,
          survey_id: this.survey.id,
          time: this.time
        };
    }

}
