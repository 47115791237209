import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import moment from 'moment';

import { Periodicity, AuthService, ApiService, SPFEvent, SPFEventResponse, EventSummary, EventSummaryResponse } from 'sp-core';

import { environment } from '@web/env/environment';

@Injectable()
export class EventService {

  constructor(
    private auth: AuthService,
    private api: ApiService
  ) { }

  /**
   * Obtiene la lista de eventos correspondientes a una fecha y a un equipo/atleta
   * @param date Fecha de los eventos a obtener
   * @param isMonth
   * @param teamId Identificador de equipo al que corresponde los eventos
   * @param athleteId Identificador de atleta al que corresponde los eventos
   * @returns 
   */
  getListByDate(
    date: Date,
    isMonth: boolean = false,
    teamId?: number,
    athleteId?: number
  ): Observable<Array<SPFEvent>> {

    let params = new HttpParams();
    params = params
      .set('type', isMonth ? '2' : '1')
      .set('institution', this.auth.institutionId.toString())
      .set('date', moment(date).format(environment.apiDateFormat));

    // Equipo
    if (teamId) {
      params = params.set('team', teamId.toString());
    }

    // Atleta
    if (athleteId) {
      params = params.set('athlete', athleteId.toString());
    }

    return this.api
      .get<Array<SPFEventResponse>>('events-list/', params)
      .pipe(
        map(response => response.map(x => new SPFEvent().fromResponse(x)))
      );
  }

  getSummaryByYear(
    year: number,
    teamsId?: Array<number>,
    athleteId?: number
  ): Observable<Array<EventSummary>> {
    return this.getSummaryByPeriodicity(Periodicity.year, null, null, year, teamsId, athleteId);
  }

  getSummaryByDates(
    date_begin: Date,
    date_end: Date,
    teamsId?: Array<number>,
    athleteId?: number
  ): Observable<Array<EventSummary>> {
    return this.getSummaryByPeriodicity(Periodicity.month, date_begin, date_end, null, teamsId, athleteId);
  }

  /**
   * Crea un evento
   * @param event Evento a crear
   * @returns Evento recién creado
   */
  create(
    event: SPFEvent
  ): Observable<SPFEvent> {
    return this.api
      .post<SPFEventResponse>('widget-events/', event.toRequest())
      .pipe(
        map(response => event.fromResponse(response))
      )
  }

  private getSummaryByPeriodicity(
    periodicity: Periodicity,
    date_begin?: Date,
    date_end?: Date,
    year?: number,
    teamsId?: Array<number>,
    athleteId?: number
  ): Observable<Array<EventSummary>> {

    let params = new HttpParams();

    const type = (periodicity === Periodicity.year) ? 2 : 1;
    params = params.set('type', type.toString());

    // Institución
    params = params.set('institution', this.auth.institutionId.toString());

    // Rango de fechas/ año
    if (type === 2) {
      params = params.set('year', year.toString());
    } else {
      params = params
        .set('starts', moment(date_begin).format('YYYY-MM-DD'))
        .set('ends', moment(date_end).format('YYYY-MM-DD'));
    }

    // Equipo(s)
    if (teamsId && teamsId.length) {
      params = params.set('teams', teamsId.join(','));
    }

    // Atleta
    if (athleteId) {
      params = params.set('athlete', athleteId.toString());
    }

    return this.api
      .get<Array<EventSummaryResponse>>('events-filter/', params)
      .pipe(
        map(response => response.map(x => new EventSummary().fromResponse(x)))
      );
  }
}