import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { catchError, finalize } from 'rxjs/operators';

import { AuthService, AuthValidators, ParentErrorStateMatcher, ProgressSpinnerService, ServiceResponse, User } from 'sp-core';

import { NotificationService } from '../../sp-notification';

import { ChangePasswordData, ChangePasswordDataTexts, CHANGE_PASSWORD_CONTROL_NAMES } from './models';

@Component({
  selector: 'sp-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  parentErrorStateMatcher = new ParentErrorStateMatcher();

  form: UntypedFormGroup;

  messageError = '';

  loggingIn = false;

  CONTROL_NAMES = CHANGE_PASSWORD_CONTROL_NAMES;

  passwordShown = {
    password: false,
    confirm: false
  }

  texts: ChangePasswordDataTexts;

  private user: User;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ChangePasswordData,
    private dialogRef: MatDialogRef<ChangePasswordComponent>,
    private fb: UntypedFormBuilder,
    private spinnerService: ProgressSpinnerService,
    private notificationService: NotificationService,
    private authService: AuthService
  ) {
    this.user = this.data.user;
    this.texts = this.data.texts;
    this.createForm();
  }

  ngOnInit(): void {
  }

  handleSaveClick(): void {

    const password = this.form.get('password').value as string;
    const passwordConfirm = this.form.get('confirm').value as string;

    this.spinnerService.start();
    this.authService.changePassword(
      this.user.id,
      password,
      passwordConfirm
    ).pipe(
      catchError(error => {
        throw error;
      }),
      finalize(() => this.spinnerService.stop())
    ).subscribe(() => {
      this.dialogRef.close(this.form.get('password').value as string);
      this.notificationService.success('The password was updated successfully');
    }, (error: ServiceResponse) => {
      this.notificationService.error(error.errorMessage.firstError);
    });
  }

  private createForm(): void {

    this.form = this.fb.group({});

    const passwordCtrl = this.fb.control(null, [Validators.required]);
    this.form.addControl(this.CONTROL_NAMES.password, passwordCtrl);

    const confirmCtrl = this.fb.control(null, [Validators.required]);
    this.form.addControl(this.CONTROL_NAMES.confirm, confirmCtrl);

    this.form.setValidators([AuthValidators.confirmPassword]);
  }
}
