import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, from, of, BehaviorSubject } from 'rxjs';
import { map, mergeMap, concatMap, toArray, catchError } from 'rxjs/operators';

import {
  ApiService, AuthService, BlockType, Data, DataResponse, ExerciseBlock, ExerciseBlockCategoriesData,
  ExerciseBlockCategoriesDataResponse, ExerciseBlockCategory, ExerciseBlockCategoryResponse, ExerciseBlockComment, ExerciseBlockCommentResponse, ExerciseBlockResponse, ExerciseBlocksData,
  ExerciseBlocksDataResponse, ExerciseParameter, ExerciseSerie, PaginatedParams, REQUEST_PARAM_NAMES, ServiceResponse,
  SortSeriesRequest,
  SortSeriesTypeRequest,
  SortSeriesValueRequest,
  WorkoutBlock, Workset, WorksetResponse, WorksetValue
} from 'sp-core';

import { Sort } from 'sp-library';

import { LibraryFilter } from '@web/shared/components/library-filter';

import { ParameterService } from './parameter.service';

const CLASS_IDENTIFIER = 'ExerciseBlockService';

/**
 * Eventos o acciones referentes a la relación de bloques<->ejercicios
 */
@Injectable()
export class ExerciseBlockService {

  private deletedCategorySubject$ = new BehaviorSubject<ExerciseBlockCategory>(null);
  deletedCategory$ = this.deletedCategorySubject$.asObservable();

  private updatedCategorySubject$ = new BehaviorSubject<ExerciseBlockCategory>(null);
  updatedCategory$ = this.updatedCategorySubject$.asObservable();

  private createdCategorySubject$ = new BehaviorSubject<ExerciseBlockCategory>(null);
  createdCategory$ = this.createdCategorySubject$.asObservable();

  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private parameterService: ParameterService
  ) { }

  /**
   * Obtiene el detalle de una relación de bloque<->ejercicio
   * @param blockExerciseLinkId Identificador de la relación/asignación entre bloque y ejercicio
   */
  getById(blockExerciseLinkId: number): Observable<ExerciseBlock> {
    return this.apiService.get<ExerciseBlockResponse>(
      `exercise-block-detail/${blockExerciseLinkId}/`
    ).pipe(
      map(response => new ExerciseBlock().fromResponse(response)),
      catchError(this.apiService.handleError('ExerciseBlockService.getById'))
    );
  }

  /**
   * Elimina una serie. Elimina todos los valores de una serie de bloque<->ejercicio
   */
  removeSerie(
    blockExerciseLinkId: number,
    rowNumber?: number
  ): Observable<boolean> {
    return this.apiService.delete(
      `line-block-exercises-catalog/${rowNumber}/${blockExerciseLinkId}/`
    ).pipe(
      map(() => true)
    );
  }

  /**
   * Clona una serie
   */
  cloneSerie(
    blockExercise: ExerciseBlock,
    exerciseSerie: ExerciseSerie
  ): Observable<Workset> {
    return this.apiService.get<WorksetResponse>(
      `clone-line-block-exercises-catalog/${exerciseSerie.rowNumber}/${blockExercise.id}/`
    ).pipe(
      map(response => new Workset().fromResponse(response))
    );
  }

  /**
   * Crea registros de bloque de ejercicio (relación entre ejercicio -> bloque)
   * @param block Identificador de bloque
   * @param exerciseBlocks Colección de asignaciones de ejercicios a agregar a un bloque
   */
  bulkCreate(
    exerciseBlocks: Array<ExerciseBlock>,
    block?: WorkoutBlock
  ): Observable<Array<ExerciseBlock>> {

    if (!exerciseBlocks.length) return of([]);

    return from(
      exerciseBlocks.map((exerciseLink, index) => {
        exerciseLink.order = index + 1;
        return exerciseLink;
      })
    ).pipe(
      concatMap(exerciseBlock => {
        return this.create(exerciseBlock, block)
      }),
      catchError(this.apiService.handleError('ExerciseBlockService.bulkCreate')),
      toArray(),
    );
  }

  /**
   * Crea un registro de bloque de ejercicio (relación entre ejercicio -> bloque)
   * @param block Identificador de bloque
   * @param exerciseBlock Relación de bloque<->ejercicio a agregar a un bloque
   */
  create(
    exerciseBlock: ExerciseBlock,
    block?: WorkoutBlock
  ): Observable<ExerciseBlock> {

    const payload = exerciseBlock.toRequest();

    // En caso de que sea la creación de un bloque<->ejercicio asignado a un bloque de workout se asignan ciertos campos adicionales
    const isAssignedToWorkoutBlock = !!block;
    if (isAssignedToWorkoutBlock) {
      payload.block = block.id;
      // En caso de que NO sea regular, el bloque<->ejercicio será superset
      payload.superset = block.type.type != BlockType.regular ? true : false;
    }

    return this.apiService.post<Array<ExerciseBlockResponse>>(
      'block-exercises/',
      [payload]
    ).pipe(
      map(response => {
        return new ExerciseBlock().fromResponse(response[0]);
      }),
      // Por cada bloque ejercicio creado se crea su respectivo registro de Reps
      mergeMap(exerciseBlock => {
        return this.parameterService
          .add(exerciseBlock, ExerciseParameter.parameterReps)
          .pipe(
            map(() => exerciseBlock)
          );
      }),
      // Por cada bloque ejercicio creado, le crea un valor por defecto para su primer parámetro agregado (reps)
      mergeMap(exerciseBlock => {
        const value = WorksetValue.valueForParameterReps;
        value.parameterLinkId = exerciseBlock.parameterLinks[0].id; // Deberá existir en el mergeMap previo
        return this.parameterService
          .addValue(value)
          .pipe(
            map(worksetValueCreated => {
              exerciseBlock.values.push(worksetValueCreated)
              return exerciseBlock;
            })
          );
      }),
      catchError(this.apiService.handleError('ExerciseBlockService.create'))
    );
  }

  /**
   * Save as template an exercise block. Create a template set based of exercise block specified
   * @param exerciseBlock Exercise block to save as template
   * @returns 
   */
  saveAsTemplate(
    exerciseBlock: ExerciseBlock
  ): Observable<ExerciseBlock> {
    return this.apiService.post<ExerciseBlockResponse>(
      `block-exercises/${exerciseBlock.id}/library/`,
      exerciseBlock.toTemplateRequest()
    ).pipe(
      map(response => exerciseBlock.fromResponse(response)),
      catchError(this.apiService.handleError('ExerciseBlockService.saveAsTemplate'))
    );
  }

  /**
   * Actualiza la información de una asignación de bloque<->ejercicio
   * ```
   * EP: PATCH block-exercises/<exerciseBlockId>/
   * ```
   * @param exerciseBlock Datos a actualizar
   */
  update(exerciseBlock: ExerciseBlock): Observable<ExerciseBlock> {
    return this.apiService.patch<ExerciseBlockResponse>(
      `block-exercises/${exerciseBlock.id}/`,
      exerciseBlock.toUpdateRequest()
    ).pipe(
      map(response => exerciseBlock.fromResponse(response)),
      catchError(this.apiService.handleError('BlockExerciseService.update'))
    );
  }

  /**
   * Actualiza el nombre o título de una asignación de bloque<->ejercicio
   * @param exerciseBlockId Identificador de bloque<->ejercicio a modificar
   * @param title Título o nombre a asignar al bloque<->ejercicio
   */
  updateTitle(
    exerciseBlockId: number,
    title: string
  ): Observable<ExerciseBlock> {

    const exerciseBlockToUpdate = new ExerciseBlock();
    exerciseBlockToUpdate.title = title;

    return this.apiService.patch<ExerciseBlockResponse>(
      `block-exercises/${exerciseBlockId}/`,
      exerciseBlockToUpdate.toUpdateTitleRequest()
    ).pipe(
      catchError(this.apiService.handleError('BlockExerciseService.updateTitle')),
      map(response => exerciseBlockToUpdate.fromResponse(response))
    );
  }

  /**
   * Clona una relación de bloque<->ejercicio
   * @param exerciseBlock 
   * @returns Bloque<->ejercicio clonado
   */
  clone(exerciseBlock: ExerciseBlock): Observable<ExerciseBlock> {
    return this.apiService.get<ExerciseBlockResponse>(
      `clone-exercises-block/${exerciseBlock.id}/`
    ).pipe(
      map(response => new ExerciseBlock().fromResponse(response)),
      catchError(this.apiService.handleError('BlockExerciseService.clone'))
    );
  }

  /**
   * Elimina el bloque<->ejercicio indicado
   * @param exerciseBlock Bloque<->ejercicio a eliminar
   * @returns 
   */
  remove(exerciseBlock: ExerciseBlock): Observable<ServiceResponse> {
    return this.apiService.delete(
      `block-exercises/${exerciseBlock.id}/`
    ).pipe(
      map(() => new ServiceResponse()),
      catchError(this.apiService.handleError('BlockExerciseService.remove'))
    )
  }

  /**
   * Replace the exercise block for a template set
   * @param exerciseBlock Exercise block
   * @param template Template set for replace the exercise block
   * @returns New exercise block created for the replace
   */
  replaceForTemplate(
    exerciseBlock: ExerciseBlock,
    template: ExerciseBlock
  ): Observable<any> {

    const body = {
      block: exerciseBlock.id
    };

    return this.apiService.post<ExerciseBlockResponse>(
      `block-exercises/${template.id}/replace_to_block/`,
      body
    ).pipe(
      map(response => new ExerciseBlock().fromResponse(response)),
      catchError(this.apiService.handleError('ExerciseBlockService.replaceForTemplate'))
    );
  }

  getPaginatedCategories(
    search?: string,
    sort?: Sort,
    paginatedParams?: PaginatedParams
  ): Observable<ExerciseBlockCategoriesData> {

    let params = new HttpParams().set('institution', this.authService.institutionId.toString())

    if (search) {
      params = params.set('search', search);
    }

    if (sort && sort.direction) {
      params = params.set('ordering', `${(sort.direction == 'asc' ? '' : '-')}${sort.active}`)
    }

    if (paginatedParams) {
      params = params
        .set('perpage', paginatedParams.pageSize)
        .set('page', paginatedParams.page);
    }

    return this.apiService.get<ExerciseBlockCategoriesDataResponse>(
      `catalog-exersice-block/`,
      params
    ).pipe(
      map(response => new ExerciseBlockCategoriesData().fromResponse(response))
    );
  }

  getCategories(
    search?: string,
    sort?: Sort,
    paginatedParams?: PaginatedParams
  ): Observable<Array<ExerciseBlockCategory>> {
    return this.getPaginatedCategories(
      search,
      sort,
      paginatedParams
    ).pipe(
      map(response => response.data)
    );
  }

  getPaginatedTemplates(
    params?: PaginatedParams,
    selectedCategories?: Array<ExerciseBlockCategory>,
    filter?: LibraryFilter
  ): Observable<ExerciseBlocksData> {

    // Get exercise block of type templates
    let httpParams = new HttpParams()
      .set('has_library', '1');

    // Library filter
    if (filter) {
      httpParams = httpParams.set(REQUEST_PARAM_NAMES.tab, filter.type);
    }

    // Search value
    if (params.search) {
      httpParams = httpParams.set(REQUEST_PARAM_NAMES.search, params.search);
    }

    // Number of page
    if (params.page) {
      httpParams = httpParams.set(REQUEST_PARAM_NAMES.page, params.page.toString());
    }

    // Page size
    if (params.pageSize) {
      httpParams = httpParams.set(REQUEST_PARAM_NAMES.pageSize, params.pageSize.toString());
    }

    // Categories
    if (selectedCategories && selectedCategories.length) {
      httpParams = httpParams.set('category', selectedCategories.map(x => x.id).join(','));
    }

    return this.apiService.get<ExerciseBlocksDataResponse>(
      `block-exercises/`,
      httpParams
    ).pipe(
      map(response => new ExerciseBlocksData().fromResponse(response)),
      catchError(this.apiService.handleError('ExerciseBlockService.getPaginatedTemplates'))
    );
  }

  getTemplates(
    params?: PaginatedParams,
    selectedCategories?: Array<ExerciseBlockCategory>,
    filter?: LibraryFilter
  ): Observable<Array<ExerciseBlock>> {
    return this.getPaginatedTemplates(
      params,
      selectedCategories,
      filter
    ).pipe(
      map(response => response.data)
    );
  }

  /**
   * Crea en backend una nueva categoría de bloque de ejercicio
   * @param category Objeto con datos de la categoría a crear
   * @returns
   */
  createCategory(
    category: ExerciseBlockCategory
  ): Observable<ExerciseBlockCategory> {

    const payload = category.toRequest();
    payload.institution = this.authService.institutionId;

    return this.apiService.post<ExerciseBlockCategoryResponse>(
      'catalog-exersice-block/',
      payload
    ).pipe(
      map(response => {
        const createdCategory = category.fromResponse(response)
        this.createdCategorySubject$.next(createdCategory);
        return createdCategory;
      })
    );
  }

  /**
   * Elimina la categoría indicada
   * @param category 
   * @returns 
   */
  deleteCategory(
    category: ExerciseBlockCategory
  ): Observable<ExerciseBlockCategory> {
    return this.apiService.delete(
      `catalog-exersice-block/${category.id}/`
    ).pipe(
      catchError(this.apiService.processError('ExerciseBlockService.createCategory')),
      map(() => {
        this.deletedCategorySubject$.next(category);
        return category;
      })
    );
  }

  /**
   * Actualiza una categoría de template set
   * @param category
   * @returns 
   */
  updateCategory(
    category: ExerciseBlockCategory
  ): Observable<ExerciseBlockCategory> {

    if (!category.hasChanges) {
      return of(category);
    }

    const data = {
      name: category.name
    };

    return this.apiService.patch(
      `catalog-exersice-block/${category.id}/`,
      data
    ).pipe(
      catchError(this.apiService.processError('ExerciseBlockService.updateCategory')),
      map(response => {
        category.applyChanges();
        const updatedCategory = category.fromResponse(response)
        this.updatedCategorySubject$.next(updatedCategory);
        return updatedCategory;
      })
    );
  }

  /**
   * ```
   * EP: POST be-values-sorting/
   * ```
   * @param series
   * @returns 
   */
  sortSeries(series: Array<ExerciseSerie>): Observable<boolean> {

    const values: Array<SortSeriesValueRequest> = [];
    const types: Array<SortSeriesTypeRequest> = [];

    series.filter(x => !x.isNewRowAux).forEach((serie, serieIndex) => {
      const rowNumber = serieIndex + 1;
      serie.rowNumber = rowNumber;
      // Values columns
      serie.columns.filter(x => x.value.id).forEach(serieColumn => {
        serieColumn.value.rowNumber = rowNumber;
        const value = {} as SortSeriesValueRequest;
        value.id = serieColumn.value.id;
        value.row = rowNumber;
        value.catalog = serieColumn.value.parameterLinkId;
        values.push(value);
      })
      // Type column
      if (serie.type.id) {
        serie.type.rowNumber = rowNumber;
        const type = {} as SortSeriesTypeRequest;
        type.id = serie.type.id;
        type.row = rowNumber;
        type.exercise_block = serie.type.exerciseBlockId;
        types.push(type);
      }
    });

    const payload = {} as SortSeriesRequest;
    payload.values = values;
    payload.row_types = types;

    return this.apiService.post(
      'be-values-sorting/',
      payload
    ).pipe(
      catchError(this.apiService.processError('ExerciseBlockService.sortSeries')),
      map(() => true)
    );
  }

  getComments(
    userId: number,
    exerciseBlockId: number,
    recipientId?: number,
    paginatedParams?: PaginatedParams
  ): Observable<Data<ExerciseBlockComment>> {

    let params = new HttpParams()
      .set('user', userId.toString())
      .set('exercise_block', exerciseBlockId.toString());

    if (recipientId)
      params = params.set('recipient', recipientId.toString())

    if (paginatedParams)
      params = paginatedParams.toRequest(params);

    return this.apiService.get<DataResponse<ExerciseBlockComment>>(
      'exercises-comments/conversation/',
      params
    ).pipe(
      catchError(this.apiService.processError('ExerciseBlockService.getComments')),
      map((response: DataResponse<ExerciseBlockComment>) => {
        return new Data(ExerciseBlockComment).fromResponse(response)
      })
    );
  }

  /**
   * Envía un comentario a un atleta (athleteId) o a varios cuando se indica allAthletes = true
   * ```
   * EP: POST exercises-comments/
   * El usuario quien envía se obtiene del usuario en sesión desde backend
   * ```
   * @param exerciseBlockComment Datos de comentario a enviar
   * @param allAthletes Indica si el comentario es para todos los atletas
   * @returns Colección de comentarios creados. Con identificador y fechas asignadas
   */
  sendNewCommentToAthlete(
    exerciseBlockComment: ExerciseBlockComment,
    allAthletes = false
  ): Observable<Array<ExerciseBlockComment>> {

    const methodIdentifier = `${CLASS_IDENTIFIER}.sendNewCommentToAthlete`;

    if (!allAthletes && !exerciseBlockComment.recipient.id) {
      throw new Error(`${methodIdentifier}: athleteId is required when allAthletes is false`);
    }

    const payload = allAthletes
      ? exerciseBlockComment.toAllAthletesRequest()
      : exerciseBlockComment.toRequest();

    return this.apiService.post<Array<ExerciseBlockCommentResponse>>('exercises-comments/',
      payload
    ).pipe(
      catchError(this.apiService.processError(methodIdentifier)),
      map((response: Array<ExerciseBlockCommentResponse>) => {
        return (response && response.length)
          ? response.map(x => ExerciseBlockComment.fromResponse(x))
          : []
      })
    );
  }
}