import {Serializer, AnswerQuestionRequest, AnswerQuestionResponse} from './interfaces';
import {AuditUser} from './audit-user';

export class AnswerQuestion implements Serializer<AnswerQuestion> {
  constructor(
    public id?: number,
    public question?: number,
    public minValue?: number,
    public maxValue?: number,
    public minText?: string,
    public maxText?: string,
    public order?: number,
    public audit?: AuditUser
  ) {
  }

  fromResponse(response: AnswerQuestionResponse): AnswerQuestion {
    return new AnswerQuestion(
      response.id,
      response.question,
      response.min_value,
      response.max_value,
      response.min_text,
      response.max_text,
      response.order,
      new AuditUser().fromResponse({
        created_by: null,
        created_at: response.created_at,
        updated_by: null,
        updated_at: response.updated_at
      })
    );
  }

  toRequest(): AnswerQuestionRequest {
    return {
      max_text: this.maxText,
      max_value: this.maxValue,
      min_text: this.minText,
      min_value: this.minValue,
      question: this.question,
      order: this.order
    };
  }
}
