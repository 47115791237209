// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //apiBaseUrl: 'https://api.soloperformance.app/api/v1/', // Production API
  apiBaseUrl: 'https://api.dev.soloperformance.app/api/v1/',
  baseUrl: 'https://dashboard.dev.soloperformance.app/',
  tokenUrl: 'token/',
  refreshTokenUrl: 'token-refresh/',
  userInfoUrl: 'me',
  forgotPasswordUrl: 'reset-password/',
  loginUrl: 'auth/login',
  apiDateFormat: 'YYYY-MM-DD',
  //ws: 'wss://api.soloperformance.app/ws/',  // Production API
  ws: 'wss://api.dev.soloperformance.app/ws/',
  // Configuración con la cuenta de SoloPerformance en Firebase. Proyecto: SPSocialLogin
  firebase: {
    apiKey: "AIzaSyBDHsytrRpbp1cSF4NAgzITn2SWknVhB9M",
    authDomain: "spsociallogin.firebaseapp.com",
    projectId: "spsociallogin",
    storageBucket: "spsociallogin.appspot.com",
    messagingSenderId: "424312611673",
    appId: "1:424312611673:web:d17dd9ee3756f4ad3fa248"
  },
  stripe: {
    publishableKey: 'pk_test_51HZlO5GF0mI54tukLKSjIipYwT3Bt1LvSLJjjruPXE55XJPpa18gcphFxRuCeiWDJJ1PQswwNC8AQAgPdYHC8bh30026WMj2XL'
  },
  recaptcha: {
    siteKey: '6LepSk4bAAAAADS80BqcFQdQBq1hrhi9E4-3NcI_' // Clave obtenido con la cuenta: jose.chin.dev@gmail.com
  },
  googleAnalytics: {
    trackindId: 'G-S8PLV1VT5D'  // Clave obtenida con la cuenta jose.chin.dev@gmail.com
  },
  youtubeApiV3: {
    apiKey: 'AIzaSyCJoGcopacrO2V5Ywllz182pTFXeu4BJSA' // Clave obtenido con la cuenta: jose.chin.dev@gmail.com
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
