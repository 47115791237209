import { ExerciseCategoryResponse, Serializer } from './interfaces';

import { Audit } from './audit';
import { ExerciseSubcategory } from './exercise-subcategory';

export class ExerciseCategory implements Serializer<ExerciseCategory> {
    constructor(
        public id?: number,
        public name?: string,
        public code?: string,
        public isActive?: boolean,
        public categoryGroupId?: number,
        public audit?: Audit,
        /**
         * Colección de subcategorías. NOTA: No se obtiene en el servicio, se complementa en front.
         */
        public subcategories: Array<ExerciseSubcategory> = []
    ) { }

    fromResponse(response: ExerciseCategoryResponse): ExerciseCategory {
        const _language = localStorage.getItem('USER_LANGUAGE');
        const _name = _language == 'es' ? response.spanish_name || response.name : response.name || response.spanish_name;
        return new ExerciseCategory(
            response.id,
            _name,
            response.code,
            response.active,
            response.category_level,
            new Audit().fromResponse(response)
        )
    }

    toRequest() {
        throw new Error('Method not implemented.');
    }

    getSubcategoriesSelected(): Array<ExerciseSubcategory> {
        return this.subcategories.filter(subcategory => subcategory.selected);
    }

    checkSubcategoriesSelected(): boolean {
        return !!this.getSubcategoriesSelected().length;
    }

    selectUnselectAllSubcategories(select: boolean): void {
        this.subcategories.forEach(subcategory => subcategory.selected = select);
    }
}